.integrations-select-dropdown {
  background-color: #fff !important;
}
.integrations-select-dropdown [class*=-select-item-option]:not([class*=-select-item-option-content]) {
  background-color: #fff !important;
}
.integrations-select-dropdown [class*=-select-item-option-disabled] {
  color: #868ca2 !important;
}
.integrations-select-dropdown [class*=-select-item-option]:not([class*=-select-item-option-content]):not([class*=-select-item-option-disabled]):hover {
  background-color: #eaf6ff !important;
}
.integrations-delete-notification {
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.15));
  border-radius: 4px;
  padding: 18px 24px 16px;
  border: none;
  background-color: #fff;
  box-shadow: inset 4px 0px 0px -1px #19A04B;
  display: flex;
  align-items: flex-start;
  max-width: 362px;
}
.integrations-delete-notification .ant-notification-notice-message {
  margin-bottom: 8px;
  margin-left: 40px;
  font-size: 16px;
  padding: 0;
}
.integrations-delete-notification .ant-notification-notice-close {
  top: 18px;
  right: 24px;
}
.integrations-delete-notification .ant-notification-notice-icon {
  margin: 0;
}
.integrations-delete-notification .ant-notification-notice-description {
  margin: 0 14px 0 40px;
}

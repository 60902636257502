.cw-content-row {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}
.cw-app-create-form-vseparator {
  background-color: #E5E7EA;
  flex-direction: column;
  min-width: 1px;
  margin-left: 25px;
  margin-right: 25px;
}
.cw-app-image-placeholder {
  border-radius: 8px;
  background-color: #E8EAED;
  border: #E5E7EA solid 1px;
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
}
.cw-content-container {
  background: #fff;
  margin: 24px;
  padding: 20px 30px 20px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 20px 0 rgba(112, 115, 116, 0.16);
}
.banner-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.cw-create-app-column1 {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  padding-right: 40px;
  min-width: 500px;
  width: 35%;
  border-right: 1px solid #E8EAED;
}
.cw-create-app-column2 {
  display: flex;
  flex-direction: column;
  min-width: 450px;
  width: 35%;
  margin-top: -25px;
}
.technology-placeholder {
  display: flex;
  overflow-x: scroll;
  height: 200px;
}

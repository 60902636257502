.delete-modal-confirm-content {
  width: 100%;
}
.delete-modal-confirm-content .left {
  float: left;
  width: 10%;
}
.delete-modal-confirm-content .right {
  float: right;
  width: 90%;
}
.delete-confirm-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}
.delete-confirm-title {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 1.25rem;
}
.delete-modal-button {
  background: #FFFFFF;
  border-radius: 4px;
  color: #14151C;
}
.delete-modal-button__cancel {
  color: #14151C;
  border: 1px solid #C2C7D0;
}
.delete-modal-button__cancel:hover {
  color: #14151C;
}
.delete-modal-button__disabled {
  display: none;
}
.delete-modal-button__ok {
  background: #CF3537;
  border: 1px;
}
.delete-modal-button__ok:hover {
  background: #CF3537;
  box-shadow: inset 0 0 0 10em rgba(255, 255, 255, 0.3);
}
.delete-modal-button__ok:focus {
  background: #CF3537;
}

.drawerButtons Button {
  float: right;
  margin-left: 10px;
}
.deleteLink {
  left: 10px;
  bottom: 10px;
}
.deleteLink a {
  color: #eb4034;
}
.drawerButtons {
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 10px;
  right: 10px;
  padding: 24px 0 24px 0;
}

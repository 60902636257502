.cw-content-container {
  background: #fff;
  margin: 24px;
  padding: 20px 30px 20px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 20px 0 rgba(112, 115, 116, 0.16);
}
.cw-api-image-placeholder {
  border-radius: 8px;
  background-color: #E8EAED;
  border: #E5E7EA solid 1px;
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
}
.cw-client-details-column1 {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  padding-right: 40px;
  min-width: 500px;
  width: 35%;
  border-right: 1px solid #E8EAED;
}
.cw-client-details-column2 {
  display: flex;
  flex-direction: column;
  min-width: 450px;
  width: 35%;
  margin-top: 10px;
}
.cw-api-create-form-vseparator {
  background-color: #E5E7EA;
  flex-direction: column;
  min-width: 1px;
  margin-left: 25px;
}
.cw-api-uri-button {
  margin-top: 15px;
}
.name-error {
  color: #1890ff;
  font-size: 40px;
}
.modal-error {
  color: red;
  font-size: 40px;
}
.modal-success {
  color: green;
  font-size: 40px;
}
.modal-placeholder {
  text-align: center;
  padding-top: 20px;
}
@media screen and (max-width: 600px) {
  .cw-api-uri-button {
    width: 400px;
  }
}

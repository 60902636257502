* {
  box-sizing: border-box;
}
.home-main-container {
  position: relative;
  height: 100%;
}
.home-main-container .what-can-i-do-icons {
  width: 64px;
  height: 64px;
}
.home-main-container .what-can-i-do-icons img {
  width: 32px;
  height: 32px;
  margin: auto;
  margin-top: 15px;
}
.homeMainCont {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f5f8fc;
}
.homeHeadingCont,
.homeMainContentCont {
  width: 100%;
  height: 138px;
  background-color: #ffffff;
  border-radius: 4px;
  z-index: 2;
}
.homeHeadingCont {
  padding: 14px 20px 11px 13px;
  display: flex;
  flex-direction: row;
}
.homeHeadingCont .headingImageCont {
  position: absolute;
  right: 49px;
  width: 113px;
  height: 113px;
}
.homeHeadingCont .headingTextCont {
  margin-top: 14px;
  margin-right: 145px;
}
.homeMainContentCont {
  margin-top: 24px;
  height: 383px;
  padding-top: 19.14px;
  padding-left: 18px;
  padding-bottom: 25px;
  padding-right: 47px;
}
.homeFooter {
  position: absolute;
  bottom: 16px;
  left: -24px;
  right: -24px;
}
.homePageHeading,
.subHeading {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #14151C;
  letter-spacing: -0.0075em;
}
.subHeading {
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  margin-top: 15px;
  margin-left: 6px;
}
.mainContentBodyCont {
  margin-top: 25px;
  display: flex;
  height: 248px;
  flex-direction: row;
  gap: 48px;
}
.leftVideoCont {
  height: 100%;
  width: 28.79%;
  background: #f3f4f6;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.rightTextCont {
  width: 67.91%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.rightTextCont .rightTextContHeadingBox {
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
}
.rightTextCont .contentsBox {
  width: 100%;
  height: 205px;
  display: grid;
  grid-template-rows: 50% 50%;
  grid-template-columns: 48.5% 48.5%;
  column-gap: 27px;
}
.rightTextCont .contentsBox .allcontentsBoxes {
  display: flex;
  flex-direction: row;
  gap: 13px;
}
.rightTextCont .contentsBox .allcontentsBoxes .contentslogoBox {
  max-width: 45.26px;
  min-width: 45.26px;
  height: 45.26px;
  background: #73dc78;
  border-radius: 0px 11.3431px;
  display: table;
  text-align: center;
}
.rightTextCont .contentsBox .allcontentsBoxes .contentslogoBox span {
  display: table-cell;
  vertical-align: middle;
}
.rightTextCont .contentsBox .allcontentsBoxes .contentsHeaderBox {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #14151C;
}
.rightTextCont .contentsBox .allcontentsBoxes .descriptionBox {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}
.rightTextCont .contentsBox .allcontentsBoxes .contentsTextBox {
  width: 400px;
}
.newRequestAccessModal,
.syt-antd-modal-body {
  padding: 0px !important;
}
.newRequestAccessModal .not-request-access-gif,
.syt-antd-modal-body .not-request-access-gif {
  width: 100%;
}
.newRequestAccessModal .text-content-container,
.syt-antd-modal-body .text-content-container {
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 276px;
}
.newRequestAccessModal .text-content-container .title,
.syt-antd-modal-body .text-content-container .title {
  font-family: 'Noto Sans', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.0075em;
  color: #14803C;
}
.newRequestAccessModal .text-content-container p,
.syt-antd-modal-body .text-content-container p {
  font-family: 'Noto Sans', sans-serif;
  color: #14151C;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.0075em;
  text-align: center;
  margin-bottom: 0px;
}
.newRequestAccessModal .action-container,
.syt-antd-modal-body .action-container {
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 15px 16px 0;
}
.newRequestAccessModal .action-container .enableButton,
.syt-antd-modal-body .action-container .enableButton {
  background-color: #14803C;
  color: #ffffff;
}
.newRequestAccessModal .action-container .disableButton,
.syt-antd-modal-body .action-container .disableButton {
  border-color: #c4bfbf;
}
.newRequestAccessModal .action-container .access-request-sent-message-container,
.syt-antd-modal-body .action-container .access-request-sent-message-container {
  flex: 1;
}
.newRequestAccessModal .action-container .access-request-sent-message-container .request-sent-message-text,
.syt-antd-modal-body .action-container .access-request-sent-message-container .request-sent-message-text {
  text-align: center;
  margin-left: 24%;
  padding: 10px;
  margin-right: 8%;
  background: #F3F4F6;
  border: 1px solid #DFE2E7;
  font-family: 'Noto Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.0075em;
}
.newRequestAccessModal .syngenta-ant-modal-content .syngenta-ant-modal-body {
  padding: 0;
}
.syngenta-ant-modal-mask,
.syngenta-ant-modal-wrap {
  top: 64px !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.syngenta-ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  display: none;
  color: #000;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.syngenta-ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
.syngenta-ant-spin-nested-loading {
  position: relative;
}
.syngenta-ant-spin-nested-loading > div > .syngenta-ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.syngenta-ant-spin-nested-loading > div > .syngenta-ant-spin .syngenta-ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.syngenta-ant-spin-nested-loading > div > .syngenta-ant-spin .syngenta-ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px #fff;
}
.syngenta-ant-spin-nested-loading > div > .syngenta-ant-spin.syngenta-ant-spin-show-text .syngenta-ant-spin-dot {
  margin-top: -20px;
}
.syngenta-ant-spin-nested-loading > div > .syngenta-ant-spin-sm .syngenta-ant-spin-dot {
  margin: -7px;
}
.syngenta-ant-spin-nested-loading > div > .syngenta-ant-spin-sm .syngenta-ant-spin-text {
  padding-top: 2px;
}
.syngenta-ant-spin-nested-loading > div > .syngenta-ant-spin-sm.syngenta-ant-spin-show-text .syngenta-ant-spin-dot {
  margin-top: -17px;
}
.syngenta-ant-spin-nested-loading > div > .syngenta-ant-spin-lg .syngenta-ant-spin-dot {
  margin: -16px;
}
.syngenta-ant-spin-nested-loading > div > .syngenta-ant-spin-lg .syngenta-ant-spin-text {
  padding-top: 11px;
}
.syngenta-ant-spin-nested-loading > div > .syngenta-ant-spin-lg.syngenta-ant-spin-show-text .syngenta-ant-spin-dot {
  margin-top: -26px;
}
.syngenta-ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
.syngenta-ant-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
.syngenta-ant-spin-blur {
  clear: both;
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}
.syngenta-ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
.syngenta-ant-spin-tip {
  color: #707374;
}
.syngenta-ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}
.syngenta-ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #000;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  animation: antSpinMove 1s infinite linear alternate;
}
.syngenta-ant-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}
.syngenta-ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  animation-delay: 0.4s;
}
.syngenta-ant-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  animation-delay: 0.8s;
}
.syngenta-ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  animation-delay: 1.2s;
}
.syngenta-ant-spin-dot-spin {
  transform: rotate(0deg);
  animation: antRotate 1.2s infinite linear;
}
.syngenta-ant-spin-sm .syngenta-ant-spin-dot {
  font-size: 14px;
}
.syngenta-ant-spin-sm .syngenta-ant-spin-dot i {
  width: 6px;
  height: 6px;
}
.syngenta-ant-spin-lg .syngenta-ant-spin-dot {
  font-size: 32px;
}
.syngenta-ant-spin-lg .syngenta-ant-spin-dot i {
  width: 14px;
  height: 14px;
}
.syngenta-ant-spin.syngenta-ant-spin-show-text .syngenta-ant-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .syngenta-ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antRotate {
  to {
    transform: rotate(360deg);
  }
}
.syngenta-ant-spin-rtl {
  direction: rtl;
}
.syngenta-ant-spin-rtl .syngenta-ant-spin-dot-spin {
  transform: rotate(-45deg);
  animation-name: antRotateRtl;
}
@keyframes antRotateRtl {
  to {
    transform: rotate(-405deg);
  }
}
@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.syngenta-ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.syngenta-ant-checkbox-wrapper:hover .syngenta-ant-checkbox-inner,
.syngenta-ant-checkbox:hover .syngenta-ant-checkbox-inner,
.syngenta-ant-checkbox-input:focus + .syngenta-ant-checkbox-inner {
  border-color: #000;
}
.syngenta-ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #000;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.syngenta-ant-checkbox:hover::after,
.syngenta-ant-checkbox-wrapper:hover .syngenta-ant-checkbox::after {
  visibility: visible;
}
.syngenta-ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #e8eaed;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.syngenta-ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.syngenta-ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.syngenta-ant-checkbox-checked .syngenta-ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.syngenta-ant-checkbox-checked .syngenta-ant-checkbox-inner {
  background-color: #000;
  border-color: #000;
}
.syngenta-ant-checkbox-disabled {
  cursor: not-allowed;
}
.syngenta-ant-checkbox-disabled.syngenta-ant-checkbox-checked .syngenta-ant-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.syngenta-ant-checkbox-disabled .syngenta-ant-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.syngenta-ant-checkbox-disabled .syngenta-ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #e8eaed !important;
}
.syngenta-ant-checkbox-disabled .syngenta-ant-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.syngenta-ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.syngenta-ant-checkbox-disabled:hover::after,
.syngenta-ant-checkbox-wrapper:hover .syngenta-ant-checkbox-disabled::after {
  visibility: hidden;
}
.syngenta-ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.syngenta-ant-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.syngenta-ant-checkbox-wrapper.syngenta-ant-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.syngenta-ant-checkbox-wrapper + .syngenta-ant-checkbox-wrapper {
  margin-left: 8px;
}
.syngenta-ant-checkbox-wrapper.syngenta-ant-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.syngenta-ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.syngenta-ant-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.syngenta-ant-checkbox-group-item {
  margin-right: 8px;
}
.syngenta-ant-checkbox-group-item:last-child {
  margin-right: 0;
}
.syngenta-ant-checkbox-group-item + .syngenta-ant-checkbox-group-item {
  margin-left: 0;
}
.syngenta-ant-checkbox-indeterminate .syngenta-ant-checkbox-inner {
  background-color: #fff;
  border-color: #e8eaed;
}
.syngenta-ant-checkbox-indeterminate .syngenta-ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #000;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.syngenta-ant-checkbox-indeterminate.syngenta-ant-checkbox-disabled .syngenta-ant-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.syngenta-ant-checkbox-rtl {
  direction: rtl;
}
.syngenta-ant-checkbox-group-rtl .syngenta-ant-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.syngenta-ant-checkbox-group-rtl .syngenta-ant-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.syngenta-ant-checkbox-group-rtl .syngenta-ant-checkbox-group-item + .syngenta-ant-checkbox-group-item {
  margin-left: 8px;
}
.syngenta-ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  font-size: 0;
}
.syngenta-ant-radio-group .syngenta-ant-badge-count {
  z-index: 1;
}
.syngenta-ant-radio-group > .syngenta-ant-badge:not(:first-child) > .syngenta-ant-radio-button-wrapper {
  border-left: none;
}
.syngenta-ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-flex;
  align-items: baseline;
  margin-right: 8px;
  cursor: pointer;
}
.syngenta-ant-radio-wrapper-disabled {
  cursor: not-allowed;
}
.syngenta-ant-radio-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.syngenta-ant-radio-wrapper.syngenta-ant-radio-wrapper-in-form-item input[type='radio'] {
  width: 14px;
  height: 14px;
}
.syngenta-ant-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  display: inline-block;
  outline: none;
  cursor: pointer;
}
.syngenta-ant-radio-wrapper:hover .syngenta-ant-radio,
.syngenta-ant-radio:hover .syngenta-ant-radio-inner,
.syngenta-ant-radio-input:focus + .syngenta-ant-radio-inner {
  border-color: #000;
}
.syngenta-ant-radio-input:focus + .syngenta-ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
}
.syngenta-ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #000;
  border-radius: 50%;
  visibility: hidden;
  animation: antRadioEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}
.syngenta-ant-radio:hover::after,
.syngenta-ant-radio-wrapper:hover .syngenta-ant-radio::after {
  visibility: visible;
}
.syngenta-ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #F2F4F6;
  border-color: #e8eaed;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  transition: all 0.3s;
}
.syngenta-ant-radio-inner::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  background-color: #000;
  border-top: 0;
  border-left: 0;
  border-radius: 16px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.syngenta-ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.syngenta-ant-radio.syngenta-ant-radio-disabled .syngenta-ant-radio-inner {
  border-color: #e8eaed;
}
.syngenta-ant-radio-checked .syngenta-ant-radio-inner {
  border-color: #000;
}
.syngenta-ant-radio-checked .syngenta-ant-radio-inner::after {
  transform: scale(0.5);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.syngenta-ant-radio-disabled {
  cursor: not-allowed;
}
.syngenta-ant-radio-disabled .syngenta-ant-radio-inner {
  background-color: #f5f5f5;
  cursor: not-allowed;
}
.syngenta-ant-radio-disabled .syngenta-ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.syngenta-ant-radio-disabled .syngenta-ant-radio-input {
  cursor: not-allowed;
}
.syngenta-ant-radio-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
span.syngenta-ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
.syngenta-ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0 15px;
  color: #2F3031;
  font-size: 14px;
  line-height: 30px;
  background: #F2F4F6;
  border: 1px solid #e8eaed;
  border-top-width: 1.02px;
  border-left-width: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.syngenta-ant-radio-button-wrapper a {
  color: #2F3031;
}
.syngenta-ant-radio-button-wrapper > .syngenta-ant-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.syngenta-ant-radio-group-large .syngenta-ant-radio-button-wrapper {
  height: 40px;
  font-size: 16px;
  line-height: 38px;
}
.syngenta-ant-radio-group-small .syngenta-ant-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px;
}
.syngenta-ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  box-sizing: content-box;
  width: 1px;
  height: 100%;
  padding: 1px 0;
  background-color: #e8eaed;
  transition: background-color 0.3s;
  content: '';
}
.syngenta-ant-radio-button-wrapper:first-child {
  border-left: 1px solid #e8eaed;
  border-radius: 2px 0 0 2px;
}
.syngenta-ant-radio-button-wrapper:last-child {
  border-radius: 0 2px 2px 0;
}
.syngenta-ant-radio-button-wrapper:first-child:last-child {
  border-radius: 2px;
}
.syngenta-ant-radio-button-wrapper:hover {
  position: relative;
  color: #000;
}
.syngenta-ant-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
}
.syngenta-ant-radio-button-wrapper .syngenta-ant-radio-inner,
.syngenta-ant-radio-button-wrapper input[type='checkbox'],
.syngenta-ant-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.syngenta-ant-radio-button-wrapper-checked:not(.syngenta-ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #000;
  background: #F2F4F6;
  border-color: #000;
}
.syngenta-ant-radio-button-wrapper-checked:not(.syngenta-ant-radio-button-wrapper-disabled)::before {
  background-color: #000;
}
.syngenta-ant-radio-button-wrapper-checked:not(.syngenta-ant-radio-button-wrapper-disabled):first-child {
  border-color: #000;
}
.syngenta-ant-radio-button-wrapper-checked:not(.syngenta-ant-radio-button-wrapper-disabled):hover {
  color: #0d0d0d;
  border-color: #0d0d0d;
}
.syngenta-ant-radio-button-wrapper-checked:not(.syngenta-ant-radio-button-wrapper-disabled):hover::before {
  background-color: #0d0d0d;
}
.syngenta-ant-radio-button-wrapper-checked:not(.syngenta-ant-radio-button-wrapper-disabled):active {
  color: #000000;
  border-color: #000000;
}
.syngenta-ant-radio-button-wrapper-checked:not(.syngenta-ant-radio-button-wrapper-disabled):active::before {
  background-color: #000000;
}
.syngenta-ant-radio-button-wrapper-checked:not(.syngenta-ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
}
.syngenta-ant-radio-group-solid .syngenta-ant-radio-button-wrapper-checked:not(.syngenta-ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #000;
  border-color: #000;
}
.syngenta-ant-radio-group-solid .syngenta-ant-radio-button-wrapper-checked:not(.syngenta-ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #0d0d0d;
  border-color: #0d0d0d;
}
.syngenta-ant-radio-group-solid .syngenta-ant-radio-button-wrapper-checked:not(.syngenta-ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #000000;
  border-color: #000000;
}
.syngenta-ant-radio-group-solid .syngenta-ant-radio-button-wrapper-checked:not(.syngenta-ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
}
.syngenta-ant-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #e8eaed;
  cursor: not-allowed;
}
.syngenta-ant-radio-button-wrapper-disabled:first-child,
.syngenta-ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #e8eaed;
}
.syngenta-ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #e8eaed;
}
.syngenta-ant-radio-button-wrapper-disabled.syngenta-ant-radio-button-wrapper-checked {
  color: rgba(0, 0, 0, 0.25);
  background-color: #e6e6e6;
  border-color: #e8eaed;
  box-shadow: none;
}
@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.syngenta-ant-radio-group.syngenta-ant-radio-group-rtl {
  direction: rtl;
}
.syngenta-ant-radio-wrapper.syngenta-ant-radio-wrapper-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
}
.syngenta-ant-radio-button-wrapper.syngenta-ant-radio-button-wrapper-rtl {
  border-right-width: 0;
  border-left-width: 1px;
}
.syngenta-ant-radio-button-wrapper.syngenta-ant-radio-button-wrapper-rtl.syngenta-ant-radio-button-wrapper:not(:first-child)::before {
  right: -1px;
  left: 0;
}
.syngenta-ant-radio-button-wrapper.syngenta-ant-radio-button-wrapper-rtl.syngenta-ant-radio-button-wrapper:first-child {
  border-right: 1px solid #e8eaed;
  border-radius: 0 2px 2px 0;
}
.syngenta-ant-radio-button-wrapper-checked:not([class*=' syngenta-ant-radio-button-wrapper-disabled']).syngenta-ant-radio-button-wrapper:first-child {
  border-right-color: #0d0d0d;
}
.syngenta-ant-radio-button-wrapper.syngenta-ant-radio-button-wrapper-rtl.syngenta-ant-radio-button-wrapper:last-child {
  border-radius: 2px 0 0 2px;
}
.syngenta-ant-radio-button-wrapper.syngenta-ant-radio-button-wrapper-rtl.syngenta-ant-radio-button-wrapper-disabled:first-child {
  border-right-color: #e8eaed;
}
.syngenta-ant-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  z-index: 1070;
  display: block;
  width: max-content;
  width: intrinsic;
  max-width: 250px;
  visibility: visible;
}
.syngenta-ant-tooltip-content {
  position: relative;
}
.syngenta-ant-tooltip-hidden {
  display: none;
}
.syngenta-ant-tooltip-placement-top,
.syngenta-ant-tooltip-placement-topLeft,
.syngenta-ant-tooltip-placement-topRight {
  padding-bottom: 14.3137085px;
}
.syngenta-ant-tooltip-placement-right,
.syngenta-ant-tooltip-placement-rightTop,
.syngenta-ant-tooltip-placement-rightBottom {
  padding-left: 14.3137085px;
}
.syngenta-ant-tooltip-placement-bottom,
.syngenta-ant-tooltip-placement-bottomLeft,
.syngenta-ant-tooltip-placement-bottomRight {
  padding-top: 14.3137085px;
}
.syngenta-ant-tooltip-placement-left,
.syngenta-ant-tooltip-placement-leftTop,
.syngenta-ant-tooltip-placement-leftBottom {
  padding-right: 14.3137085px;
}
.syngenta-ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.syngenta-ant-tooltip-arrow {
  position: absolute;
  z-index: 2;
  display: block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.syngenta-ant-tooltip-arrow-content {
  --antd-arrow-background-color: linear-gradient(to right bottom, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.75));
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  margin: auto;
  content: '';
  pointer-events: auto;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.syngenta-ant-tooltip-arrow-content::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: var(--antd-arrow-background-color);
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  clip-path: inset(33% 33%);
  clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.syngenta-ant-tooltip-placement-top .syngenta-ant-tooltip-arrow,
.syngenta-ant-tooltip-placement-topLeft .syngenta-ant-tooltip-arrow,
.syngenta-ant-tooltip-placement-topRight .syngenta-ant-tooltip-arrow {
  bottom: 0;
  transform: translateY(100%);
}
.syngenta-ant-tooltip-placement-top .syngenta-ant-tooltip-arrow-content,
.syngenta-ant-tooltip-placement-topLeft .syngenta-ant-tooltip-arrow-content,
.syngenta-ant-tooltip-placement-topRight .syngenta-ant-tooltip-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-11px) rotate(45deg);
}
.syngenta-ant-tooltip-placement-top .syngenta-ant-tooltip-arrow {
  left: 50%;
  transform: translateY(100%) translateX(-50%);
}
.syngenta-ant-tooltip-placement-topLeft .syngenta-ant-tooltip-arrow {
  left: 13px;
}
.syngenta-ant-tooltip-placement-topRight .syngenta-ant-tooltip-arrow {
  right: 13px;
}
.syngenta-ant-tooltip-placement-right .syngenta-ant-tooltip-arrow,
.syngenta-ant-tooltip-placement-rightTop .syngenta-ant-tooltip-arrow,
.syngenta-ant-tooltip-placement-rightBottom .syngenta-ant-tooltip-arrow {
  left: 0;
  transform: translateX(-100%);
}
.syngenta-ant-tooltip-placement-right .syngenta-ant-tooltip-arrow-content,
.syngenta-ant-tooltip-placement-rightTop .syngenta-ant-tooltip-arrow-content,
.syngenta-ant-tooltip-placement-rightBottom .syngenta-ant-tooltip-arrow-content {
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(11px) rotate(135deg);
}
.syngenta-ant-tooltip-placement-right .syngenta-ant-tooltip-arrow {
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
}
.syngenta-ant-tooltip-placement-rightTop .syngenta-ant-tooltip-arrow {
  top: 5px;
}
.syngenta-ant-tooltip-placement-rightBottom .syngenta-ant-tooltip-arrow {
  bottom: 5px;
}
.syngenta-ant-tooltip-placement-left .syngenta-ant-tooltip-arrow,
.syngenta-ant-tooltip-placement-leftTop .syngenta-ant-tooltip-arrow,
.syngenta-ant-tooltip-placement-leftBottom .syngenta-ant-tooltip-arrow {
  right: 0;
  transform: translateX(100%);
}
.syngenta-ant-tooltip-placement-left .syngenta-ant-tooltip-arrow-content,
.syngenta-ant-tooltip-placement-leftTop .syngenta-ant-tooltip-arrow-content,
.syngenta-ant-tooltip-placement-leftBottom .syngenta-ant-tooltip-arrow-content {
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-11px) rotate(315deg);
}
.syngenta-ant-tooltip-placement-left .syngenta-ant-tooltip-arrow {
  top: 50%;
  transform: translateX(100%) translateY(-50%);
}
.syngenta-ant-tooltip-placement-leftTop .syngenta-ant-tooltip-arrow {
  top: 5px;
}
.syngenta-ant-tooltip-placement-leftBottom .syngenta-ant-tooltip-arrow {
  bottom: 5px;
}
.syngenta-ant-tooltip-placement-bottom .syngenta-ant-tooltip-arrow,
.syngenta-ant-tooltip-placement-bottomLeft .syngenta-ant-tooltip-arrow,
.syngenta-ant-tooltip-placement-bottomRight .syngenta-ant-tooltip-arrow {
  top: 0;
  transform: translateY(-100%);
}
.syngenta-ant-tooltip-placement-bottom .syngenta-ant-tooltip-arrow-content,
.syngenta-ant-tooltip-placement-bottomLeft .syngenta-ant-tooltip-arrow-content,
.syngenta-ant-tooltip-placement-bottomRight .syngenta-ant-tooltip-arrow-content {
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(11px) rotate(225deg);
}
.syngenta-ant-tooltip-placement-bottom .syngenta-ant-tooltip-arrow {
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}
.syngenta-ant-tooltip-placement-bottomLeft .syngenta-ant-tooltip-arrow {
  left: 13px;
}
.syngenta-ant-tooltip-placement-bottomRight .syngenta-ant-tooltip-arrow {
  right: 13px;
}
.syngenta-ant-tooltip-pink .syngenta-ant-tooltip-inner {
  background-color: #eb2f96;
}
.syngenta-ant-tooltip-pink .syngenta-ant-tooltip-arrow-content::before {
  background: #eb2f96;
}
.syngenta-ant-tooltip-magenta .syngenta-ant-tooltip-inner {
  background-color: #eb2f96;
}
.syngenta-ant-tooltip-magenta .syngenta-ant-tooltip-arrow-content::before {
  background: #eb2f96;
}
.syngenta-ant-tooltip-red .syngenta-ant-tooltip-inner {
  background-color: #f5222d;
}
.syngenta-ant-tooltip-red .syngenta-ant-tooltip-arrow-content::before {
  background: #f5222d;
}
.syngenta-ant-tooltip-volcano .syngenta-ant-tooltip-inner {
  background-color: #fa541c;
}
.syngenta-ant-tooltip-volcano .syngenta-ant-tooltip-arrow-content::before {
  background: #fa541c;
}
.syngenta-ant-tooltip-orange .syngenta-ant-tooltip-inner {
  background-color: #fa8c16;
}
.syngenta-ant-tooltip-orange .syngenta-ant-tooltip-arrow-content::before {
  background: #fa8c16;
}
.syngenta-ant-tooltip-yellow .syngenta-ant-tooltip-inner {
  background-color: #fadb14;
}
.syngenta-ant-tooltip-yellow .syngenta-ant-tooltip-arrow-content::before {
  background: #fadb14;
}
.syngenta-ant-tooltip-gold .syngenta-ant-tooltip-inner {
  background-color: #faad14;
}
.syngenta-ant-tooltip-gold .syngenta-ant-tooltip-arrow-content::before {
  background: #faad14;
}
.syngenta-ant-tooltip-cyan .syngenta-ant-tooltip-inner {
  background-color: #13c2c2;
}
.syngenta-ant-tooltip-cyan .syngenta-ant-tooltip-arrow-content::before {
  background: #13c2c2;
}
.syngenta-ant-tooltip-lime .syngenta-ant-tooltip-inner {
  background-color: #a0d911;
}
.syngenta-ant-tooltip-lime .syngenta-ant-tooltip-arrow-content::before {
  background: #a0d911;
}
.syngenta-ant-tooltip-green .syngenta-ant-tooltip-inner {
  background-color: #52c41a;
}
.syngenta-ant-tooltip-green .syngenta-ant-tooltip-arrow-content::before {
  background: #52c41a;
}
.syngenta-ant-tooltip-blue .syngenta-ant-tooltip-inner {
  background-color: #1890ff;
}
.syngenta-ant-tooltip-blue .syngenta-ant-tooltip-arrow-content::before {
  background: #1890ff;
}
.syngenta-ant-tooltip-geekblue .syngenta-ant-tooltip-inner {
  background-color: #2f54eb;
}
.syngenta-ant-tooltip-geekblue .syngenta-ant-tooltip-arrow-content::before {
  background: #2f54eb;
}
.syngenta-ant-tooltip-purple .syngenta-ant-tooltip-inner {
  background-color: #722ed1;
}
.syngenta-ant-tooltip-purple .syngenta-ant-tooltip-arrow-content::before {
  background: #722ed1;
}
.syngenta-ant-tooltip-rtl {
  direction: rtl;
}
.syngenta-ant-tooltip-rtl .syngenta-ant-tooltip-inner {
  text-align: right;
}
.syngenta-ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  max-width: 100%;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  user-select: text;
}
.syngenta-ant-popover-content {
  position: relative;
}
.syngenta-ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}
.syngenta-ant-popover-hidden {
  display: none;
}
.syngenta-ant-popover-placement-top,
.syngenta-ant-popover-placement-topLeft,
.syngenta-ant-popover-placement-topRight {
  padding-bottom: 15.3137085px;
}
.syngenta-ant-popover-placement-right,
.syngenta-ant-popover-placement-rightTop,
.syngenta-ant-popover-placement-rightBottom {
  padding-left: 15.3137085px;
}
.syngenta-ant-popover-placement-bottom,
.syngenta-ant-popover-placement-bottomLeft,
.syngenta-ant-popover-placement-bottomRight {
  padding-top: 15.3137085px;
}
.syngenta-ant-popover-placement-left,
.syngenta-ant-popover-placement-leftTop,
.syngenta-ant-popover-placement-leftBottom {
  padding-right: 15.3137085px;
}
.syngenta-ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .syngenta-ant-popover {
    /* IE10+ */
  }
  .syngenta-ant-popover-inner {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
}
.syngenta-ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: #707374;
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
}
.syngenta-ant-popover-inner-content {
  width: max-content;
  max-width: 100%;
  padding: 12px 16px;
  color: #2f3031;
}
.syngenta-ant-popover-message {
  display: flex;
  padding: 4px 0 12px;
  color: #2f3031;
  font-size: 14px;
}
.syngenta-ant-popover-message-icon {
  display: inline-block;
  margin-right: 8px;
  color: #faad14;
  font-size: 14px;
}
.syngenta-ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}
.syngenta-ant-popover-buttons button:not(:first-child) {
  margin-left: 8px;
}
.syngenta-ant-popover-arrow {
  position: absolute;
  display: block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.syngenta-ant-popover-arrow-content {
  --antd-arrow-background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  margin: auto;
  content: '';
  pointer-events: auto;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.syngenta-ant-popover-arrow-content::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: var(--antd-arrow-background-color);
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  clip-path: inset(33% 33%);
  clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.syngenta-ant-popover-placement-top .syngenta-ant-popover-arrow,
.syngenta-ant-popover-placement-topLeft .syngenta-ant-popover-arrow,
.syngenta-ant-popover-placement-topRight .syngenta-ant-popover-arrow {
  bottom: 0;
  transform: translateY(100%);
}
.syngenta-ant-popover-placement-top .syngenta-ant-popover-arrow-content,
.syngenta-ant-popover-placement-topLeft .syngenta-ant-popover-arrow-content,
.syngenta-ant-popover-placement-topRight .syngenta-ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-11px) rotate(45deg);
}
.syngenta-ant-popover-placement-top .syngenta-ant-popover-arrow {
  left: 50%;
  transform: translateY(100%) translateX(-50%);
}
.syngenta-ant-popover-placement-topLeft .syngenta-ant-popover-arrow {
  left: 16px;
}
.syngenta-ant-popover-placement-topRight .syngenta-ant-popover-arrow {
  right: 16px;
}
.syngenta-ant-popover-placement-right .syngenta-ant-popover-arrow,
.syngenta-ant-popover-placement-rightTop .syngenta-ant-popover-arrow,
.syngenta-ant-popover-placement-rightBottom .syngenta-ant-popover-arrow {
  left: 0;
  transform: translateX(-100%);
}
.syngenta-ant-popover-placement-right .syngenta-ant-popover-arrow-content,
.syngenta-ant-popover-placement-rightTop .syngenta-ant-popover-arrow-content,
.syngenta-ant-popover-placement-rightBottom .syngenta-ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(11px) rotate(135deg);
}
.syngenta-ant-popover-placement-right .syngenta-ant-popover-arrow {
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
}
.syngenta-ant-popover-placement-rightTop .syngenta-ant-popover-arrow {
  top: 12px;
}
.syngenta-ant-popover-placement-rightBottom .syngenta-ant-popover-arrow {
  bottom: 12px;
}
.syngenta-ant-popover-placement-bottom .syngenta-ant-popover-arrow,
.syngenta-ant-popover-placement-bottomLeft .syngenta-ant-popover-arrow,
.syngenta-ant-popover-placement-bottomRight .syngenta-ant-popover-arrow {
  top: 0;
  transform: translateY(-100%);
}
.syngenta-ant-popover-placement-bottom .syngenta-ant-popover-arrow-content,
.syngenta-ant-popover-placement-bottomLeft .syngenta-ant-popover-arrow-content,
.syngenta-ant-popover-placement-bottomRight .syngenta-ant-popover-arrow-content {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.06);
  transform: translateY(11px) rotate(-135deg);
}
.syngenta-ant-popover-placement-bottom .syngenta-ant-popover-arrow {
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}
.syngenta-ant-popover-placement-bottomLeft .syngenta-ant-popover-arrow {
  left: 16px;
}
.syngenta-ant-popover-placement-bottomRight .syngenta-ant-popover-arrow {
  right: 16px;
}
.syngenta-ant-popover-placement-left .syngenta-ant-popover-arrow,
.syngenta-ant-popover-placement-leftTop .syngenta-ant-popover-arrow,
.syngenta-ant-popover-placement-leftBottom .syngenta-ant-popover-arrow {
  right: 0;
  transform: translateX(100%);
}
.syngenta-ant-popover-placement-left .syngenta-ant-popover-arrow-content,
.syngenta-ant-popover-placement-leftTop .syngenta-ant-popover-arrow-content,
.syngenta-ant-popover-placement-leftBottom .syngenta-ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-11px) rotate(-45deg);
}
.syngenta-ant-popover-placement-left .syngenta-ant-popover-arrow {
  top: 50%;
  transform: translateX(100%) translateY(-50%);
}
.syngenta-ant-popover-placement-leftTop .syngenta-ant-popover-arrow {
  top: 12px;
}
.syngenta-ant-popover-placement-leftBottom .syngenta-ant-popover-arrow {
  bottom: 12px;
}
.syngenta-ant-popover-pink .syngenta-ant-popover-inner {
  background-color: #eb2f96;
}
.syngenta-ant-popover-pink .syngenta-ant-popover-arrow-content {
  background-color: #eb2f96;
}
.syngenta-ant-popover-magenta .syngenta-ant-popover-inner {
  background-color: #eb2f96;
}
.syngenta-ant-popover-magenta .syngenta-ant-popover-arrow-content {
  background-color: #eb2f96;
}
.syngenta-ant-popover-red .syngenta-ant-popover-inner {
  background-color: #f5222d;
}
.syngenta-ant-popover-red .syngenta-ant-popover-arrow-content {
  background-color: #f5222d;
}
.syngenta-ant-popover-volcano .syngenta-ant-popover-inner {
  background-color: #fa541c;
}
.syngenta-ant-popover-volcano .syngenta-ant-popover-arrow-content {
  background-color: #fa541c;
}
.syngenta-ant-popover-orange .syngenta-ant-popover-inner {
  background-color: #fa8c16;
}
.syngenta-ant-popover-orange .syngenta-ant-popover-arrow-content {
  background-color: #fa8c16;
}
.syngenta-ant-popover-yellow .syngenta-ant-popover-inner {
  background-color: #fadb14;
}
.syngenta-ant-popover-yellow .syngenta-ant-popover-arrow-content {
  background-color: #fadb14;
}
.syngenta-ant-popover-gold .syngenta-ant-popover-inner {
  background-color: #faad14;
}
.syngenta-ant-popover-gold .syngenta-ant-popover-arrow-content {
  background-color: #faad14;
}
.syngenta-ant-popover-cyan .syngenta-ant-popover-inner {
  background-color: #13c2c2;
}
.syngenta-ant-popover-cyan .syngenta-ant-popover-arrow-content {
  background-color: #13c2c2;
}
.syngenta-ant-popover-lime .syngenta-ant-popover-inner {
  background-color: #a0d911;
}
.syngenta-ant-popover-lime .syngenta-ant-popover-arrow-content {
  background-color: #a0d911;
}
.syngenta-ant-popover-green .syngenta-ant-popover-inner {
  background-color: #52c41a;
}
.syngenta-ant-popover-green .syngenta-ant-popover-arrow-content {
  background-color: #52c41a;
}
.syngenta-ant-popover-blue .syngenta-ant-popover-inner {
  background-color: #1890ff;
}
.syngenta-ant-popover-blue .syngenta-ant-popover-arrow-content {
  background-color: #1890ff;
}
.syngenta-ant-popover-geekblue .syngenta-ant-popover-inner {
  background-color: #2f54eb;
}
.syngenta-ant-popover-geekblue .syngenta-ant-popover-arrow-content {
  background-color: #2f54eb;
}
.syngenta-ant-popover-purple .syngenta-ant-popover-inner {
  background-color: #722ed1;
}
.syngenta-ant-popover-purple .syngenta-ant-popover-arrow-content {
  background-color: #722ed1;
}
.syngenta-ant-popover-rtl {
  direction: rtl;
  text-align: right;
}
.syngenta-ant-popover-rtl .syngenta-ant-popover-message-icon {
  margin-right: 0;
  margin-left: 8px;
}
.syngenta-ant-popover-rtl .syngenta-ant-popover-message-title {
  padding-left: 16px;
}
.syngenta-ant-popover-rtl .syngenta-ant-popover-buttons {
  text-align: left;
}
.syngenta-ant-popover-rtl .syngenta-ant-popover-buttons button {
  margin-right: 8px;
  margin-left: 0;
}
.syngenta-ant-dropdown-menu-item.syngenta-ant-dropdown-menu-item-danger {
  color: #ff4d4f;
}
.syngenta-ant-dropdown-menu-item.syngenta-ant-dropdown-menu-item-danger:hover {
  color: #fff;
  background-color: #ff4d4f;
}
.syngenta-ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.syngenta-ant-dropdown::before {
  position: absolute;
  top: -4px;
  right: 0;
  bottom: -4px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}
.syngenta-ant-dropdown-wrap {
  position: relative;
}
.syngenta-ant-dropdown-wrap .syngenta-ant-btn > .anticon-down {
  font-size: 10px;
}
.syngenta-ant-dropdown-wrap .anticon-down::before {
  transition: transform 0.2s;
}
.syngenta-ant-dropdown-wrap-open .anticon-down::before {
  transform: rotate(180deg);
}
.syngenta-ant-dropdown-hidden,
.syngenta-ant-dropdown-menu-hidden,
.syngenta-ant-dropdown-menu-submenu-hidden {
  display: none;
}
.syngenta-ant-dropdown-show-arrow.syngenta-ant-dropdown-placement-topLeft,
.syngenta-ant-dropdown-show-arrow.syngenta-ant-dropdown-placement-top,
.syngenta-ant-dropdown-show-arrow.syngenta-ant-dropdown-placement-topRight {
  padding-bottom: 15.3137085px;
}
.syngenta-ant-dropdown-show-arrow.syngenta-ant-dropdown-placement-bottomLeft,
.syngenta-ant-dropdown-show-arrow.syngenta-ant-dropdown-placement-bottom,
.syngenta-ant-dropdown-show-arrow.syngenta-ant-dropdown-placement-bottomRight {
  padding-top: 15.3137085px;
}
.syngenta-ant-dropdown-arrow {
  position: absolute;
  z-index: 1;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.syngenta-ant-dropdown-arrow::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: #fff;
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  clip-path: inset(33% 33%);
  clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.syngenta-ant-dropdown-placement-top > .syngenta-ant-dropdown-arrow,
.syngenta-ant-dropdown-placement-topLeft > .syngenta-ant-dropdown-arrow,
.syngenta-ant-dropdown-placement-topRight > .syngenta-ant-dropdown-arrow {
  bottom: 10px;
  box-shadow: 3px 3px 7px -3px rgba(0, 0, 0, 0.1);
  transform: rotate(45deg);
}
.syngenta-ant-dropdown-placement-top > .syngenta-ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.syngenta-ant-dropdown-placement-topLeft > .syngenta-ant-dropdown-arrow {
  left: 16px;
}
.syngenta-ant-dropdown-placement-topRight > .syngenta-ant-dropdown-arrow {
  right: 16px;
}
.syngenta-ant-dropdown-placement-bottom > .syngenta-ant-dropdown-arrow,
.syngenta-ant-dropdown-placement-bottomLeft > .syngenta-ant-dropdown-arrow,
.syngenta-ant-dropdown-placement-bottomRight > .syngenta-ant-dropdown-arrow {
  top: 9.41421356px;
  box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.1);
  transform: rotate(-135deg) translateY(-0.5px);
}
.syngenta-ant-dropdown-placement-bottom > .syngenta-ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(-135deg) translateY(-0.5px);
}
.syngenta-ant-dropdown-placement-bottomLeft > .syngenta-ant-dropdown-arrow {
  left: 16px;
}
.syngenta-ant-dropdown-placement-bottomRight > .syngenta-ant-dropdown-arrow {
  right: 16px;
}
.syngenta-ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.syngenta-ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: #707374;
  transition: all 0.3s;
}
.syngenta-ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  box-shadow: none;
  transform-origin: 0 0;
}
.syngenta-ant-dropdown-menu-submenu-popup ul,
.syngenta-ant-dropdown-menu-submenu-popup li {
  list-style: none;
}
.syngenta-ant-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
}
.syngenta-ant-dropdown-menu-item {
  position: relative;
  display: flex;
  align-items: center;
}
.syngenta-ant-dropdown-menu-item-icon {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
}
.syngenta-ant-dropdown-menu-title-content {
  flex: auto;
}
.syngenta-ant-dropdown-menu-title-content > a {
  color: inherit;
  transition: all 0.3s;
}
.syngenta-ant-dropdown-menu-title-content > a:hover {
  color: inherit;
}
.syngenta-ant-dropdown-menu-title-content > a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}
.syngenta-ant-dropdown-menu-item,
.syngenta-ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: #2f3031;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s;
}
.syngenta-ant-dropdown-menu-item-selected,
.syngenta-ant-dropdown-menu-submenu-title-selected {
  color: #000;
  background-color: #404040;
}
.syngenta-ant-dropdown-menu-item:hover,
.syngenta-ant-dropdown-menu-submenu-title:hover,
.syngenta-ant-dropdown-menu-item.syngenta-ant-dropdown-menu-item-active,
.syngenta-ant-dropdown-menu-item.syngenta-ant-dropdown-menu-submenu-title-active,
.syngenta-ant-dropdown-menu-submenu-title.syngenta-ant-dropdown-menu-item-active,
.syngenta-ant-dropdown-menu-submenu-title.syngenta-ant-dropdown-menu-submenu-title-active {
  background-color: #f5f5f5;
}
.syngenta-ant-dropdown-menu-item.syngenta-ant-dropdown-menu-item-disabled,
.syngenta-ant-dropdown-menu-item.syngenta-ant-dropdown-menu-submenu-title-disabled,
.syngenta-ant-dropdown-menu-submenu-title.syngenta-ant-dropdown-menu-item-disabled,
.syngenta-ant-dropdown-menu-submenu-title.syngenta-ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.syngenta-ant-dropdown-menu-item.syngenta-ant-dropdown-menu-item-disabled:hover,
.syngenta-ant-dropdown-menu-item.syngenta-ant-dropdown-menu-submenu-title-disabled:hover,
.syngenta-ant-dropdown-menu-submenu-title.syngenta-ant-dropdown-menu-item-disabled:hover,
.syngenta-ant-dropdown-menu-submenu-title.syngenta-ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.syngenta-ant-dropdown-menu-item.syngenta-ant-dropdown-menu-item-disabled a,
.syngenta-ant-dropdown-menu-item.syngenta-ant-dropdown-menu-submenu-title-disabled a,
.syngenta-ant-dropdown-menu-submenu-title.syngenta-ant-dropdown-menu-item-disabled a,
.syngenta-ant-dropdown-menu-submenu-title.syngenta-ant-dropdown-menu-submenu-title-disabled a {
  pointer-events: none;
}
.syngenta-ant-dropdown-menu-item-divider,
.syngenta-ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.syngenta-ant-dropdown-menu-item .syngenta-ant-dropdown-menu-submenu-expand-icon,
.syngenta-ant-dropdown-menu-submenu-title .syngenta-ant-dropdown-menu-submenu-expand-icon {
  position: absolute;
  right: 8px;
}
.syngenta-ant-dropdown-menu-item .syngenta-ant-dropdown-menu-submenu-expand-icon .syngenta-ant-dropdown-menu-submenu-arrow-icon,
.syngenta-ant-dropdown-menu-submenu-title .syngenta-ant-dropdown-menu-submenu-expand-icon .syngenta-ant-dropdown-menu-submenu-arrow-icon {
  margin-right: 0 !important;
  color: #707374;
  font-size: 10px;
  font-style: normal;
}
.syngenta-ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}
.syngenta-ant-dropdown-menu-submenu-title {
  padding-right: 24px;
}
.syngenta-ant-dropdown-menu-submenu-vertical {
  position: relative;
}
.syngenta-ant-dropdown-menu-submenu-vertical > .syngenta-ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  transform-origin: 0 0;
}
.syngenta-ant-dropdown-menu-submenu.syngenta-ant-dropdown-menu-submenu-disabled .syngenta-ant-dropdown-menu-submenu-title,
.syngenta-ant-dropdown-menu-submenu.syngenta-ant-dropdown-menu-submenu-disabled .syngenta-ant-dropdown-menu-submenu-title .syngenta-ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.syngenta-ant-dropdown-menu-submenu-selected .syngenta-ant-dropdown-menu-submenu-title {
  color: #000;
}
.syngenta-ant-dropdown.syngenta-ant-slide-down-enter.syngenta-ant-slide-down-enter-active.syngenta-ant-dropdown-placement-bottomLeft,
.syngenta-ant-dropdown.syngenta-ant-slide-down-appear.syngenta-ant-slide-down-appear-active.syngenta-ant-dropdown-placement-bottomLeft,
.syngenta-ant-dropdown.syngenta-ant-slide-down-enter.syngenta-ant-slide-down-enter-active.syngenta-ant-dropdown-placement-bottom,
.syngenta-ant-dropdown.syngenta-ant-slide-down-appear.syngenta-ant-slide-down-appear-active.syngenta-ant-dropdown-placement-bottom,
.syngenta-ant-dropdown.syngenta-ant-slide-down-enter.syngenta-ant-slide-down-enter-active.syngenta-ant-dropdown-placement-bottomRight,
.syngenta-ant-dropdown.syngenta-ant-slide-down-appear.syngenta-ant-slide-down-appear-active.syngenta-ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.syngenta-ant-dropdown.syngenta-ant-slide-up-enter.syngenta-ant-slide-up-enter-active.syngenta-ant-dropdown-placement-topLeft,
.syngenta-ant-dropdown.syngenta-ant-slide-up-appear.syngenta-ant-slide-up-appear-active.syngenta-ant-dropdown-placement-topLeft,
.syngenta-ant-dropdown.syngenta-ant-slide-up-enter.syngenta-ant-slide-up-enter-active.syngenta-ant-dropdown-placement-top,
.syngenta-ant-dropdown.syngenta-ant-slide-up-appear.syngenta-ant-slide-up-appear-active.syngenta-ant-dropdown-placement-top,
.syngenta-ant-dropdown.syngenta-ant-slide-up-enter.syngenta-ant-slide-up-enter-active.syngenta-ant-dropdown-placement-topRight,
.syngenta-ant-dropdown.syngenta-ant-slide-up-appear.syngenta-ant-slide-up-appear-active.syngenta-ant-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.syngenta-ant-dropdown.syngenta-ant-slide-down-leave.syngenta-ant-slide-down-leave-active.syngenta-ant-dropdown-placement-bottomLeft,
.syngenta-ant-dropdown.syngenta-ant-slide-down-leave.syngenta-ant-slide-down-leave-active.syngenta-ant-dropdown-placement-bottom,
.syngenta-ant-dropdown.syngenta-ant-slide-down-leave.syngenta-ant-slide-down-leave-active.syngenta-ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.syngenta-ant-dropdown.syngenta-ant-slide-up-leave.syngenta-ant-slide-up-leave-active.syngenta-ant-dropdown-placement-topLeft,
.syngenta-ant-dropdown.syngenta-ant-slide-up-leave.syngenta-ant-slide-up-leave-active.syngenta-ant-dropdown-placement-top,
.syngenta-ant-dropdown.syngenta-ant-slide-up-leave.syngenta-ant-slide-up-leave-active.syngenta-ant-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.syngenta-ant-dropdown-trigger > .anticon.anticon-down,
.syngenta-ant-dropdown-link > .anticon.anticon-down,
.syngenta-ant-dropdown-button > .anticon.anticon-down {
  font-size: 10px;
  vertical-align: baseline;
}
.syngenta-ant-dropdown-button {
  white-space: nowrap;
}
.syngenta-ant-dropdown-button.syngenta-ant-btn-group > .syngenta-ant-btn-loading,
.syngenta-ant-dropdown-button.syngenta-ant-btn-group > .syngenta-ant-btn-loading + .syngenta-ant-btn {
  cursor: default;
  pointer-events: none;
}
.syngenta-ant-dropdown-button.syngenta-ant-btn-group > .syngenta-ant-btn-loading + .syngenta-ant-btn::before {
  display: block;
}
.syngenta-ant-dropdown-button.syngenta-ant-btn-group > .syngenta-ant-btn:last-child:not(:first-child):not(.syngenta-ant-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px;
}
.syngenta-ant-dropdown-menu-dark,
.syngenta-ant-dropdown-menu-dark .syngenta-ant-dropdown-menu {
  background: #2f3031;
}
.syngenta-ant-dropdown-menu-dark .syngenta-ant-dropdown-menu-item,
.syngenta-ant-dropdown-menu-dark .syngenta-ant-dropdown-menu-submenu-title,
.syngenta-ant-dropdown-menu-dark .syngenta-ant-dropdown-menu-item > a,
.syngenta-ant-dropdown-menu-dark .syngenta-ant-dropdown-menu-item > .anticon + span > a {
  color: rgba(255, 255, 255, 0.65);
}
.syngenta-ant-dropdown-menu-dark .syngenta-ant-dropdown-menu-item .syngenta-ant-dropdown-menu-submenu-arrow::after,
.syngenta-ant-dropdown-menu-dark .syngenta-ant-dropdown-menu-submenu-title .syngenta-ant-dropdown-menu-submenu-arrow::after,
.syngenta-ant-dropdown-menu-dark .syngenta-ant-dropdown-menu-item > a .syngenta-ant-dropdown-menu-submenu-arrow::after,
.syngenta-ant-dropdown-menu-dark .syngenta-ant-dropdown-menu-item > .anticon + span > a .syngenta-ant-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65);
}
.syngenta-ant-dropdown-menu-dark .syngenta-ant-dropdown-menu-item:hover,
.syngenta-ant-dropdown-menu-dark .syngenta-ant-dropdown-menu-submenu-title:hover,
.syngenta-ant-dropdown-menu-dark .syngenta-ant-dropdown-menu-item > a:hover,
.syngenta-ant-dropdown-menu-dark .syngenta-ant-dropdown-menu-item > .anticon + span > a:hover {
  color: #fff;
  background: transparent;
}
.syngenta-ant-dropdown-menu-dark .syngenta-ant-dropdown-menu-item-selected,
.syngenta-ant-dropdown-menu-dark .syngenta-ant-dropdown-menu-item-selected:hover,
.syngenta-ant-dropdown-menu-dark .syngenta-ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #000;
}
.syngenta-ant-dropdown-rtl {
  direction: rtl;
}
.syngenta-ant-dropdown-rtl.syngenta-ant-dropdown::before {
  right: -7px;
  left: 0;
}
.syngenta-ant-dropdown-menu.syngenta-ant-dropdown-menu-rtl {
  direction: rtl;
  text-align: right;
}
.syngenta-ant-dropdown-rtl .syngenta-ant-dropdown-menu-item-group-title,
.syngenta-ant-dropdown-menu-submenu-rtl .syngenta-ant-dropdown-menu-item-group-title {
  direction: rtl;
  text-align: right;
}
.syngenta-ant-dropdown-menu-submenu-popup.syngenta-ant-dropdown-menu-submenu-rtl {
  transform-origin: 100% 0;
}
.syngenta-ant-dropdown-rtl .syngenta-ant-dropdown-menu-submenu-popup ul,
.syngenta-ant-dropdown-rtl .syngenta-ant-dropdown-menu-submenu-popup li {
  text-align: right;
}
.syngenta-ant-dropdown-rtl .syngenta-ant-dropdown-menu-item,
.syngenta-ant-dropdown-rtl .syngenta-ant-dropdown-menu-submenu-title {
  text-align: right;
}
.syngenta-ant-dropdown-rtl .syngenta-ant-dropdown-menu-item > .anticon:first-child,
.syngenta-ant-dropdown-rtl .syngenta-ant-dropdown-menu-submenu-title > .anticon:first-child,
.syngenta-ant-dropdown-rtl .syngenta-ant-dropdown-menu-item > span > .anticon:first-child,
.syngenta-ant-dropdown-rtl .syngenta-ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  margin-right: 0;
  margin-left: 8px;
}
.syngenta-ant-dropdown-rtl .syngenta-ant-dropdown-menu-item .syngenta-ant-dropdown-menu-submenu-expand-icon,
.syngenta-ant-dropdown-rtl .syngenta-ant-dropdown-menu-submenu-title .syngenta-ant-dropdown-menu-submenu-expand-icon {
  right: auto;
  left: 8px;
}
.syngenta-ant-dropdown-rtl .syngenta-ant-dropdown-menu-item .syngenta-ant-dropdown-menu-submenu-expand-icon .syngenta-ant-dropdown-menu-submenu-arrow-icon,
.syngenta-ant-dropdown-rtl .syngenta-ant-dropdown-menu-submenu-title .syngenta-ant-dropdown-menu-submenu-expand-icon .syngenta-ant-dropdown-menu-submenu-arrow-icon {
  margin-left: 0 !important;
  transform: scaleX(-1);
}
.syngenta-ant-dropdown-rtl .syngenta-ant-dropdown-menu-submenu-title {
  padding-right: 12px;
  padding-left: 24px;
}
.syngenta-ant-dropdown-rtl .syngenta-ant-dropdown-menu-submenu-vertical > .syngenta-ant-dropdown-menu {
  right: 100%;
  left: 0;
  margin-right: 4px;
  margin-left: 0;
}
.syngenta-ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  pointer-events: none;
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding-bottom: 24px;
}
.syngenta-ant-modal.syngenta-ant-zoom-enter,
.syngenta-ant-modal.syngenta-ant-zoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  user-select: none;
}
.syngenta-ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.syngenta-ant-modal-mask-hidden {
  display: none;
}
.syngenta-ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
}
.syngenta-ant-modal-wrap {
  z-index: 1000;
}
.syngenta-ant-modal-title {
  margin: 0;
  color: #F74141;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.syngenta-ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}
.syngenta-ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: #707374;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.syngenta-ant-modal-close-x {
  display: block;
  width: 54px;
  height: 54px;
  font-size: 16px;
  font-style: normal;
  line-height: 54px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.syngenta-ant-modal-close:focus,
.syngenta-ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.syngenta-ant-modal-header {
  padding: 16px 24px;
  color: #2f3031;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.syngenta-ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.syngenta-ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #ffffff;
  border-radius: 0 0 2px 2px;
}
.syngenta-ant-modal-footer .syngenta-ant-btn + .syngenta-ant-btn:not(.syngenta-ant-dropdown-trigger) {
  margin-bottom: 0;
  margin-left: 8px;
}
.syngenta-ant-modal-open {
  overflow: hidden;
}
.syngenta-ant-modal-centered {
  text-align: center;
}
.syngenta-ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.syngenta-ant-modal-centered .syngenta-ant-modal {
  top: 0;
  display: inline-block;
  padding-bottom: 0;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .syngenta-ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  .syngenta-ant-modal-centered .syngenta-ant-modal {
    flex: 1;
  }
}
.syngenta-ant-modal-confirm .syngenta-ant-modal-header {
  display: none;
}
.syngenta-ant-modal-confirm .syngenta-ant-modal-body {
  padding: 32px 32px 24px;
}
.syngenta-ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.syngenta-ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.syngenta-ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-modal-confirm-body .syngenta-ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: #707374;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.syngenta-ant-modal-confirm-body .syngenta-ant-modal-confirm-content {
  margin-top: 8px;
  color: #2f3031;
  font-size: 14px;
}
.syngenta-ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
.syngenta-ant-modal-confirm-body > .anticon + .syngenta-ant-modal-confirm-title + .syngenta-ant-modal-confirm-content {
  margin-left: 38px;
}
.syngenta-ant-modal-confirm .syngenta-ant-modal-confirm-btns {
  margin-top: 24px;
  text-align: right;
}
.syngenta-ant-modal-confirm .syngenta-ant-modal-confirm-btns .syngenta-ant-btn + .syngenta-ant-btn {
  margin-bottom: 0;
  margin-left: 8px;
}
.syngenta-ant-modal-confirm-error .syngenta-ant-modal-confirm-body > .anticon {
  color: #ff4d4f;
}
.syngenta-ant-modal-confirm-warning .syngenta-ant-modal-confirm-body > .anticon,
.syngenta-ant-modal-confirm-confirm .syngenta-ant-modal-confirm-body > .anticon {
  color: #faad14;
}
.syngenta-ant-modal-confirm-info .syngenta-ant-modal-confirm-body > .anticon {
  color: #000;
}
.syngenta-ant-modal-confirm-success .syngenta-ant-modal-confirm-body > .anticon {
  color: #52c41a;
}
.syngenta-ant-modal-confirm .syngenta-ant-zoom-leave .syngenta-ant-modal-confirm-btns {
  pointer-events: none;
}
.syngenta-ant-modal-wrap-rtl {
  direction: rtl;
}
.syngenta-ant-modal-wrap-rtl .syngenta-ant-modal-close {
  right: initial;
  left: 0;
}
.syngenta-ant-modal-wrap-rtl .syngenta-ant-modal-footer {
  text-align: left;
}
.syngenta-ant-modal-wrap-rtl .syngenta-ant-modal-footer .syngenta-ant-btn + .syngenta-ant-btn {
  margin-right: 8px;
  margin-left: 0;
}
.syngenta-ant-modal-wrap-rtl .syngenta-ant-modal-confirm-body {
  direction: rtl;
}
.syngenta-ant-modal-wrap-rtl .syngenta-ant-modal-confirm-body > .anticon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.syngenta-ant-modal-wrap-rtl .syngenta-ant-modal-confirm-body > .anticon + .syngenta-ant-modal-confirm-title + .syngenta-ant-modal-confirm-content {
  margin-right: 38px;
  margin-left: 0;
}
.syngenta-ant-modal-wrap-rtl .syngenta-ant-modal-confirm-btns {
  text-align: left;
}
.syngenta-ant-modal-wrap-rtl .syngenta-ant-modal-confirm-btns .syngenta-ant-btn + .syngenta-ant-btn {
  margin-right: 8px;
  margin-left: 0;
}
.syngenta-ant-modal-wrap-rtl.syngenta-ant-modal-centered .syngenta-ant-modal {
  text-align: right;
}
.syngenta-ant-menu-item-danger.syngenta-ant-menu-item {
  color: #ff4d4f;
}
.syngenta-ant-menu-item-danger.syngenta-ant-menu-item:hover,
.syngenta-ant-menu-item-danger.syngenta-ant-menu-item-active {
  color: #ff4d4f;
}
.syngenta-ant-menu-item-danger.syngenta-ant-menu-item:active {
  background: #fff1f0;
}
.syngenta-ant-menu-item-danger.syngenta-ant-menu-item-selected {
  color: #ff4d4f;
}
.syngenta-ant-menu-item-danger.syngenta-ant-menu-item-selected > a,
.syngenta-ant-menu-item-danger.syngenta-ant-menu-item-selected > a:hover {
  color: #ff4d4f;
}
.syngenta-ant-menu:not(.syngenta-ant-menu-horizontal) .syngenta-ant-menu-item-danger.syngenta-ant-menu-item-selected {
  background-color: #fff1f0;
}
.syngenta-ant-menu-inline .syngenta-ant-menu-item-danger.syngenta-ant-menu-item::after {
  border-right-color: #ff4d4f;
}
.syngenta-ant-menu-dark .syngenta-ant-menu-item-danger.syngenta-ant-menu-item,
.syngenta-ant-menu-dark .syngenta-ant-menu-item-danger.syngenta-ant-menu-item:hover,
.syngenta-ant-menu-dark .syngenta-ant-menu-item-danger.syngenta-ant-menu-item > a {
  color: #ff4d4f;
}
.syngenta-ant-menu-dark.syngenta-ant-menu-dark:not(.syngenta-ant-menu-horizontal) .syngenta-ant-menu-item-danger.syngenta-ant-menu-item-selected {
  color: #fff;
  background-color: #ff4d4f;
}
.syngenta-ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum';
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(255, 255, 255, 0.65);
  font-size: 14px;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #2f3031;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.syngenta-ant-menu::before {
  display: table;
  content: '';
}
.syngenta-ant-menu::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-menu::before {
  display: table;
  content: '';
}
.syngenta-ant-menu::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-menu.syngenta-ant-menu-root:focus-visible {
  box-shadow: 0 0 0 2px #333333;
}
.syngenta-ant-menu ul,
.syngenta-ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.syngenta-ant-menu-overflow {
  display: flex;
}
.syngenta-ant-menu-overflow-item {
  flex: none;
}
.syngenta-ant-menu-hidden,
.syngenta-ant-menu-submenu-hidden {
  display: none;
}
.syngenta-ant-menu-item-group-title {
  height: 1.5715;
  padding: 8px 16px;
  color: #707374;
  font-size: 14px;
  line-height: 1.5715;
  transition: all 0.3s;
}
.syngenta-ant-menu-horizontal .syngenta-ant-menu-submenu {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syngenta-ant-menu-submenu,
.syngenta-ant-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syngenta-ant-menu-submenu-selected {
  color: #fff;
}
.syngenta-ant-menu-item:active,
.syngenta-ant-menu-submenu-title:active {
  background: #171718;
}
.syngenta-ant-menu-submenu .syngenta-ant-menu-sub {
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syngenta-ant-menu-title-content {
  transition: color 0.3s;
}
.syngenta-ant-menu-item a {
  color: rgba(255, 255, 255, 0.65);
}
.syngenta-ant-menu-item a:hover {
  color: #fff;
}
.syngenta-ant-menu-item a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}
.syngenta-ant-menu-item > .syngenta-ant-badge a {
  color: rgba(255, 255, 255, 0.65);
}
.syngenta-ant-menu-item > .syngenta-ant-badge a:hover {
  color: #fff;
}
.syngenta-ant-menu-item-divider {
  overflow: hidden;
  line-height: 0;
  border-color: #f0f0f0;
  border-style: solid;
  border-width: 1px 0 0;
}
.syngenta-ant-menu-item-divider-dashed {
  border-style: dashed;
}
.syngenta-ant-menu-horizontal .syngenta-ant-menu-item,
.syngenta-ant-menu-horizontal .syngenta-ant-menu-submenu {
  margin-top: -1px;
}
.syngenta-ant-menu-horizontal > .syngenta-ant-menu-item:hover,
.syngenta-ant-menu-horizontal > .syngenta-ant-menu-item-active,
.syngenta-ant-menu-horizontal > .syngenta-ant-menu-submenu .syngenta-ant-menu-submenu-title:hover {
  background-color: transparent;
}
.syngenta-ant-menu-item-selected {
  color: #fff;
}
.syngenta-ant-menu-item-selected a,
.syngenta-ant-menu-item-selected a:hover {
  color: #fff;
}
.syngenta-ant-menu:not(.syngenta-ant-menu-horizontal) .syngenta-ant-menu-item-selected {
  background-color: #171718;
}
.syngenta-ant-menu-inline,
.syngenta-ant-menu-vertical,
.syngenta-ant-menu-vertical-left {
  border-right: 1px solid #f0f0f0;
}
.syngenta-ant-menu-vertical-right {
  border-left: 1px solid #f0f0f0;
}
.syngenta-ant-menu-vertical.syngenta-ant-menu-sub,
.syngenta-ant-menu-vertical-left.syngenta-ant-menu-sub,
.syngenta-ant-menu-vertical-right.syngenta-ant-menu-sub {
  min-width: 160px;
  max-height: calc(100vh - 100px);
  padding: 0;
  overflow: hidden;
  border-right: 0;
}
.syngenta-ant-menu-vertical.syngenta-ant-menu-sub:not([class*='-active']),
.syngenta-ant-menu-vertical-left.syngenta-ant-menu-sub:not([class*='-active']),
.syngenta-ant-menu-vertical-right.syngenta-ant-menu-sub:not([class*='-active']) {
  overflow-x: hidden;
  overflow-y: auto;
}
.syngenta-ant-menu-vertical.syngenta-ant-menu-sub .syngenta-ant-menu-item,
.syngenta-ant-menu-vertical-left.syngenta-ant-menu-sub .syngenta-ant-menu-item,
.syngenta-ant-menu-vertical-right.syngenta-ant-menu-sub .syngenta-ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.syngenta-ant-menu-vertical.syngenta-ant-menu-sub .syngenta-ant-menu-item::after,
.syngenta-ant-menu-vertical-left.syngenta-ant-menu-sub .syngenta-ant-menu-item::after,
.syngenta-ant-menu-vertical-right.syngenta-ant-menu-sub .syngenta-ant-menu-item::after {
  border-right: 0;
}
.syngenta-ant-menu-vertical.syngenta-ant-menu-sub > .syngenta-ant-menu-item,
.syngenta-ant-menu-vertical-left.syngenta-ant-menu-sub > .syngenta-ant-menu-item,
.syngenta-ant-menu-vertical-right.syngenta-ant-menu-sub > .syngenta-ant-menu-item,
.syngenta-ant-menu-vertical.syngenta-ant-menu-sub > .syngenta-ant-menu-submenu,
.syngenta-ant-menu-vertical-left.syngenta-ant-menu-sub > .syngenta-ant-menu-submenu,
.syngenta-ant-menu-vertical-right.syngenta-ant-menu-sub > .syngenta-ant-menu-submenu {
  transform-origin: 0 0;
}
.syngenta-ant-menu-horizontal.syngenta-ant-menu-sub {
  min-width: 114px;
}
.syngenta-ant-menu-horizontal .syngenta-ant-menu-item,
.syngenta-ant-menu-horizontal .syngenta-ant-menu-submenu-title {
  transition: border-color 0.3s, background 0.3s;
}
.syngenta-ant-menu-item,
.syngenta-ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syngenta-ant-menu-item .syngenta-ant-menu-item-icon,
.syngenta-ant-menu-submenu-title .syngenta-ant-menu-item-icon,
.syngenta-ant-menu-item .anticon,
.syngenta-ant-menu-submenu-title .anticon {
  min-width: 14px;
  font-size: 14px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}
.syngenta-ant-menu-item .syngenta-ant-menu-item-icon + span,
.syngenta-ant-menu-submenu-title .syngenta-ant-menu-item-icon + span,
.syngenta-ant-menu-item .anticon + span,
.syngenta-ant-menu-submenu-title .anticon + span {
  margin-left: 10px;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
}
.syngenta-ant-menu-item .syngenta-ant-menu-item-icon.svg,
.syngenta-ant-menu-submenu-title .syngenta-ant-menu-item-icon.svg {
  vertical-align: -0.125em;
}
.syngenta-ant-menu-item.syngenta-ant-menu-item-only-child > .anticon,
.syngenta-ant-menu-submenu-title.syngenta-ant-menu-item-only-child > .anticon,
.syngenta-ant-menu-item.syngenta-ant-menu-item-only-child > .syngenta-ant-menu-item-icon,
.syngenta-ant-menu-submenu-title.syngenta-ant-menu-item-only-child > .syngenta-ant-menu-item-icon {
  margin-right: 0;
}
.syngenta-ant-menu-item:not(.syngenta-ant-menu-item-disabled):focus-visible,
.syngenta-ant-menu-submenu-title:not(.syngenta-ant-menu-item-disabled):focus-visible {
  box-shadow: 0 0 0 2px #333333;
}
.syngenta-ant-menu > .syngenta-ant-menu-item-divider {
  margin: 1px 0;
  padding: 0;
}
.syngenta-ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  border-radius: 2px;
  box-shadow: none;
  transform-origin: 0 0;
}
.syngenta-ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.0001;
  content: ' ';
}
.syngenta-ant-menu-submenu-placement-rightTop::before {
  top: 0;
  left: -7px;
}
.syngenta-ant-menu-submenu > .syngenta-ant-menu {
  background-color: #2f3031;
  border-radius: 2px;
}
.syngenta-ant-menu-submenu > .syngenta-ant-menu-submenu-title::after {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syngenta-ant-menu-submenu-popup > .syngenta-ant-menu {
  background-color: #171718;
}
.syngenta-ant-menu-submenu-expand-icon,
.syngenta-ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: rgba(255, 255, 255, 0.65);
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syngenta-ant-menu-submenu-arrow::before,
.syngenta-ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentcolor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.syngenta-ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}
.syngenta-ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}
.syngenta-ant-menu-submenu:hover > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-expand-icon,
.syngenta-ant-menu-submenu:hover > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow {
  color: #fff;
}
.syngenta-ant-menu-inline-collapsed .syngenta-ant-menu-submenu-arrow::before,
.syngenta-ant-menu-submenu-inline .syngenta-ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2.5px);
}
.syngenta-ant-menu-inline-collapsed .syngenta-ant-menu-submenu-arrow::after,
.syngenta-ant-menu-submenu-inline .syngenta-ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2.5px);
}
.syngenta-ant-menu-submenu-horizontal .syngenta-ant-menu-submenu-arrow {
  display: none;
}
.syngenta-ant-menu-submenu-open.syngenta-ant-menu-submenu-inline > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow {
  transform: translateY(-2px);
}
.syngenta-ant-menu-submenu-open.syngenta-ant-menu-submenu-inline > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateX(-2.5px);
}
.syngenta-ant-menu-submenu-open.syngenta-ant-menu-submenu-inline > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateX(2.5px);
}
.syngenta-ant-menu-vertical .syngenta-ant-menu-submenu-selected,
.syngenta-ant-menu-vertical-left .syngenta-ant-menu-submenu-selected,
.syngenta-ant-menu-vertical-right .syngenta-ant-menu-submenu-selected {
  color: #fff;
}
.syngenta-ant-menu-horizontal {
  line-height: 46px;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: none;
}
.syngenta-ant-menu-horizontal:not(.syngenta-ant-menu-dark) > .syngenta-ant-menu-item,
.syngenta-ant-menu-horizontal:not(.syngenta-ant-menu-dark) > .syngenta-ant-menu-submenu {
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 20px;
}
.syngenta-ant-menu-horizontal:not(.syngenta-ant-menu-dark) > .syngenta-ant-menu-item:hover,
.syngenta-ant-menu-horizontal:not(.syngenta-ant-menu-dark) > .syngenta-ant-menu-submenu:hover,
.syngenta-ant-menu-horizontal:not(.syngenta-ant-menu-dark) > .syngenta-ant-menu-item-active,
.syngenta-ant-menu-horizontal:not(.syngenta-ant-menu-dark) > .syngenta-ant-menu-submenu-active,
.syngenta-ant-menu-horizontal:not(.syngenta-ant-menu-dark) > .syngenta-ant-menu-item-open,
.syngenta-ant-menu-horizontal:not(.syngenta-ant-menu-dark) > .syngenta-ant-menu-submenu-open,
.syngenta-ant-menu-horizontal:not(.syngenta-ant-menu-dark) > .syngenta-ant-menu-item-selected,
.syngenta-ant-menu-horizontal:not(.syngenta-ant-menu-dark) > .syngenta-ant-menu-submenu-selected {
  color: #fff;
}
.syngenta-ant-menu-horizontal:not(.syngenta-ant-menu-dark) > .syngenta-ant-menu-item:hover::after,
.syngenta-ant-menu-horizontal:not(.syngenta-ant-menu-dark) > .syngenta-ant-menu-submenu:hover::after,
.syngenta-ant-menu-horizontal:not(.syngenta-ant-menu-dark) > .syngenta-ant-menu-item-active::after,
.syngenta-ant-menu-horizontal:not(.syngenta-ant-menu-dark) > .syngenta-ant-menu-submenu-active::after,
.syngenta-ant-menu-horizontal:not(.syngenta-ant-menu-dark) > .syngenta-ant-menu-item-open::after,
.syngenta-ant-menu-horizontal:not(.syngenta-ant-menu-dark) > .syngenta-ant-menu-submenu-open::after,
.syngenta-ant-menu-horizontal:not(.syngenta-ant-menu-dark) > .syngenta-ant-menu-item-selected::after,
.syngenta-ant-menu-horizontal:not(.syngenta-ant-menu-dark) > .syngenta-ant-menu-submenu-selected::after {
  border-bottom: 2px solid #fff;
}
.syngenta-ant-menu-horizontal > .syngenta-ant-menu-item,
.syngenta-ant-menu-horizontal > .syngenta-ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
}
.syngenta-ant-menu-horizontal > .syngenta-ant-menu-item::after,
.syngenta-ant-menu-horizontal > .syngenta-ant-menu-submenu::after {
  position: absolute;
  right: 20px;
  bottom: 0;
  left: 20px;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.syngenta-ant-menu-horizontal > .syngenta-ant-menu-submenu > .syngenta-ant-menu-submenu-title {
  padding: 0;
}
.syngenta-ant-menu-horizontal > .syngenta-ant-menu-item a {
  color: rgba(255, 255, 255, 0.65);
}
.syngenta-ant-menu-horizontal > .syngenta-ant-menu-item a:hover {
  color: #fff;
}
.syngenta-ant-menu-horizontal > .syngenta-ant-menu-item a::before {
  bottom: -2px;
}
.syngenta-ant-menu-horizontal > .syngenta-ant-menu-item-selected a {
  color: #fff;
}
.syngenta-ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}
.syngenta-ant-menu-vertical .syngenta-ant-menu-item,
.syngenta-ant-menu-vertical-left .syngenta-ant-menu-item,
.syngenta-ant-menu-vertical-right .syngenta-ant-menu-item,
.syngenta-ant-menu-inline .syngenta-ant-menu-item {
  position: relative;
}
.syngenta-ant-menu-vertical .syngenta-ant-menu-item::after,
.syngenta-ant-menu-vertical-left .syngenta-ant-menu-item::after,
.syngenta-ant-menu-vertical-right .syngenta-ant-menu-item::after,
.syngenta-ant-menu-inline .syngenta-ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #fff;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
.syngenta-ant-menu-vertical .syngenta-ant-menu-item,
.syngenta-ant-menu-vertical-left .syngenta-ant-menu-item,
.syngenta-ant-menu-vertical-right .syngenta-ant-menu-item,
.syngenta-ant-menu-inline .syngenta-ant-menu-item,
.syngenta-ant-menu-vertical .syngenta-ant-menu-submenu-title,
.syngenta-ant-menu-vertical-left .syngenta-ant-menu-submenu-title,
.syngenta-ant-menu-vertical-right .syngenta-ant-menu-submenu-title,
.syngenta-ant-menu-inline .syngenta-ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
}
.syngenta-ant-menu-vertical .syngenta-ant-menu-submenu,
.syngenta-ant-menu-vertical-left .syngenta-ant-menu-submenu,
.syngenta-ant-menu-vertical-right .syngenta-ant-menu-submenu,
.syngenta-ant-menu-inline .syngenta-ant-menu-submenu {
  padding-bottom: 0.02px;
}
.syngenta-ant-menu-vertical .syngenta-ant-menu-item:not(:last-child),
.syngenta-ant-menu-vertical-left .syngenta-ant-menu-item:not(:last-child),
.syngenta-ant-menu-vertical-right .syngenta-ant-menu-item:not(:last-child),
.syngenta-ant-menu-inline .syngenta-ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
.syngenta-ant-menu-vertical > .syngenta-ant-menu-item,
.syngenta-ant-menu-vertical-left > .syngenta-ant-menu-item,
.syngenta-ant-menu-vertical-right > .syngenta-ant-menu-item,
.syngenta-ant-menu-inline > .syngenta-ant-menu-item,
.syngenta-ant-menu-vertical > .syngenta-ant-menu-submenu > .syngenta-ant-menu-submenu-title,
.syngenta-ant-menu-vertical-left > .syngenta-ant-menu-submenu > .syngenta-ant-menu-submenu-title,
.syngenta-ant-menu-vertical-right > .syngenta-ant-menu-submenu > .syngenta-ant-menu-submenu-title,
.syngenta-ant-menu-inline > .syngenta-ant-menu-submenu > .syngenta-ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}
.syngenta-ant-menu-vertical .syngenta-ant-menu-item-group-list .syngenta-ant-menu-submenu-title,
.syngenta-ant-menu-vertical .syngenta-ant-menu-submenu-title {
  padding-right: 34px;
}
.syngenta-ant-menu-inline {
  width: 100%;
}
.syngenta-ant-menu-inline .syngenta-ant-menu-selected::after,
.syngenta-ant-menu-inline .syngenta-ant-menu-item-selected::after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syngenta-ant-menu-inline .syngenta-ant-menu-item,
.syngenta-ant-menu-inline .syngenta-ant-menu-submenu-title {
  width: calc(100% + 1px);
}
.syngenta-ant-menu-inline .syngenta-ant-menu-item-group-list .syngenta-ant-menu-submenu-title,
.syngenta-ant-menu-inline .syngenta-ant-menu-submenu-title {
  padding-right: 34px;
}
.syngenta-ant-menu-inline.syngenta-ant-menu-root .syngenta-ant-menu-item,
.syngenta-ant-menu-inline.syngenta-ant-menu-root .syngenta-ant-menu-submenu-title {
  display: flex;
  align-items: center;
  transition: border-color 0.3s, background 0.3s, padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.syngenta-ant-menu-inline.syngenta-ant-menu-root .syngenta-ant-menu-item > .syngenta-ant-menu-title-content,
.syngenta-ant-menu-inline.syngenta-ant-menu-root .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-title-content {
  flex: auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.syngenta-ant-menu-inline.syngenta-ant-menu-root .syngenta-ant-menu-item > *,
.syngenta-ant-menu-inline.syngenta-ant-menu-root .syngenta-ant-menu-submenu-title > * {
  flex: none;
}
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed {
  width: 80px;
}
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-item,
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-item-group > .syngenta-ant-menu-item-group-list > .syngenta-ant-menu-item,
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-item-group > .syngenta-ant-menu-item-group-list > .syngenta-ant-menu-submenu > .syngenta-ant-menu-submenu-title,
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-submenu > .syngenta-ant-menu-submenu-title {
  left: 0;
  padding: 0 calc(50% - 16px / 2);
  text-overflow: clip;
}
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-item .syngenta-ant-menu-submenu-arrow,
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-item-group > .syngenta-ant-menu-item-group-list > .syngenta-ant-menu-item .syngenta-ant-menu-submenu-arrow,
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-item-group > .syngenta-ant-menu-item-group-list > .syngenta-ant-menu-submenu > .syngenta-ant-menu-submenu-title .syngenta-ant-menu-submenu-arrow,
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-submenu > .syngenta-ant-menu-submenu-title .syngenta-ant-menu-submenu-arrow {
  opacity: 0;
}
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-item .syngenta-ant-menu-item-icon,
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-item-group > .syngenta-ant-menu-item-group-list > .syngenta-ant-menu-item .syngenta-ant-menu-item-icon,
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-item-group > .syngenta-ant-menu-item-group-list > .syngenta-ant-menu-submenu > .syngenta-ant-menu-submenu-title .syngenta-ant-menu-item-icon,
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-submenu > .syngenta-ant-menu-submenu-title .syngenta-ant-menu-item-icon,
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-item .anticon,
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-item-group > .syngenta-ant-menu-item-group-list > .syngenta-ant-menu-item .anticon,
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-item-group > .syngenta-ant-menu-item-group-list > .syngenta-ant-menu-submenu > .syngenta-ant-menu-submenu-title .anticon,
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-submenu > .syngenta-ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
}
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-item .syngenta-ant-menu-item-icon + span,
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-item-group > .syngenta-ant-menu-item-group-list > .syngenta-ant-menu-item .syngenta-ant-menu-item-icon + span,
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-item-group > .syngenta-ant-menu-item-group-list > .syngenta-ant-menu-submenu > .syngenta-ant-menu-submenu-title .syngenta-ant-menu-item-icon + span,
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-submenu > .syngenta-ant-menu-submenu-title .syngenta-ant-menu-item-icon + span,
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-item .anticon + span,
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-item-group > .syngenta-ant-menu-item-group-list > .syngenta-ant-menu-item .anticon + span,
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-item-group > .syngenta-ant-menu-item-group-list > .syngenta-ant-menu-submenu > .syngenta-ant-menu-submenu-title .anticon + span,
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed > .syngenta-ant-menu-submenu > .syngenta-ant-menu-submenu-title .anticon + span {
  display: inline-block;
  opacity: 0;
}
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed .syngenta-ant-menu-item-icon,
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed .anticon {
  display: inline-block;
}
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed-tooltip .syngenta-ant-menu-item-icon,
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}
.syngenta-ant-menu.syngenta-ant-menu-inline-collapsed .syngenta-ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syngenta-ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.syngenta-ant-menu-item-group-list .syngenta-ant-menu-item,
.syngenta-ant-menu-item-group-list .syngenta-ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.syngenta-ant-menu-root.syngenta-ant-menu-vertical,
.syngenta-ant-menu-root.syngenta-ant-menu-vertical-left,
.syngenta-ant-menu-root.syngenta-ant-menu-vertical-right,
.syngenta-ant-menu-root.syngenta-ant-menu-inline {
  box-shadow: none;
}
.syngenta-ant-menu-root.syngenta-ant-menu-inline-collapsed .syngenta-ant-menu-item > .syngenta-ant-menu-inline-collapsed-noicon,
.syngenta-ant-menu-root.syngenta-ant-menu-inline-collapsed .syngenta-ant-menu-submenu .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-inline-collapsed-noicon {
  font-size: 16px;
  text-align: center;
}
.syngenta-ant-menu-sub.syngenta-ant-menu-inline {
  padding: 0;
  background: #fafafa;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.syngenta-ant-menu-sub.syngenta-ant-menu-inline > .syngenta-ant-menu-item,
.syngenta-ant-menu-sub.syngenta-ant-menu-inline > .syngenta-ant-menu-submenu > .syngenta-ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
}
.syngenta-ant-menu-sub.syngenta-ant-menu-inline .syngenta-ant-menu-item-group-title {
  padding-left: 32px;
}
.syngenta-ant-menu-item-disabled,
.syngenta-ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: none;
  cursor: not-allowed;
}
.syngenta-ant-menu-item-disabled::after,
.syngenta-ant-menu-submenu-disabled::after {
  border-color: transparent !important;
}
.syngenta-ant-menu-item-disabled a,
.syngenta-ant-menu-submenu-disabled a {
  color: rgba(0, 0, 0, 0.25) !important;
  pointer-events: none;
}
.syngenta-ant-menu-item-disabled > .syngenta-ant-menu-submenu-title,
.syngenta-ant-menu-submenu-disabled > .syngenta-ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
.syngenta-ant-menu-item-disabled > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow::before,
.syngenta-ant-menu-submenu-disabled > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow::before,
.syngenta-ant-menu-item-disabled > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow::after,
.syngenta-ant-menu-submenu-disabled > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow::after {
  background: rgba(0, 0, 0, 0.25) !important;
}
.syngenta-ant-layout-header .syngenta-ant-menu {
  line-height: inherit;
}
.syngenta-ant-menu-inline-collapsed-tooltip a,
.syngenta-ant-menu-inline-collapsed-tooltip a:hover {
  color: #fff;
}
.syngenta-ant-menu-light .syngenta-ant-menu-item:hover,
.syngenta-ant-menu-light .syngenta-ant-menu-item-active,
.syngenta-ant-menu-light .syngenta-ant-menu:not(.syngenta-ant-menu-inline) .syngenta-ant-menu-submenu-open,
.syngenta-ant-menu-light .syngenta-ant-menu-submenu-active,
.syngenta-ant-menu-light .syngenta-ant-menu-submenu-title:hover {
  color: #fff;
}
.syngenta-ant-menu.syngenta-ant-menu-root:focus-visible {
  box-shadow: 0 0 0 2px #000000;
}
.syngenta-ant-menu-dark .syngenta-ant-menu-item:focus-visible,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-title:focus-visible {
  box-shadow: 0 0 0 2px #000000;
}
.syngenta-ant-menu.syngenta-ant-menu-dark,
.syngenta-ant-menu-dark .syngenta-ant-menu-sub,
.syngenta-ant-menu.syngenta-ant-menu-dark .syngenta-ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #2f3031;
}
.syngenta-ant-menu.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-title .syngenta-ant-menu-submenu-arrow,
.syngenta-ant-menu-dark .syngenta-ant-menu-sub .syngenta-ant-menu-submenu-title .syngenta-ant-menu-submenu-arrow,
.syngenta-ant-menu.syngenta-ant-menu-dark .syngenta-ant-menu-sub .syngenta-ant-menu-submenu-title .syngenta-ant-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}
.syngenta-ant-menu.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-title .syngenta-ant-menu-submenu-arrow::after,
.syngenta-ant-menu-dark .syngenta-ant-menu-sub .syngenta-ant-menu-submenu-title .syngenta-ant-menu-submenu-arrow::after,
.syngenta-ant-menu.syngenta-ant-menu-dark .syngenta-ant-menu-sub .syngenta-ant-menu-submenu-title .syngenta-ant-menu-submenu-arrow::after,
.syngenta-ant-menu.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-title .syngenta-ant-menu-submenu-arrow::before,
.syngenta-ant-menu-dark .syngenta-ant-menu-sub .syngenta-ant-menu-submenu-title .syngenta-ant-menu-submenu-arrow::before,
.syngenta-ant-menu.syngenta-ant-menu-dark .syngenta-ant-menu-sub .syngenta-ant-menu-submenu-title .syngenta-ant-menu-submenu-arrow::before {
  background: #fff;
}
.syngenta-ant-menu-dark.syngenta-ant-menu-submenu-popup {
  background: transparent;
}
.syngenta-ant-menu-dark .syngenta-ant-menu-inline.syngenta-ant-menu-sub {
  background: #000c17;
}
.syngenta-ant-menu-dark.syngenta-ant-menu-horizontal {
  border-bottom: 0;
}
.syngenta-ant-menu-dark.syngenta-ant-menu-horizontal > .syngenta-ant-menu-item,
.syngenta-ant-menu-dark.syngenta-ant-menu-horizontal > .syngenta-ant-menu-submenu {
  top: 0;
  margin-top: 0;
  padding: 0 20px;
  border-color: #2f3031;
  border-bottom: 0;
}
.syngenta-ant-menu-dark.syngenta-ant-menu-horizontal > .syngenta-ant-menu-item:hover {
  background-color: #171718;
}
.syngenta-ant-menu-dark.syngenta-ant-menu-horizontal > .syngenta-ant-menu-item > a::before {
  bottom: 0;
}
.syngenta-ant-menu-dark .syngenta-ant-menu-item,
.syngenta-ant-menu-dark .syngenta-ant-menu-item-group-title,
.syngenta-ant-menu-dark .syngenta-ant-menu-item > a,
.syngenta-ant-menu-dark .syngenta-ant-menu-item > span > a {
  color: rgba(255, 255, 255, 0.65);
}
.syngenta-ant-menu-dark.syngenta-ant-menu-inline,
.syngenta-ant-menu-dark.syngenta-ant-menu-vertical,
.syngenta-ant-menu-dark.syngenta-ant-menu-vertical-left,
.syngenta-ant-menu-dark.syngenta-ant-menu-vertical-right {
  border-right: 0;
}
.syngenta-ant-menu-dark.syngenta-ant-menu-inline .syngenta-ant-menu-item,
.syngenta-ant-menu-dark.syngenta-ant-menu-vertical .syngenta-ant-menu-item,
.syngenta-ant-menu-dark.syngenta-ant-menu-vertical-left .syngenta-ant-menu-item,
.syngenta-ant-menu-dark.syngenta-ant-menu-vertical-right .syngenta-ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.syngenta-ant-menu-dark.syngenta-ant-menu-inline .syngenta-ant-menu-item::after,
.syngenta-ant-menu-dark.syngenta-ant-menu-vertical .syngenta-ant-menu-item::after,
.syngenta-ant-menu-dark.syngenta-ant-menu-vertical-left .syngenta-ant-menu-item::after,
.syngenta-ant-menu-dark.syngenta-ant-menu-vertical-right .syngenta-ant-menu-item::after {
  border-right: 0;
}
.syngenta-ant-menu-dark.syngenta-ant-menu-inline .syngenta-ant-menu-item,
.syngenta-ant-menu-dark.syngenta-ant-menu-inline .syngenta-ant-menu-submenu-title {
  width: 100%;
}
.syngenta-ant-menu-dark .syngenta-ant-menu-item:hover,
.syngenta-ant-menu-dark .syngenta-ant-menu-item-active,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-active,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-open,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-selected,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-title:hover {
  color: #fff;
  background-color: transparent;
}
.syngenta-ant-menu-dark .syngenta-ant-menu-item:hover > a,
.syngenta-ant-menu-dark .syngenta-ant-menu-item-active > a,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-active > a,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-open > a,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-selected > a,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-title:hover > a,
.syngenta-ant-menu-dark .syngenta-ant-menu-item:hover > span > a,
.syngenta-ant-menu-dark .syngenta-ant-menu-item-active > span > a,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-active > span > a,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-open > span > a,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-selected > span > a,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-title:hover > span > a {
  color: #fff;
}
.syngenta-ant-menu-dark .syngenta-ant-menu-item:hover > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow,
.syngenta-ant-menu-dark .syngenta-ant-menu-item-active > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-active > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-open > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-selected > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-title:hover > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow {
  opacity: 1;
}
.syngenta-ant-menu-dark .syngenta-ant-menu-item:hover > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow::after,
.syngenta-ant-menu-dark .syngenta-ant-menu-item-active > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow::after,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-active > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow::after,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-open > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow::after,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-selected > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow::after,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-title:hover > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow::after,
.syngenta-ant-menu-dark .syngenta-ant-menu-item:hover > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow::before,
.syngenta-ant-menu-dark .syngenta-ant-menu-item-active > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow::before,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-active > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow::before,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-open > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow::before,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-selected > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow::before,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-title:hover > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow::before {
  background: #fff;
}
.syngenta-ant-menu-dark .syngenta-ant-menu-item:hover {
  background-color: transparent;
}
.syngenta-ant-menu-dark.syngenta-ant-menu-dark:not(.syngenta-ant-menu-horizontal) .syngenta-ant-menu-item-selected {
  background-color: #171718;
}
.syngenta-ant-menu-dark .syngenta-ant-menu-item-selected {
  color: #fff;
  border-right: 0;
}
.syngenta-ant-menu-dark .syngenta-ant-menu-item-selected::after {
  border-right: 0;
}
.syngenta-ant-menu-dark .syngenta-ant-menu-item-selected > a,
.syngenta-ant-menu-dark .syngenta-ant-menu-item-selected > span > a,
.syngenta-ant-menu-dark .syngenta-ant-menu-item-selected > a:hover,
.syngenta-ant-menu-dark .syngenta-ant-menu-item-selected > span > a:hover {
  color: #fff;
}
.syngenta-ant-menu-dark .syngenta-ant-menu-item-selected .syngenta-ant-menu-item-icon,
.syngenta-ant-menu-dark .syngenta-ant-menu-item-selected .anticon {
  color: #fff;
}
.syngenta-ant-menu-dark .syngenta-ant-menu-item-selected .syngenta-ant-menu-item-icon + span,
.syngenta-ant-menu-dark .syngenta-ant-menu-item-selected .anticon + span {
  color: #fff;
}
.syngenta-ant-menu.syngenta-ant-menu-dark .syngenta-ant-menu-item-selected,
.syngenta-ant-menu-submenu-popup.syngenta-ant-menu-dark .syngenta-ant-menu-item-selected {
  background-color: #171718;
}
.syngenta-ant-menu-dark .syngenta-ant-menu-item-disabled,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-disabled,
.syngenta-ant-menu-dark .syngenta-ant-menu-item-disabled > a,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-disabled > a,
.syngenta-ant-menu-dark .syngenta-ant-menu-item-disabled > span > a,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-disabled > span > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}
.syngenta-ant-menu-dark .syngenta-ant-menu-item-disabled > .syngenta-ant-menu-submenu-title,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-disabled > .syngenta-ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.syngenta-ant-menu-dark .syngenta-ant-menu-item-disabled > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow::before,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-disabled > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow::before,
.syngenta-ant-menu-dark .syngenta-ant-menu-item-disabled > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow::after,
.syngenta-ant-menu-dark .syngenta-ant-menu-submenu-disabled > .syngenta-ant-menu-submenu-title > .syngenta-ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important;
}
.syngenta-ant-menu.syngenta-ant-menu-rtl {
  direction: rtl;
  text-align: right;
}
.syngenta-ant-menu-rtl .syngenta-ant-menu-item-group-title {
  text-align: right;
}
.syngenta-ant-menu-rtl.syngenta-ant-menu-inline,
.syngenta-ant-menu-rtl.syngenta-ant-menu-vertical {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.syngenta-ant-menu-rtl.syngenta-ant-menu-dark.syngenta-ant-menu-inline,
.syngenta-ant-menu-rtl.syngenta-ant-menu-dark.syngenta-ant-menu-vertical {
  border-left: none;
}
.syngenta-ant-menu-rtl.syngenta-ant-menu-vertical.syngenta-ant-menu-sub > .syngenta-ant-menu-item,
.syngenta-ant-menu-rtl.syngenta-ant-menu-vertical-left.syngenta-ant-menu-sub > .syngenta-ant-menu-item,
.syngenta-ant-menu-rtl.syngenta-ant-menu-vertical-right.syngenta-ant-menu-sub > .syngenta-ant-menu-item,
.syngenta-ant-menu-rtl.syngenta-ant-menu-vertical.syngenta-ant-menu-sub > .syngenta-ant-menu-submenu,
.syngenta-ant-menu-rtl.syngenta-ant-menu-vertical-left.syngenta-ant-menu-sub > .syngenta-ant-menu-submenu,
.syngenta-ant-menu-rtl.syngenta-ant-menu-vertical-right.syngenta-ant-menu-sub > .syngenta-ant-menu-submenu {
  transform-origin: top right;
}
.syngenta-ant-menu-rtl .syngenta-ant-menu-item .syngenta-ant-menu-item-icon,
.syngenta-ant-menu-rtl .syngenta-ant-menu-submenu-title .syngenta-ant-menu-item-icon,
.syngenta-ant-menu-rtl .syngenta-ant-menu-item .anticon,
.syngenta-ant-menu-rtl .syngenta-ant-menu-submenu-title .anticon {
  margin-right: auto;
  margin-left: 10px;
}
.syngenta-ant-menu-rtl .syngenta-ant-menu-item.syngenta-ant-menu-item-only-child > .syngenta-ant-menu-item-icon,
.syngenta-ant-menu-rtl .syngenta-ant-menu-submenu-title.syngenta-ant-menu-item-only-child > .syngenta-ant-menu-item-icon,
.syngenta-ant-menu-rtl .syngenta-ant-menu-item.syngenta-ant-menu-item-only-child > .anticon,
.syngenta-ant-menu-rtl .syngenta-ant-menu-submenu-title.syngenta-ant-menu-item-only-child > .anticon {
  margin-left: 0;
}
.syngenta-ant-menu-submenu-rtl.syngenta-ant-menu-submenu-popup {
  transform-origin: 100% 0;
}
.syngenta-ant-menu-rtl .syngenta-ant-menu-submenu-vertical > .syngenta-ant-menu-submenu-title .syngenta-ant-menu-submenu-arrow,
.syngenta-ant-menu-rtl .syngenta-ant-menu-submenu-vertical-left > .syngenta-ant-menu-submenu-title .syngenta-ant-menu-submenu-arrow,
.syngenta-ant-menu-rtl .syngenta-ant-menu-submenu-vertical-right > .syngenta-ant-menu-submenu-title .syngenta-ant-menu-submenu-arrow,
.syngenta-ant-menu-rtl .syngenta-ant-menu-submenu-inline > .syngenta-ant-menu-submenu-title .syngenta-ant-menu-submenu-arrow {
  right: auto;
  left: 16px;
}
.syngenta-ant-menu-rtl .syngenta-ant-menu-submenu-vertical > .syngenta-ant-menu-submenu-title .syngenta-ant-menu-submenu-arrow::before,
.syngenta-ant-menu-rtl .syngenta-ant-menu-submenu-vertical-left > .syngenta-ant-menu-submenu-title .syngenta-ant-menu-submenu-arrow::before,
.syngenta-ant-menu-rtl .syngenta-ant-menu-submenu-vertical-right > .syngenta-ant-menu-submenu-title .syngenta-ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateY(-2px);
}
.syngenta-ant-menu-rtl .syngenta-ant-menu-submenu-vertical > .syngenta-ant-menu-submenu-title .syngenta-ant-menu-submenu-arrow::after,
.syngenta-ant-menu-rtl .syngenta-ant-menu-submenu-vertical-left > .syngenta-ant-menu-submenu-title .syngenta-ant-menu-submenu-arrow::after,
.syngenta-ant-menu-rtl .syngenta-ant-menu-submenu-vertical-right > .syngenta-ant-menu-submenu-title .syngenta-ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateY(2px);
}
.syngenta-ant-menu-rtl.syngenta-ant-menu-vertical .syngenta-ant-menu-item::after,
.syngenta-ant-menu-rtl.syngenta-ant-menu-vertical-left .syngenta-ant-menu-item::after,
.syngenta-ant-menu-rtl.syngenta-ant-menu-vertical-right .syngenta-ant-menu-item::after,
.syngenta-ant-menu-rtl.syngenta-ant-menu-inline .syngenta-ant-menu-item::after {
  right: auto;
  left: 0;
}
.syngenta-ant-menu-rtl.syngenta-ant-menu-vertical .syngenta-ant-menu-item,
.syngenta-ant-menu-rtl.syngenta-ant-menu-vertical-left .syngenta-ant-menu-item,
.syngenta-ant-menu-rtl.syngenta-ant-menu-vertical-right .syngenta-ant-menu-item,
.syngenta-ant-menu-rtl.syngenta-ant-menu-inline .syngenta-ant-menu-item,
.syngenta-ant-menu-rtl.syngenta-ant-menu-vertical .syngenta-ant-menu-submenu-title,
.syngenta-ant-menu-rtl.syngenta-ant-menu-vertical-left .syngenta-ant-menu-submenu-title,
.syngenta-ant-menu-rtl.syngenta-ant-menu-vertical-right .syngenta-ant-menu-submenu-title,
.syngenta-ant-menu-rtl.syngenta-ant-menu-inline .syngenta-ant-menu-submenu-title {
  text-align: right;
}
.syngenta-ant-menu-rtl.syngenta-ant-menu-inline .syngenta-ant-menu-submenu-title {
  padding-right: 0;
  padding-left: 34px;
}
.syngenta-ant-menu-rtl.syngenta-ant-menu-vertical .syngenta-ant-menu-submenu-title {
  padding-right: 16px;
  padding-left: 34px;
}
.syngenta-ant-menu-rtl.syngenta-ant-menu-inline-collapsed.syngenta-ant-menu-vertical .syngenta-ant-menu-submenu-title {
  padding: 0 calc(50% - 16px / 2);
}
.syngenta-ant-menu-rtl .syngenta-ant-menu-item-group-list .syngenta-ant-menu-item,
.syngenta-ant-menu-rtl .syngenta-ant-menu-item-group-list .syngenta-ant-menu-submenu-title {
  padding: 0 28px 0 16px;
}
.syngenta-ant-menu-sub.syngenta-ant-menu-inline {
  border: 0;
}
.syngenta-ant-menu-rtl.syngenta-ant-menu-sub.syngenta-ant-menu-inline .syngenta-ant-menu-item-group-title {
  padding-right: 32px;
  padding-left: 0;
}
.syngenta-ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: #2F3031;
  border-color: #F0F3F7;
  background: #F2F4F6;
}
.syngenta-ant-btn > .anticon {
  line-height: 1;
}
.syngenta-ant-btn,
.syngenta-ant-btn:active,
.syngenta-ant-btn:focus {
  outline: 0;
}
.syngenta-ant-btn:not([disabled]):hover {
  text-decoration: none;
}
.syngenta-ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.syngenta-ant-btn[disabled] {
  cursor: not-allowed;
}
.syngenta-ant-btn[disabled] > * {
  pointer-events: none;
}
.syngenta-ant-btn-lg {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 2px;
}
.syngenta-ant-btn-sm {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 2px;
}
.syngenta-ant-btn > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn:hover,
.syngenta-ant-btn:focus {
  color: #0d0d0d;
  border-color: #0d0d0d;
  background: #F2F4F6;
}
.syngenta-ant-btn:hover > a:only-child,
.syngenta-ant-btn:focus > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn:hover > a:only-child::after,
.syngenta-ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn:active {
  color: #000000;
  border-color: #000000;
  background: #F2F4F6;
}
.syngenta-ant-btn:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn[disabled],
.syngenta-ant-btn[disabled]:hover,
.syngenta-ant-btn[disabled]:focus,
.syngenta-ant-btn[disabled]:active {
  color: #E8EAED;
  border-color: #e8eaed;
  background: #C1C5C8;
  text-shadow: none;
  box-shadow: none;
}
.syngenta-ant-btn[disabled] > a:only-child,
.syngenta-ant-btn[disabled]:hover > a:only-child,
.syngenta-ant-btn[disabled]:focus > a:only-child,
.syngenta-ant-btn[disabled]:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn[disabled] > a:only-child::after,
.syngenta-ant-btn[disabled]:hover > a:only-child::after,
.syngenta-ant-btn[disabled]:focus > a:only-child::after,
.syngenta-ant-btn[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn:hover,
.syngenta-ant-btn:focus,
.syngenta-ant-btn:active {
  text-decoration: none;
  background: #F2F4F6;
}
.syngenta-ant-btn > span {
  display: inline-block;
}
.syngenta-ant-btn-primary {
  color: #fff;
  border-color: #000;
  background: #000;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.syngenta-ant-btn-primary > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-primary:hover,
.syngenta-ant-btn-primary:focus {
  color: #fff;
  border-color: #0d0d0d;
  background: #0d0d0d;
}
.syngenta-ant-btn-primary:hover > a:only-child,
.syngenta-ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-primary:hover > a:only-child::after,
.syngenta-ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-primary:active {
  color: #fff;
  border-color: #000000;
  background: #000000;
}
.syngenta-ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-primary[disabled],
.syngenta-ant-btn-primary[disabled]:hover,
.syngenta-ant-btn-primary[disabled]:focus,
.syngenta-ant-btn-primary[disabled]:active {
  color: #E8EAED;
  border-color: #e8eaed;
  background: #C1C5C8;
  text-shadow: none;
  box-shadow: none;
}
.syngenta-ant-btn-primary[disabled] > a:only-child,
.syngenta-ant-btn-primary[disabled]:hover > a:only-child,
.syngenta-ant-btn-primary[disabled]:focus > a:only-child,
.syngenta-ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-primary[disabled] > a:only-child::after,
.syngenta-ant-btn-primary[disabled]:hover > a:only-child::after,
.syngenta-ant-btn-primary[disabled]:focus > a:only-child::after,
.syngenta-ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-group .syngenta-ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #0d0d0d;
  border-left-color: #0d0d0d;
}
.syngenta-ant-btn-group .syngenta-ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #F0F3F7;
}
.syngenta-ant-btn-group .syngenta-ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #0d0d0d;
}
.syngenta-ant-btn-group .syngenta-ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #F0F3F7;
}
.syngenta-ant-btn-group .syngenta-ant-btn-primary:last-child:not(:first-child),
.syngenta-ant-btn-group .syngenta-ant-btn-primary + .syngenta-ant-btn-primary {
  border-left-color: #0d0d0d;
}
.syngenta-ant-btn-group .syngenta-ant-btn-primary:last-child:not(:first-child)[disabled],
.syngenta-ant-btn-group .syngenta-ant-btn-primary + .syngenta-ant-btn-primary[disabled] {
  border-left-color: #F0F3F7;
}
.syngenta-ant-btn-ghost {
  color: #2f3031;
  border-color: #e8eaed;
  background: transparent;
}
.syngenta-ant-btn-ghost > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-ghost:hover,
.syngenta-ant-btn-ghost:focus {
  color: #0d0d0d;
  border-color: #0d0d0d;
  background: transparent;
}
.syngenta-ant-btn-ghost:hover > a:only-child,
.syngenta-ant-btn-ghost:focus > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-ghost:hover > a:only-child::after,
.syngenta-ant-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-ghost:active {
  color: #000000;
  border-color: #000000;
  background: transparent;
}
.syngenta-ant-btn-ghost:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-ghost:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-ghost[disabled],
.syngenta-ant-btn-ghost[disabled]:hover,
.syngenta-ant-btn-ghost[disabled]:focus,
.syngenta-ant-btn-ghost[disabled]:active {
  color: #E8EAED;
  border-color: #e8eaed;
  background: #C1C5C8;
  text-shadow: none;
  box-shadow: none;
}
.syngenta-ant-btn-ghost[disabled] > a:only-child,
.syngenta-ant-btn-ghost[disabled]:hover > a:only-child,
.syngenta-ant-btn-ghost[disabled]:focus > a:only-child,
.syngenta-ant-btn-ghost[disabled]:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-ghost[disabled] > a:only-child::after,
.syngenta-ant-btn-ghost[disabled]:hover > a:only-child::after,
.syngenta-ant-btn-ghost[disabled]:focus > a:only-child::after,
.syngenta-ant-btn-ghost[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dashed {
  color: #2F3031;
  border-color: #F0F3F7;
  background: #F2F4F6;
  border-style: dashed;
}
.syngenta-ant-btn-dashed > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dashed:hover,
.syngenta-ant-btn-dashed:focus {
  color: #0d0d0d;
  border-color: #0d0d0d;
  background: #F2F4F6;
}
.syngenta-ant-btn-dashed:hover > a:only-child,
.syngenta-ant-btn-dashed:focus > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dashed:hover > a:only-child::after,
.syngenta-ant-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dashed:active {
  color: #000000;
  border-color: #000000;
  background: #F2F4F6;
}
.syngenta-ant-btn-dashed:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dashed:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dashed[disabled],
.syngenta-ant-btn-dashed[disabled]:hover,
.syngenta-ant-btn-dashed[disabled]:focus,
.syngenta-ant-btn-dashed[disabled]:active {
  color: #E8EAED;
  border-color: #e8eaed;
  background: #C1C5C8;
  text-shadow: none;
  box-shadow: none;
}
.syngenta-ant-btn-dashed[disabled] > a:only-child,
.syngenta-ant-btn-dashed[disabled]:hover > a:only-child,
.syngenta-ant-btn-dashed[disabled]:focus > a:only-child,
.syngenta-ant-btn-dashed[disabled]:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dashed[disabled] > a:only-child::after,
.syngenta-ant-btn-dashed[disabled]:hover > a:only-child::after,
.syngenta-ant-btn-dashed[disabled]:focus > a:only-child::after,
.syngenta-ant-btn-dashed[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-danger {
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.syngenta-ant-btn-danger > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-danger:hover,
.syngenta-ant-btn-danger:focus {
  color: #fff;
  border-color: #ff7875;
  background: #ff7875;
}
.syngenta-ant-btn-danger:hover > a:only-child,
.syngenta-ant-btn-danger:focus > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-danger:hover > a:only-child::after,
.syngenta-ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-danger:active {
  color: #fff;
  border-color: #d9363e;
  background: #d9363e;
}
.syngenta-ant-btn-danger:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-danger[disabled],
.syngenta-ant-btn-danger[disabled]:hover,
.syngenta-ant-btn-danger[disabled]:focus,
.syngenta-ant-btn-danger[disabled]:active {
  color: #E8EAED;
  border-color: #e8eaed;
  background: #C1C5C8;
  text-shadow: none;
  box-shadow: none;
}
.syngenta-ant-btn-danger[disabled] > a:only-child,
.syngenta-ant-btn-danger[disabled]:hover > a:only-child,
.syngenta-ant-btn-danger[disabled]:focus > a:only-child,
.syngenta-ant-btn-danger[disabled]:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-danger[disabled] > a:only-child::after,
.syngenta-ant-btn-danger[disabled]:hover > a:only-child::after,
.syngenta-ant-btn-danger[disabled]:focus > a:only-child::after,
.syngenta-ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-link {
  color: #000;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.syngenta-ant-btn-link > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-link:hover,
.syngenta-ant-btn-link:focus {
  color: #0d0d0d;
  border-color: #0d0d0d;
  background: transparent;
}
.syngenta-ant-btn-link:hover > a:only-child,
.syngenta-ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-link:hover > a:only-child::after,
.syngenta-ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-link:active {
  color: #000000;
  border-color: #000000;
  background: transparent;
}
.syngenta-ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-link[disabled],
.syngenta-ant-btn-link[disabled]:hover,
.syngenta-ant-btn-link[disabled]:focus,
.syngenta-ant-btn-link[disabled]:active {
  color: #E8EAED;
  border-color: #e8eaed;
  background: #C1C5C8;
  text-shadow: none;
  box-shadow: none;
}
.syngenta-ant-btn-link[disabled] > a:only-child,
.syngenta-ant-btn-link[disabled]:hover > a:only-child,
.syngenta-ant-btn-link[disabled]:focus > a:only-child,
.syngenta-ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-link[disabled] > a:only-child::after,
.syngenta-ant-btn-link[disabled]:hover > a:only-child::after,
.syngenta-ant-btn-link[disabled]:focus > a:only-child::after,
.syngenta-ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-link:hover {
  background: transparent;
}
.syngenta-ant-btn-link:hover,
.syngenta-ant-btn-link:focus,
.syngenta-ant-btn-link:active {
  border-color: transparent;
}
.syngenta-ant-btn-link[disabled],
.syngenta-ant-btn-link[disabled]:hover,
.syngenta-ant-btn-link[disabled]:focus,
.syngenta-ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.syngenta-ant-btn-link[disabled] > a:only-child,
.syngenta-ant-btn-link[disabled]:hover > a:only-child,
.syngenta-ant-btn-link[disabled]:focus > a:only-child,
.syngenta-ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-link[disabled] > a:only-child::after,
.syngenta-ant-btn-link[disabled]:hover > a:only-child::after,
.syngenta-ant-btn-link[disabled]:focus > a:only-child::after,
.syngenta-ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-text {
  color: #2f3031;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.syngenta-ant-btn-text > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-text:hover,
.syngenta-ant-btn-text:focus {
  color: #0d0d0d;
  border-color: #0d0d0d;
  background: transparent;
}
.syngenta-ant-btn-text:hover > a:only-child,
.syngenta-ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-text:hover > a:only-child::after,
.syngenta-ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-text:active {
  color: #000000;
  border-color: #000000;
  background: transparent;
}
.syngenta-ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-text[disabled],
.syngenta-ant-btn-text[disabled]:hover,
.syngenta-ant-btn-text[disabled]:focus,
.syngenta-ant-btn-text[disabled]:active {
  color: #E8EAED;
  border-color: #e8eaed;
  background: #C1C5C8;
  text-shadow: none;
  box-shadow: none;
}
.syngenta-ant-btn-text[disabled] > a:only-child,
.syngenta-ant-btn-text[disabled]:hover > a:only-child,
.syngenta-ant-btn-text[disabled]:focus > a:only-child,
.syngenta-ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-text[disabled] > a:only-child::after,
.syngenta-ant-btn-text[disabled]:hover > a:only-child::after,
.syngenta-ant-btn-text[disabled]:focus > a:only-child::after,
.syngenta-ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-text:hover,
.syngenta-ant-btn-text:focus {
  color: #2f3031;
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}
.syngenta-ant-btn-text:active {
  color: #2f3031;
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}
.syngenta-ant-btn-text[disabled],
.syngenta-ant-btn-text[disabled]:hover,
.syngenta-ant-btn-text[disabled]:focus,
.syngenta-ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.syngenta-ant-btn-text[disabled] > a:only-child,
.syngenta-ant-btn-text[disabled]:hover > a:only-child,
.syngenta-ant-btn-text[disabled]:focus > a:only-child,
.syngenta-ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-text[disabled] > a:only-child::after,
.syngenta-ant-btn-text[disabled]:hover > a:only-child::after,
.syngenta-ant-btn-text[disabled]:focus > a:only-child::after,
.syngenta-ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dangerous {
  color: #ff4d4f;
  border-color: #ff4d4f;
  background: #F2F4F6;
}
.syngenta-ant-btn-dangerous > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dangerous:hover,
.syngenta-ant-btn-dangerous:focus {
  color: #ff7875;
  border-color: #ff7875;
  background: #F2F4F6;
}
.syngenta-ant-btn-dangerous:hover > a:only-child,
.syngenta-ant-btn-dangerous:focus > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dangerous:hover > a:only-child::after,
.syngenta-ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dangerous:active {
  color: #d9363e;
  border-color: #d9363e;
  background: #F2F4F6;
}
.syngenta-ant-btn-dangerous:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dangerous[disabled],
.syngenta-ant-btn-dangerous[disabled]:hover,
.syngenta-ant-btn-dangerous[disabled]:focus,
.syngenta-ant-btn-dangerous[disabled]:active {
  color: #E8EAED;
  border-color: #e8eaed;
  background: #C1C5C8;
  text-shadow: none;
  box-shadow: none;
}
.syngenta-ant-btn-dangerous[disabled] > a:only-child,
.syngenta-ant-btn-dangerous[disabled]:hover > a:only-child,
.syngenta-ant-btn-dangerous[disabled]:focus > a:only-child,
.syngenta-ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dangerous[disabled] > a:only-child::after,
.syngenta-ant-btn-dangerous[disabled]:hover > a:only-child::after,
.syngenta-ant-btn-dangerous[disabled]:focus > a:only-child::after,
.syngenta-ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary {
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary:hover,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary:focus {
  color: #fff;
  border-color: #ff7875;
  background: #ff7875;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary:hover > a:only-child,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary:hover > a:only-child::after,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary:active {
  color: #fff;
  border-color: #d9363e;
  background: #d9363e;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary[disabled],
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary[disabled]:hover,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary[disabled]:focus,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary[disabled]:active {
  color: #E8EAED;
  border-color: #e8eaed;
  background: #C1C5C8;
  text-shadow: none;
  box-shadow: none;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary[disabled] > a:only-child,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary[disabled]:hover > a:only-child,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary[disabled]:focus > a:only-child,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary[disabled] > a:only-child::after,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary[disabled]:hover > a:only-child::after,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary[disabled]:focus > a:only-child::after,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link {
  color: #ff4d4f;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:hover,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:focus {
  color: #0d0d0d;
  border-color: #0d0d0d;
  background: transparent;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:hover > a:only-child,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:hover > a:only-child::after,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:active {
  color: #000000;
  border-color: #000000;
  background: transparent;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled],
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:hover,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:focus,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:active {
  color: #E8EAED;
  border-color: #e8eaed;
  background: #C1C5C8;
  text-shadow: none;
  box-shadow: none;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled] > a:only-child,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:hover > a:only-child,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:focus > a:only-child,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled] > a:only-child::after,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:hover > a:only-child::after,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:focus > a:only-child::after,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:hover,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:focus {
  color: #ff7875;
  border-color: transparent;
  background: transparent;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:hover > a:only-child,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:hover > a:only-child::after,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:active {
  color: #d9363e;
  border-color: transparent;
  background: transparent;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled],
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:hover,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:focus,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled] > a:only-child,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:hover > a:only-child,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:focus > a:only-child,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled] > a:only-child::after,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:hover > a:only-child::after,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:focus > a:only-child::after,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text {
  color: #ff4d4f;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text:hover,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text:focus {
  color: #0d0d0d;
  border-color: #0d0d0d;
  background: transparent;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text:hover > a:only-child,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text:hover > a:only-child::after,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text:active {
  color: #000000;
  border-color: #000000;
  background: transparent;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled],
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled]:hover,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled]:focus,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled]:active {
  color: #E8EAED;
  border-color: #e8eaed;
  background: #C1C5C8;
  text-shadow: none;
  box-shadow: none;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled] > a:only-child,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled]:hover > a:only-child,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled]:focus > a:only-child,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled] > a:only-child::after,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled]:hover > a:only-child::after,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled]:focus > a:only-child::after,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text:hover,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text:focus {
  color: #ff7875;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.018);
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text:hover > a:only-child,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text:hover > a:only-child::after,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text:active {
  color: #d9363e;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.028);
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled],
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled]:hover,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled]:focus,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled] > a:only-child,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled]:hover > a:only-child,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled]:focus > a:only-child,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled] > a:only-child::after,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled]:hover > a:only-child::after,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled]:focus > a:only-child::after,
.syngenta-ant-btn-dangerous.syngenta-ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-icon-only {
  width: 32px;
  height: 32px;
  padding: 2.4px 0;
  font-size: 16px;
  border-radius: 2px;
  vertical-align: -3px;
}
.syngenta-ant-btn-icon-only > * {
  font-size: 16px;
}
.syngenta-ant-btn-icon-only.syngenta-ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 4.9px 0;
  font-size: 18px;
  border-radius: 2px;
}
.syngenta-ant-btn-icon-only.syngenta-ant-btn-lg > * {
  font-size: 18px;
}
.syngenta-ant-btn-icon-only.syngenta-ant-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0px 0;
  font-size: 14px;
  border-radius: 2px;
}
.syngenta-ant-btn-icon-only.syngenta-ant-btn-sm > * {
  font-size: 14px;
}
.syngenta-ant-btn-icon-only > .anticon {
  display: flex;
  justify-content: center;
}
.syngenta-ant-btn-icon-only .anticon-loading {
  padding: 0 !important;
}
a.syngenta-ant-btn-icon-only {
  vertical-align: -1px;
}
a.syngenta-ant-btn-icon-only > .anticon {
  display: inline;
}
.syngenta-ant-btn-round {
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 32px;
}
.syngenta-ant-btn-round.syngenta-ant-btn-lg {
  height: 40px;
  padding: 6.4px 20px;
  font-size: 16px;
  border-radius: 40px;
}
.syngenta-ant-btn-round.syngenta-ant-btn-sm {
  height: 24px;
  padding: 0px 12px;
  font-size: 14px;
  border-radius: 24px;
}
.syngenta-ant-btn-round.syngenta-ant-btn-icon-only {
  width: auto;
}
.syngenta-ant-btn-circle {
  min-width: 32px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}
.syngenta-ant-btn-circle.syngenta-ant-btn-lg {
  min-width: 40px;
  border-radius: 50%;
}
.syngenta-ant-btn-circle.syngenta-ant-btn-sm {
  min-width: 24px;
  border-radius: 50%;
}
.syngenta-ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none;
}
.syngenta-ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syngenta-ant-btn .anticon.anticon-plus > svg,
.syngenta-ant-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizespeed;
}
.syngenta-ant-btn.syngenta-ant-btn-loading {
  position: relative;
  cursor: default;
}
.syngenta-ant-btn.syngenta-ant-btn-loading::before {
  display: block;
}
.syngenta-ant-btn > .syngenta-ant-btn-loading-icon {
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syngenta-ant-btn > .syngenta-ant-btn-loading-icon .anticon {
  padding-right: 8px;
  animation: none;
}
.syngenta-ant-btn > .syngenta-ant-btn-loading-icon .anticon svg {
  animation: loadingCircle 1s infinite linear;
}
.syngenta-ant-btn-group {
  position: relative;
  display: inline-flex;
}
.syngenta-ant-btn-group > .syngenta-ant-btn,
.syngenta-ant-btn-group > span > .syngenta-ant-btn {
  position: relative;
}
.syngenta-ant-btn-group > .syngenta-ant-btn:hover,
.syngenta-ant-btn-group > span > .syngenta-ant-btn:hover,
.syngenta-ant-btn-group > .syngenta-ant-btn:focus,
.syngenta-ant-btn-group > span > .syngenta-ant-btn:focus,
.syngenta-ant-btn-group > .syngenta-ant-btn:active,
.syngenta-ant-btn-group > span > .syngenta-ant-btn:active {
  z-index: 2;
}
.syngenta-ant-btn-group > .syngenta-ant-btn[disabled],
.syngenta-ant-btn-group > span > .syngenta-ant-btn[disabled] {
  z-index: 0;
}
.syngenta-ant-btn-group .syngenta-ant-btn-icon-only {
  font-size: 14px;
}
.syngenta-ant-btn-group .syngenta-ant-btn + .syngenta-ant-btn,
.syngenta-ant-btn + .syngenta-ant-btn-group,
.syngenta-ant-btn-group span + .syngenta-ant-btn,
.syngenta-ant-btn-group .syngenta-ant-btn + span,
.syngenta-ant-btn-group > span + span,
.syngenta-ant-btn-group + .syngenta-ant-btn,
.syngenta-ant-btn-group + .syngenta-ant-btn-group {
  margin-left: -1px;
}
.syngenta-ant-btn-group .syngenta-ant-btn-primary + .syngenta-ant-btn:not(.syngenta-ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.syngenta-ant-btn-group .syngenta-ant-btn {
  border-radius: 0;
}
.syngenta-ant-btn-group > .syngenta-ant-btn:first-child,
.syngenta-ant-btn-group > span:first-child > .syngenta-ant-btn {
  margin-left: 0;
}
.syngenta-ant-btn-group > .syngenta-ant-btn:only-child {
  border-radius: 2px;
}
.syngenta-ant-btn-group > span:only-child > .syngenta-ant-btn {
  border-radius: 2px;
}
.syngenta-ant-btn-group > .syngenta-ant-btn:first-child:not(:last-child),
.syngenta-ant-btn-group > span:first-child:not(:last-child) > .syngenta-ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.syngenta-ant-btn-group > .syngenta-ant-btn:last-child:not(:first-child),
.syngenta-ant-btn-group > span:last-child:not(:first-child) > .syngenta-ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.syngenta-ant-btn-group-sm > .syngenta-ant-btn:only-child {
  border-radius: 2px;
}
.syngenta-ant-btn-group-sm > span:only-child > .syngenta-ant-btn {
  border-radius: 2px;
}
.syngenta-ant-btn-group-sm > .syngenta-ant-btn:first-child:not(:last-child),
.syngenta-ant-btn-group-sm > span:first-child:not(:last-child) > .syngenta-ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.syngenta-ant-btn-group-sm > .syngenta-ant-btn:last-child:not(:first-child),
.syngenta-ant-btn-group-sm > span:last-child:not(:first-child) > .syngenta-ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.syngenta-ant-btn-group > .syngenta-ant-btn-group {
  float: left;
}
.syngenta-ant-btn-group > .syngenta-ant-btn-group:not(:first-child):not(:last-child) > .syngenta-ant-btn {
  border-radius: 0;
}
.syngenta-ant-btn-group > .syngenta-ant-btn-group:first-child:not(:last-child) > .syngenta-ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syngenta-ant-btn-group > .syngenta-ant-btn-group:last-child:not(:first-child) > .syngenta-ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syngenta-ant-btn-rtl.syngenta-ant-btn-group .syngenta-ant-btn + .syngenta-ant-btn,
.syngenta-ant-btn-rtl.syngenta-ant-btn + .syngenta-ant-btn-group,
.syngenta-ant-btn-rtl.syngenta-ant-btn-group span + .syngenta-ant-btn,
.syngenta-ant-btn-rtl.syngenta-ant-btn-group .syngenta-ant-btn + span,
.syngenta-ant-btn-rtl.syngenta-ant-btn-group > span + span,
.syngenta-ant-btn-rtl.syngenta-ant-btn-group + .syngenta-ant-btn,
.syngenta-ant-btn-rtl.syngenta-ant-btn-group + .syngenta-ant-btn-group,
.syngenta-ant-btn-group-rtl.syngenta-ant-btn-group .syngenta-ant-btn + .syngenta-ant-btn,
.syngenta-ant-btn-group-rtl.syngenta-ant-btn + .syngenta-ant-btn-group,
.syngenta-ant-btn-group-rtl.syngenta-ant-btn-group span + .syngenta-ant-btn,
.syngenta-ant-btn-group-rtl.syngenta-ant-btn-group .syngenta-ant-btn + span,
.syngenta-ant-btn-group-rtl.syngenta-ant-btn-group > span + span,
.syngenta-ant-btn-group-rtl.syngenta-ant-btn-group + .syngenta-ant-btn,
.syngenta-ant-btn-group-rtl.syngenta-ant-btn-group + .syngenta-ant-btn-group {
  margin-right: -1px;
  margin-left: auto;
}
.syngenta-ant-btn-group.syngenta-ant-btn-group-rtl {
  direction: rtl;
}
.syngenta-ant-btn-group-rtl.syngenta-ant-btn-group > .syngenta-ant-btn:first-child:not(:last-child),
.syngenta-ant-btn-group-rtl.syngenta-ant-btn-group > span:first-child:not(:last-child) > .syngenta-ant-btn {
  border-radius: 0 2px 2px 0;
}
.syngenta-ant-btn-group-rtl.syngenta-ant-btn-group > .syngenta-ant-btn:last-child:not(:first-child),
.syngenta-ant-btn-group-rtl.syngenta-ant-btn-group > span:last-child:not(:first-child) > .syngenta-ant-btn {
  border-radius: 2px 0 0 2px;
}
.syngenta-ant-btn-group-rtl.syngenta-ant-btn-group-sm > .syngenta-ant-btn:first-child:not(:last-child),
.syngenta-ant-btn-group-rtl.syngenta-ant-btn-group-sm > span:first-child:not(:last-child) > .syngenta-ant-btn {
  border-radius: 0 2px 2px 0;
}
.syngenta-ant-btn-group-rtl.syngenta-ant-btn-group-sm > .syngenta-ant-btn:last-child:not(:first-child),
.syngenta-ant-btn-group-rtl.syngenta-ant-btn-group-sm > span:last-child:not(:first-child) > .syngenta-ant-btn {
  border-radius: 2px 0 0 2px;
}
.syngenta-ant-btn:focus > span,
.syngenta-ant-btn:active > span {
  position: relative;
}
.syngenta-ant-btn > .anticon + span,
.syngenta-ant-btn > span + .anticon {
  margin-left: 8px;
}
.syngenta-ant-btn.syngenta-ant-btn-background-ghost {
  color: #fff;
  border-color: #fff;
}
.syngenta-ant-btn.syngenta-ant-btn-background-ghost,
.syngenta-ant-btn.syngenta-ant-btn-background-ghost:hover,
.syngenta-ant-btn.syngenta-ant-btn-background-ghost:active,
.syngenta-ant-btn.syngenta-ant-btn-background-ghost:focus {
  background: transparent;
}
.syngenta-ant-btn.syngenta-ant-btn-background-ghost:hover,
.syngenta-ant-btn.syngenta-ant-btn-background-ghost:focus {
  color: #0d0d0d;
  border-color: #0d0d0d;
}
.syngenta-ant-btn.syngenta-ant-btn-background-ghost:active {
  color: #000000;
  border-color: #000000;
}
.syngenta-ant-btn.syngenta-ant-btn-background-ghost[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: #F0F3F7;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary {
  color: #000;
  border-color: #000;
  text-shadow: none;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary:hover,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary:focus {
  color: #0d0d0d;
  border-color: #0d0d0d;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary:hover > a:only-child,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary:hover > a:only-child::after,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary:active {
  color: #000000;
  border-color: #000000;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary[disabled],
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary[disabled]:hover,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary[disabled]:focus,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary[disabled]:active {
  color: #E8EAED;
  border-color: #e8eaed;
  background: #C1C5C8;
  text-shadow: none;
  box-shadow: none;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary[disabled] > a:only-child,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary[disabled]:hover > a:only-child,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary[disabled]:focus > a:only-child,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary[disabled] > a:only-child::after,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary[disabled]:hover > a:only-child::after,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary[disabled]:focus > a:only-child::after,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger {
  color: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: none;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger:hover,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger:focus {
  color: #ff7875;
  border-color: #ff7875;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger:hover > a:only-child,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger:focus > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger:hover > a:only-child::after,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger:active {
  color: #d9363e;
  border-color: #d9363e;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger[disabled],
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger[disabled]:hover,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger[disabled]:focus,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger[disabled]:active {
  color: #E8EAED;
  border-color: #e8eaed;
  background: #C1C5C8;
  text-shadow: none;
  box-shadow: none;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger[disabled] > a:only-child,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger[disabled]:hover > a:only-child,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger[disabled]:focus > a:only-child,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger[disabled]:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger[disabled] > a:only-child::after,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger[disabled]:hover > a:only-child::after,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger[disabled]:focus > a:only-child::after,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous {
  color: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: none;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous:hover,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous:focus {
  color: #ff7875;
  border-color: #ff7875;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous:hover > a:only-child,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous:focus > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous:hover > a:only-child::after,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous:active {
  color: #d9363e;
  border-color: #d9363e;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous[disabled],
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous[disabled]:hover,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous[disabled]:focus,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous[disabled]:active {
  color: #E8EAED;
  border-color: #e8eaed;
  background: #C1C5C8;
  text-shadow: none;
  box-shadow: none;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous[disabled] > a:only-child,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous[disabled]:hover > a:only-child,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous[disabled]:focus > a:only-child,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous[disabled] > a:only-child::after,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous[disabled]:hover > a:only-child::after,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous[disabled]:focus > a:only-child::after,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link {
  color: #ff4d4f;
  border-color: transparent;
  text-shadow: none;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:hover,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:focus {
  color: #ff7875;
  border-color: transparent;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:hover > a:only-child,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:hover > a:only-child::after,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:active {
  color: #d9363e;
  border-color: transparent;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled],
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:hover,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:focus,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:active {
  color: #E8EAED;
  border-color: #e8eaed;
  background: #C1C5C8;
  text-shadow: none;
  box-shadow: none;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled] > a:only-child,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:hover > a:only-child,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:focus > a:only-child,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled] > a:only-child::after,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:hover > a:only-child::after,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:focus > a:only-child::after,
.syngenta-ant-btn-background-ghost.syngenta-ant-btn-dangerous.syngenta-ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.syngenta-ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
.syngenta-ant-btn-two-chinese-chars > *:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
.syngenta-ant-btn.syngenta-ant-btn-block {
  width: 100%;
}
.syngenta-ant-btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
a.syngenta-ant-btn {
  padding-top: 0.01px !important;
  line-height: 30px;
}
a.syngenta-ant-btn-disabled {
  cursor: not-allowed;
}
a.syngenta-ant-btn-disabled > * {
  pointer-events: none;
}
a.syngenta-ant-btn-disabled,
a.syngenta-ant-btn-disabled:hover,
a.syngenta-ant-btn-disabled:focus,
a.syngenta-ant-btn-disabled:active {
  color: #E8EAED;
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
a.syngenta-ant-btn-disabled > a:only-child,
a.syngenta-ant-btn-disabled:hover > a:only-child,
a.syngenta-ant-btn-disabled:focus > a:only-child,
a.syngenta-ant-btn-disabled:active > a:only-child {
  color: currentcolor;
}
a.syngenta-ant-btn-disabled > a:only-child::after,
a.syngenta-ant-btn-disabled:hover > a:only-child::after,
a.syngenta-ant-btn-disabled:focus > a:only-child::after,
a.syngenta-ant-btn-disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
a.syngenta-ant-btn-lg {
  line-height: 38px;
}
a.syngenta-ant-btn-sm {
  line-height: 22px;
}
.syngenta-ant-btn-compact-item:not(.syngenta-ant-btn-compact-last-item):not(.syngenta-ant-btn-compact-item-rtl) {
  margin-right: -1px;
}
.syngenta-ant-btn-compact-item:not(.syngenta-ant-btn-compact-last-item).syngenta-ant-btn-compact-item-rtl {
  margin-left: -1px;
}
.syngenta-ant-btn-compact-item:hover,
.syngenta-ant-btn-compact-item:focus,
.syngenta-ant-btn-compact-item:active {
  z-index: 2;
}
.syngenta-ant-btn-compact-item[disabled] {
  z-index: 0;
}
.syngenta-ant-btn-compact-item:not(.syngenta-ant-btn-compact-first-item):not(.syngenta-ant-btn-compact-last-item).syngenta-ant-btn {
  border-radius: 0;
}
.syngenta-ant-btn-compact-item.syngenta-ant-btn.syngenta-ant-btn-compact-first-item:not(.syngenta-ant-btn-compact-last-item):not(.syngenta-ant-btn-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syngenta-ant-btn-compact-item.syngenta-ant-btn.syngenta-ant-btn-compact-last-item:not(.syngenta-ant-btn-compact-first-item):not(.syngenta-ant-btn-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syngenta-ant-btn-compact-item.syngenta-ant-btn.syngenta-ant-btn-compact-item-rtl.syngenta-ant-btn-compact-first-item:not(.syngenta-ant-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syngenta-ant-btn-compact-item.syngenta-ant-btn.syngenta-ant-btn-compact-item-rtl.syngenta-ant-btn-compact-last-item:not(.syngenta-ant-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syngenta-ant-btn-icon-only.syngenta-ant-btn-compact-item {
  flex: none;
}
.syngenta-ant-btn-compact-item.syngenta-ant-btn-primary:not([disabled]) + .syngenta-ant-btn-compact-item.syngenta-ant-btn-primary:not([disabled]) {
  position: relative;
}
.syngenta-ant-btn-compact-item.syngenta-ant-btn-primary:not([disabled]) + .syngenta-ant-btn-compact-item.syngenta-ant-btn-primary:not([disabled])::after {
  position: absolute;
  top: -1px;
  left: -1px;
  display: inline-block;
  width: 1px;
  height: calc(100% + 1px * 2);
  background-color: #0d0d0d;
  content: ' ';
}
.syngenta-ant-btn-compact-item-rtl.syngenta-ant-btn-compact-first-item.syngenta-ant-btn-compact-item-rtl:not(.syngenta-ant-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syngenta-ant-btn-compact-item-rtl.syngenta-ant-btn-compact-last-item.syngenta-ant-btn-compact-item-rtl:not(.syngenta-ant-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syngenta-ant-btn-compact-item-rtl.syngenta-ant-btn-sm.syngenta-ant-btn-compact-first-item.syngenta-ant-btn-compact-item-rtl.syngenta-ant-btn-sm:not(.syngenta-ant-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syngenta-ant-btn-compact-item-rtl.syngenta-ant-btn-sm.syngenta-ant-btn-compact-last-item.syngenta-ant-btn-compact-item-rtl.syngenta-ant-btn-sm:not(.syngenta-ant-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syngenta-ant-btn-compact-item-rtl.syngenta-ant-btn-primary:not([disabled]) + .syngenta-ant-btn-compact-item-rtl.syngenta-ant-btn-primary:not([disabled])::after {
  right: -1px;
}
.syngenta-ant-btn-compact-vertical-item:not(.syngenta-ant-btn-compact-vertical-last-item) {
  margin-bottom: -1px;
}
.syngenta-ant-btn-compact-vertical-item:hover,
.syngenta-ant-btn-compact-vertical-item:focus,
.syngenta-ant-btn-compact-vertical-item:active {
  z-index: 2;
}
.syngenta-ant-btn-compact-vertical-item[disabled] {
  z-index: 0;
}
.syngenta-ant-btn-compact-vertical-item:not(.syngenta-ant-btn-compact-vertical-first-item):not(.syngenta-ant-btn-compact-vertical-last-item) {
  border-radius: 0;
}
.syngenta-ant-btn-compact-vertical-item.syngenta-ant-btn-compact-vertical-first-item:not(.syngenta-ant-btn-compact-vertical-last-item) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.syngenta-ant-btn-compact-vertical-item.syngenta-ant-btn-compact-vertical-last-item:not(.syngenta-ant-btn-compact-vertical-first-item) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.syngenta-ant-btn-compact-vertical-item.syngenta-ant-btn-primary:not([disabled]) + .syngenta-ant-btn-compact-vertical-item.syngenta-ant-btn-primary:not([disabled]) {
  position: relative;
}
.syngenta-ant-btn-compact-vertical-item.syngenta-ant-btn-primary:not([disabled]) + .syngenta-ant-btn-compact-vertical-item.syngenta-ant-btn-primary:not([disabled])::after {
  position: absolute;
  top: -1px;
  left: -1px;
  display: inline-block;
  width: calc(100% + 1px * 2);
  height: 1px;
  background-color: #0d0d0d;
  content: ' ';
}
.syngenta-ant-btn-rtl {
  direction: rtl;
}
.syngenta-ant-btn-group-rtl.syngenta-ant-btn-group .syngenta-ant-btn-primary:last-child:not(:first-child),
.syngenta-ant-btn-group-rtl.syngenta-ant-btn-group .syngenta-ant-btn-primary + .syngenta-ant-btn-primary {
  border-right-color: #0d0d0d;
  border-left-color: #F0F3F7;
}
.syngenta-ant-btn-group-rtl.syngenta-ant-btn-group .syngenta-ant-btn-primary:last-child:not(:first-child)[disabled],
.syngenta-ant-btn-group-rtl.syngenta-ant-btn-group .syngenta-ant-btn-primary + .syngenta-ant-btn-primary[disabled] {
  border-right-color: #F0F3F7;
  border-left-color: #0d0d0d;
}
.syngenta-ant-btn-rtl.syngenta-ant-btn > .syngenta-ant-btn-loading-icon .anticon {
  padding-right: 0;
  padding-left: 8px;
}
.syngenta-ant-btn-rtl.syngenta-ant-btn > .anticon + span,
.syngenta-ant-btn-rtl.syngenta-ant-btn > span + .anticon {
  margin-right: 8px;
  margin-left: 0;
}
.syngenta-ant-form-item .syngenta-ant-input-number + .syngenta-ant-form-text {
  margin-left: 8px;
}
.syngenta-ant-form-inline {
  display: flex;
  flex-wrap: wrap;
}
.syngenta-ant-form-inline .syngenta-ant-form-item {
  flex: none;
  flex-wrap: nowrap;
  margin-right: 16px;
  margin-bottom: 0;
}
.syngenta-ant-form-inline .syngenta-ant-form-item-with-help {
  margin-bottom: 5px;
}
.syngenta-ant-form-inline .syngenta-ant-form-item > .syngenta-ant-form-item-label,
.syngenta-ant-form-inline .syngenta-ant-form-item > .syngenta-ant-form-item-control {
  display: inline-block;
  vertical-align: top;
}
.syngenta-ant-form-inline .syngenta-ant-form-item > .syngenta-ant-form-item-label {
  flex: none;
}
.syngenta-ant-form-inline .syngenta-ant-form-item .syngenta-ant-form-text {
  display: inline-block;
}
.syngenta-ant-form-inline .syngenta-ant-form-item .syngenta-ant-form-item-has-feedback {
  display: inline-block;
}
.syngenta-ant-form-horizontal .syngenta-ant-form-item-label {
  flex-grow: 0;
}
.syngenta-ant-form-horizontal .syngenta-ant-form-item-control {
  flex: 1 1 0;
  min-width: 0;
}
.syngenta-ant-form-horizontal .syngenta-ant-form-item-label[class$='-24'] + .syngenta-ant-form-item-control,
.syngenta-ant-form-horizontal .syngenta-ant-form-item-label[class*='-24 '] + .syngenta-ant-form-item-control {
  min-width: unset;
}
.syngenta-ant-form-vertical .syngenta-ant-form-item-row {
  flex-direction: column;
}
.syngenta-ant-form-vertical .syngenta-ant-form-item-label > label {
  height: auto;
}
.syngenta-ant-form-vertical .syngenta-ant-form-item .syngenta-ant-form-item-control {
  width: 100%;
}
.syngenta-ant-form-vertical .syngenta-ant-form-item-label,
.syngenta-ant-col-24.syngenta-ant-form-item-label,
.syngenta-ant-col-xl-24.syngenta-ant-form-item-label {
  padding: 0;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
}
.syngenta-ant-form-vertical .syngenta-ant-form-item-label > label,
.syngenta-ant-col-24.syngenta-ant-form-item-label > label,
.syngenta-ant-col-xl-24.syngenta-ant-form-item-label > label {
  margin: 0;
}
.syngenta-ant-form-vertical .syngenta-ant-form-item-label > label::after,
.syngenta-ant-col-24.syngenta-ant-form-item-label > label::after,
.syngenta-ant-col-xl-24.syngenta-ant-form-item-label > label::after {
  display: none;
}
.syngenta-ant-form-rtl.syngenta-ant-form-vertical .syngenta-ant-form-item-label,
.syngenta-ant-form-rtl.syngenta-ant-col-24.syngenta-ant-form-item-label,
.syngenta-ant-form-rtl.syngenta-ant-col-xl-24.syngenta-ant-form-item-label {
  text-align: right;
}
@media (max-width: 575px) {
  .syngenta-ant-form-item .syngenta-ant-form-item-label {
    padding: 0;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .syngenta-ant-form-item .syngenta-ant-form-item-label > label {
    margin: 0;
  }
  .syngenta-ant-form-item .syngenta-ant-form-item-label > label::after {
    display: none;
  }
  .syngenta-ant-form-rtl.syngenta-ant-form-item .syngenta-ant-form-item-label {
    text-align: right;
  }
  .syngenta-ant-form .syngenta-ant-form-item {
    flex-wrap: wrap;
  }
  .syngenta-ant-form .syngenta-ant-form-item .syngenta-ant-form-item-label,
  .syngenta-ant-form .syngenta-ant-form-item .syngenta-ant-form-item-control {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .syngenta-ant-col-xs-24.syngenta-ant-form-item-label {
    padding: 0;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .syngenta-ant-col-xs-24.syngenta-ant-form-item-label > label {
    margin: 0;
  }
  .syngenta-ant-col-xs-24.syngenta-ant-form-item-label > label::after {
    display: none;
  }
  .syngenta-ant-form-rtl.syngenta-ant-col-xs-24.syngenta-ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 767px) {
  .syngenta-ant-col-sm-24.syngenta-ant-form-item-label {
    padding: 0;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .syngenta-ant-col-sm-24.syngenta-ant-form-item-label > label {
    margin: 0;
  }
  .syngenta-ant-col-sm-24.syngenta-ant-form-item-label > label::after {
    display: none;
  }
  .syngenta-ant-form-rtl.syngenta-ant-col-sm-24.syngenta-ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 991px) {
  .syngenta-ant-col-md-24.syngenta-ant-form-item-label {
    padding: 0;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .syngenta-ant-col-md-24.syngenta-ant-form-item-label > label {
    margin: 0;
  }
  .syngenta-ant-col-md-24.syngenta-ant-form-item-label > label::after {
    display: none;
  }
  .syngenta-ant-form-rtl.syngenta-ant-col-md-24.syngenta-ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1199px) {
  .syngenta-ant-col-lg-24.syngenta-ant-form-item-label {
    padding: 0;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .syngenta-ant-col-lg-24.syngenta-ant-form-item-label > label {
    margin: 0;
  }
  .syngenta-ant-col-lg-24.syngenta-ant-form-item-label > label::after {
    display: none;
  }
  .syngenta-ant-form-rtl.syngenta-ant-col-lg-24.syngenta-ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1599px) {
  .syngenta-ant-col-xl-24.syngenta-ant-form-item-label {
    padding: 0;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .syngenta-ant-col-xl-24.syngenta-ant-form-item-label > label {
    margin: 0;
  }
  .syngenta-ant-col-xl-24.syngenta-ant-form-item-label > label::after {
    display: none;
  }
  .syngenta-ant-form-rtl.syngenta-ant-col-xl-24.syngenta-ant-form-item-label {
    text-align: right;
  }
}
.syngenta-ant-form-item {
  /* Some non-status related component style is in `components.less` */
  /* To support leave along ErrorList. We add additional className to handle explain style */
}
.syngenta-ant-form-item-explain-error {
  color: #ff4d4f;
}
.syngenta-ant-form-item-explain-warning {
  color: #faad14;
}
.syngenta-ant-form-item-has-feedback .syngenta-ant-switch {
  margin: 2px 0 4px;
}
.syngenta-ant-form-item-has-warning .syngenta-ant-form-item-split {
  color: #faad14;
}
.syngenta-ant-form-item-has-error .syngenta-ant-form-item-split {
  color: #ff4d4f;
}
.syngenta-ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.syngenta-ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: #707374;
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #e8eaed;
}
.syngenta-ant-form label {
  font-size: 14px;
}
.syngenta-ant-form input[type='search'] {
  box-sizing: border-box;
}
.syngenta-ant-form input[type='radio'],
.syngenta-ant-form input[type='checkbox'] {
  line-height: normal;
}
.syngenta-ant-form input[type='file'] {
  display: block;
}
.syngenta-ant-form input[type='range'] {
  display: block;
  width: 100%;
}
.syngenta-ant-form select[multiple],
.syngenta-ant-form select[size] {
  height: auto;
}
.syngenta-ant-form input[type='file']:focus,
.syngenta-ant-form input[type='radio']:focus,
.syngenta-ant-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.syngenta-ant-form output {
  display: block;
  padding-top: 15px;
  color: #00a0be;
  font-size: 14px;
  line-height: 1.5715;
}
.syngenta-ant-form .syngenta-ant-form-text {
  display: inline-block;
  padding-right: 8px;
}
.syngenta-ant-form-small .syngenta-ant-form-item-label > label {
  height: 24px;
}
.syngenta-ant-form-small .syngenta-ant-form-item-control-input {
  min-height: 24px;
}
.syngenta-ant-form-large .syngenta-ant-form-item-label > label {
  height: 40px;
}
.syngenta-ant-form-large .syngenta-ant-form-item-control-input {
  min-height: 40px;
}
.syngenta-ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  margin-bottom: 5px;
  vertical-align: top;
}
.syngenta-ant-form-item-with-help {
  transition: none;
}
.syngenta-ant-form-item-hidden,
.syngenta-ant-form-item-hidden.syngenta-ant-row {
  display: none;
}
.syngenta-ant-form-item-label {
  display: inline-block;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.syngenta-ant-form-item-label-left {
  text-align: left;
}
.syngenta-ant-form-item-label-wrap {
  overflow: unset;
  line-height: 1.3215em;
  white-space: unset;
}
.syngenta-ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 32px;
  color: #707374;
  font-size: 14px;
}
.syngenta-ant-form-item-label > label > .anticon {
  font-size: 14px;
  vertical-align: top;
}
.syngenta-ant-form-item-label > label.syngenta-ant-form-item-required:not(.syngenta-ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.syngenta-ant-form-hide-required-mark .syngenta-ant-form-item-label > label.syngenta-ant-form-item-required:not(.syngenta-ant-form-item-required-mark-optional)::before {
  display: none;
}
.syngenta-ant-form-item-label > label .syngenta-ant-form-item-optional {
  display: inline-block;
  margin-left: 4px;
  color: #707374;
}
.syngenta-ant-form-hide-required-mark .syngenta-ant-form-item-label > label .syngenta-ant-form-item-optional {
  display: none;
}
.syngenta-ant-form-item-label > label .syngenta-ant-form-item-tooltip {
  color: #707374;
  cursor: help;
  writing-mode: horizontal-tb;
  margin-inline-start: 4px;
}
.syngenta-ant-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.syngenta-ant-form-item-label > label.syngenta-ant-form-item-no-colon::after {
  content: ' ';
}
.syngenta-ant-form-item-control {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.syngenta-ant-form-item-control:first-child:not([class^='syngenta-ant-col-']):not([class*=' syngenta-ant-col-']) {
  width: 100%;
}
.syngenta-ant-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
}
.syngenta-ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}
.syngenta-ant-form-item-explain,
.syngenta-ant-form-item-extra {
  clear: both;
  color: #707374;
  font-size: 14px;
  line-height: 1.5715;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-top: -9px;
  margin-bottom: -9px;
}
.syngenta-ant-form-item-explain-connected {
  width: 100%;
}
.syngenta-ant-form-item-extra {
  min-height: 5px;
}
.syngenta-ant-form-item-with-help .syngenta-ant-form-item-explain {
  height: auto;
  opacity: 1;
}
.syngenta-ant-form-item-feedback-icon {
  font-size: 14px;
  text-align: center;
  visibility: visible;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}
.syngenta-ant-form-item-feedback-icon-success {
  color: #52c41a;
}
.syngenta-ant-form-item-feedback-icon-error {
  color: #ff4d4f;
}
.syngenta-ant-form-item-feedback-icon-warning {
  color: #faad14;
}
.syngenta-ant-form-item-feedback-icon-validating {
  color: #000;
}
.syngenta-ant-show-help {
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syngenta-ant-show-help-appear,
.syngenta-ant-show-help-enter {
  opacity: 0;
}
.syngenta-ant-show-help-appear-active,
.syngenta-ant-show-help-enter-active {
  opacity: 1;
}
.syngenta-ant-show-help-leave {
  opacity: 1;
}
.syngenta-ant-show-help-leave-active {
  opacity: 0;
}
.syngenta-ant-show-help-item {
  overflow: hidden;
  transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.syngenta-ant-show-help-item-appear,
.syngenta-ant-show-help-item-enter {
  transform: translateY(-5px);
  opacity: 0;
}
.syngenta-ant-show-help-item-appear-active,
.syngenta-ant-show-help-item-enter-active {
  transform: translateY(0);
  opacity: 1;
}
.syngenta-ant-show-help-item-leave {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.syngenta-ant-show-help-item-leave-active {
  transform: translateY(-5px);
}
@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.syngenta-ant-form-rtl {
  direction: rtl;
}
.syngenta-ant-form-rtl .syngenta-ant-form-item-label {
  text-align: left;
}
.syngenta-ant-form-rtl .syngenta-ant-form-item-label > label.syngenta-ant-form-item-required::before {
  margin-right: 0;
  margin-left: 4px;
}
.syngenta-ant-form-rtl .syngenta-ant-form-item-label > label::after {
  margin: 0 2px 0 8px;
}
.syngenta-ant-form-rtl .syngenta-ant-form-item-label > label .syngenta-ant-form-item-optional {
  margin-right: 4px;
  margin-left: 0;
}
.syngenta-ant-col-rtl .syngenta-ant-form-item-control:first-child {
  width: 100%;
}
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback .syngenta-ant-input {
  padding-right: 11px;
  padding-left: 24px;
}
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback .syngenta-ant-input-affix-wrapper .syngenta-ant-input-suffix {
  padding-right: 11px;
  padding-left: 18px;
}
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback .syngenta-ant-input-affix-wrapper .syngenta-ant-input {
  padding: 0;
}
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback .syngenta-ant-input-number-affix-wrapper .syngenta-ant-input-number {
  padding: 0;
}
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback .syngenta-ant-input-search:not(.syngenta-ant-input-search-enter-button) .syngenta-ant-input-suffix {
  right: auto;
  left: 28px;
}
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback .syngenta-ant-input-number {
  padding-left: 18px;
}
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback > .syngenta-ant-select .syngenta-ant-select-arrow,
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback > .syngenta-ant-select .syngenta-ant-select-clear,
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback :not(.syngenta-ant-input-group-addon) > .syngenta-ant-select .syngenta-ant-select-arrow,
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback :not(.syngenta-ant-input-group-addon) > .syngenta-ant-select .syngenta-ant-select-clear,
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback :not(.syngenta-ant-input-number-group-addon) > .syngenta-ant-select .syngenta-ant-select-arrow,
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback :not(.syngenta-ant-input-number-group-addon) > .syngenta-ant-select .syngenta-ant-select-clear {
  right: auto;
  left: 32px;
}
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback > .syngenta-ant-select .syngenta-ant-select-selection-selected-value,
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback :not(.syngenta-ant-input-group-addon) > .syngenta-ant-select .syngenta-ant-select-selection-selected-value,
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback :not(.syngenta-ant-input-number-group-addon) > .syngenta-ant-select .syngenta-ant-select-selection-selected-value {
  padding-right: 0;
  padding-left: 42px;
}
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback .syngenta-ant-cascader-picker-arrow {
  margin-right: 0;
  margin-left: 19px;
}
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback .syngenta-ant-cascader-picker-clear {
  right: auto;
  left: 32px;
}
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback .syngenta-ant-picker {
  padding-right: 11px;
  padding-left: 29.2px;
}
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback .syngenta-ant-picker-large {
  padding-right: 11px;
  padding-left: 29.2px;
}
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback .syngenta-ant-picker-small {
  padding-right: 7px;
  padding-left: 25.2px;
}
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback.syngenta-ant-form-item-has-success .syngenta-ant-form-item-children-icon,
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback.syngenta-ant-form-item-has-warning .syngenta-ant-form-item-children-icon,
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback.syngenta-ant-form-item-has-error .syngenta-ant-form-item-children-icon,
.syngenta-ant-form-rtl .syngenta-ant-form-item-has-feedback.syngenta-ant-form-item-is-validating .syngenta-ant-form-item-children-icon {
  right: auto;
  left: 0;
}
.syngenta-ant-form-rtl.syngenta-ant-form-inline .syngenta-ant-form-item {
  margin-right: 0;
  margin-left: 16px;
}
.syngenta-ant-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.syngenta-ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
.syngenta-ant-progress-steps {
  display: inline-block;
}
.syngenta-ant-progress-steps-outer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.syngenta-ant-progress-steps-item {
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  background: #f3f3f3;
  transition: all 0.3s;
}
.syngenta-ant-progress-steps-item-active {
  background: #1890ff;
}
.syngenta-ant-progress-small.syngenta-ant-progress-line,
.syngenta-ant-progress-small.syngenta-ant-progress-line .syngenta-ant-progress-text .anticon {
  font-size: 12px;
}
.syngenta-ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.syngenta-ant-progress-show-info .syngenta-ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
.syngenta-ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}
.syngenta-ant-progress-circle-trail {
  stroke: #f5f5f5;
}
.syngenta-ant-progress-circle-path {
  animation: syngenta-ant-progress-appear 0.3s;
}
.syngenta-ant-progress-inner:not(.syngenta-ant-progress-circle-gradient) .syngenta-ant-progress-circle-path {
  stroke: #1890ff;
}
.syngenta-ant-progress-success-bg,
.syngenta-ant-progress-bg {
  position: relative;
  background-color: #1890ff;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.syngenta-ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #52c41a;
}
.syngenta-ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: #2f3031;
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
.syngenta-ant-progress-text .anticon {
  font-size: 14px;
}
.syngenta-ant-progress-status-active .syngenta-ant-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  animation: syngenta-ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}
.syngenta-ant-progress-status-exception .syngenta-ant-progress-bg {
  background-color: #ff4d4f;
}
.syngenta-ant-progress-status-exception .syngenta-ant-progress-text {
  color: #ff4d4f;
}
.syngenta-ant-progress-status-exception .syngenta-ant-progress-inner:not(.syngenta-ant-progress-circle-gradient) .syngenta-ant-progress-circle-path {
  stroke: #ff4d4f;
}
.syngenta-ant-progress-status-success .syngenta-ant-progress-bg {
  background-color: #52c41a;
}
.syngenta-ant-progress-status-success .syngenta-ant-progress-text {
  color: #52c41a;
}
.syngenta-ant-progress-status-success .syngenta-ant-progress-inner:not(.syngenta-ant-progress-circle-gradient) .syngenta-ant-progress-circle-path {
  stroke: #52c41a;
}
.syngenta-ant-progress-circle .syngenta-ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
.syngenta-ant-progress-circle .syngenta-ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 1em;
  line-height: 1;
  white-space: normal;
  text-align: center;
  transform: translate(-50%, -50%);
}
.syngenta-ant-progress-circle .syngenta-ant-progress-text .anticon {
  font-size: 1.16666667em;
}
.syngenta-ant-progress-circle.syngenta-ant-progress-status-exception .syngenta-ant-progress-text {
  color: #ff4d4f;
}
.syngenta-ant-progress-circle.syngenta-ant-progress-status-success .syngenta-ant-progress-text {
  color: #52c41a;
}
@keyframes syngenta-ant-progress-active {
  0% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.1;
  }
  20% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0) scaleX(1);
    opacity: 0;
  }
}
.syngenta-ant-progress-rtl {
  direction: rtl;
}
.syngenta-ant-progress-rtl.syngenta-ant-progress-show-info .syngenta-ant-progress-outer {
  margin-right: 0;
  margin-left: calc(-2em - 8px);
  padding-right: 0;
  padding-left: calc(2em + 8px);
}
.syngenta-ant-progress-rtl .syngenta-ant-progress-success-bg {
  right: 0;
  left: auto;
}
.syngenta-ant-progress-rtl.syngenta-ant-progress-line .syngenta-ant-progress-text,
.syngenta-ant-progress-rtl.syngenta-ant-progress-steps .syngenta-ant-progress-text {
  margin-right: 8px;
  margin-left: 0;
  text-align: right;
}
.syngenta-ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: #00a0be;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #f5f8fc;
  background-image: none;
  border: 1px solid #e8eaed;
  border-radius: 2px;
  transition: all 0.3s;
  display: inline-flex;
}
.syngenta-ant-input-affix-wrapper::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.syngenta-ant-input-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}
.syngenta-ant-input-affix-wrapper:hover {
  border-color: #0d0d0d;
  border-right-width: 1px;
}
.syngenta-ant-input-rtl .syngenta-ant-input-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syngenta-ant-input-affix-wrapper:focus,
.syngenta-ant-input-affix-wrapper-focused {
  border-color: #0d0d0d;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.syngenta-ant-input-rtl .syngenta-ant-input-affix-wrapper:focus,
.syngenta-ant-input-rtl .syngenta-ant-input-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syngenta-ant-input-affix-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #e8eaed;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.syngenta-ant-input-affix-wrapper-disabled:hover {
  border-color: #e8eaed;
  border-right-width: 1px;
}
.syngenta-ant-input-affix-wrapper[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #e8eaed;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.syngenta-ant-input-affix-wrapper[disabled]:hover {
  border-color: #e8eaed;
  border-right-width: 1px;
}
.syngenta-ant-input-affix-wrapper-borderless,
.syngenta-ant-input-affix-wrapper-borderless:hover,
.syngenta-ant-input-affix-wrapper-borderless:focus,
.syngenta-ant-input-affix-wrapper-borderless-focused,
.syngenta-ant-input-affix-wrapper-borderless-disabled,
.syngenta-ant-input-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.syngenta-ant-input-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.syngenta-ant-input-affix-wrapper-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.syngenta-ant-input-affix-wrapper-sm {
  padding: 0px 7px;
}
.syngenta-ant-input-affix-wrapper-rtl {
  direction: rtl;
}
.syngenta-ant-input-affix-wrapper:not(.syngenta-ant-input-affix-wrapper-disabled):hover {
  border-color: #0d0d0d;
  border-right-width: 1px;
  z-index: 1;
}
.syngenta-ant-input-rtl .syngenta-ant-input-affix-wrapper:not(.syngenta-ant-input-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syngenta-ant-input-search-with-button .syngenta-ant-input-affix-wrapper:not(.syngenta-ant-input-affix-wrapper-disabled):hover {
  z-index: 0;
}
.syngenta-ant-input-affix-wrapper-focused,
.syngenta-ant-input-affix-wrapper:focus {
  z-index: 1;
}
.syngenta-ant-input-affix-wrapper-disabled .syngenta-ant-input[disabled] {
  background: rgba(255, 255, 255, 0);
}
.syngenta-ant-input-affix-wrapper > .syngenta-ant-input {
  font-size: inherit;
  border: none;
  outline: none;
}
.syngenta-ant-input-affix-wrapper > .syngenta-ant-input:focus {
  box-shadow: none !important;
}
.syngenta-ant-input-affix-wrapper > .syngenta-ant-input:not(textarea) {
  padding: 0;
}
.syngenta-ant-input-affix-wrapper::before {
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.syngenta-ant-input-prefix,
.syngenta-ant-input-suffix {
  display: flex;
  flex: none;
  align-items: center;
}
.syngenta-ant-input-prefix > *:not(:last-child),
.syngenta-ant-input-suffix > *:not(:last-child) {
  margin-right: 8px;
}
.syngenta-ant-input-show-count-suffix {
  color: #707374;
}
.syngenta-ant-input-show-count-has-suffix {
  margin-right: 2px;
}
.syngenta-ant-input-prefix {
  margin-right: 4px;
}
.syngenta-ant-input-suffix {
  margin-left: 4px;
}
.anticon.syngenta-ant-input-clear-icon,
.syngenta-ant-input-clear-icon {
  margin: 0;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  vertical-align: -1px;
  cursor: pointer;
  transition: color 0.3s;
}
.anticon.syngenta-ant-input-clear-icon:hover,
.syngenta-ant-input-clear-icon:hover {
  color: #707374;
}
.anticon.syngenta-ant-input-clear-icon:active,
.syngenta-ant-input-clear-icon:active {
  color: #2f3031;
}
.anticon.syngenta-ant-input-clear-icon-hidden,
.syngenta-ant-input-clear-icon-hidden {
  visibility: hidden;
}
.anticon.syngenta-ant-input-clear-icon-has-suffix,
.syngenta-ant-input-clear-icon-has-suffix {
  margin: 0 4px;
}
.syngenta-ant-input-affix-wrapper.syngenta-ant-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0;
}
.syngenta-ant-input-affix-wrapper.syngenta-ant-input-affix-wrapper-textarea-with-clear-btn .syngenta-ant-input-clear-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}
.syngenta-ant-input-status-error:not(.syngenta-ant-input-disabled):not(.syngenta-ant-input-borderless).syngenta-ant-input,
.syngenta-ant-input-status-error:not(.syngenta-ant-input-disabled):not(.syngenta-ant-input-borderless).syngenta-ant-input:hover {
  background: #f5f8fc;
  border-color: #ff4d4f;
}
.syngenta-ant-input-status-error:not(.syngenta-ant-input-disabled):not(.syngenta-ant-input-borderless).syngenta-ant-input:focus,
.syngenta-ant-input-status-error:not(.syngenta-ant-input-disabled):not(.syngenta-ant-input-borderless).syngenta-ant-input-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.syngenta-ant-input-status-error .syngenta-ant-input-prefix {
  color: #ff4d4f;
}
.syngenta-ant-input-status-warning:not(.syngenta-ant-input-disabled):not(.syngenta-ant-input-borderless).syngenta-ant-input,
.syngenta-ant-input-status-warning:not(.syngenta-ant-input-disabled):not(.syngenta-ant-input-borderless).syngenta-ant-input:hover {
  background: #f5f8fc;
  border-color: #faad14;
}
.syngenta-ant-input-status-warning:not(.syngenta-ant-input-disabled):not(.syngenta-ant-input-borderless).syngenta-ant-input:focus,
.syngenta-ant-input-status-warning:not(.syngenta-ant-input-disabled):not(.syngenta-ant-input-borderless).syngenta-ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.syngenta-ant-input-status-warning .syngenta-ant-input-prefix {
  color: #faad14;
}
.syngenta-ant-input-affix-wrapper-status-error:not(.syngenta-ant-input-affix-wrapper-disabled):not(.syngenta-ant-input-affix-wrapper-borderless).syngenta-ant-input-affix-wrapper,
.syngenta-ant-input-affix-wrapper-status-error:not(.syngenta-ant-input-affix-wrapper-disabled):not(.syngenta-ant-input-affix-wrapper-borderless).syngenta-ant-input-affix-wrapper:hover {
  background: #f5f8fc;
  border-color: #ff4d4f;
}
.syngenta-ant-input-affix-wrapper-status-error:not(.syngenta-ant-input-affix-wrapper-disabled):not(.syngenta-ant-input-affix-wrapper-borderless).syngenta-ant-input-affix-wrapper:focus,
.syngenta-ant-input-affix-wrapper-status-error:not(.syngenta-ant-input-affix-wrapper-disabled):not(.syngenta-ant-input-affix-wrapper-borderless).syngenta-ant-input-affix-wrapper-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.syngenta-ant-input-affix-wrapper-status-error .syngenta-ant-input-prefix {
  color: #ff4d4f;
}
.syngenta-ant-input-affix-wrapper-status-warning:not(.syngenta-ant-input-affix-wrapper-disabled):not(.syngenta-ant-input-affix-wrapper-borderless).syngenta-ant-input-affix-wrapper,
.syngenta-ant-input-affix-wrapper-status-warning:not(.syngenta-ant-input-affix-wrapper-disabled):not(.syngenta-ant-input-affix-wrapper-borderless).syngenta-ant-input-affix-wrapper:hover {
  background: #f5f8fc;
  border-color: #faad14;
}
.syngenta-ant-input-affix-wrapper-status-warning:not(.syngenta-ant-input-affix-wrapper-disabled):not(.syngenta-ant-input-affix-wrapper-borderless).syngenta-ant-input-affix-wrapper:focus,
.syngenta-ant-input-affix-wrapper-status-warning:not(.syngenta-ant-input-affix-wrapper-disabled):not(.syngenta-ant-input-affix-wrapper-borderless).syngenta-ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.syngenta-ant-input-affix-wrapper-status-warning .syngenta-ant-input-prefix {
  color: #faad14;
}
.syngenta-ant-input-textarea-status-error.syngenta-ant-input-textarea-has-feedback .syngenta-ant-input,
.syngenta-ant-input-textarea-status-warning.syngenta-ant-input-textarea-has-feedback .syngenta-ant-input,
.syngenta-ant-input-textarea-status-success.syngenta-ant-input-textarea-has-feedback .syngenta-ant-input,
.syngenta-ant-input-textarea-status-validating.syngenta-ant-input-textarea-has-feedback .syngenta-ant-input {
  padding-right: 24px;
}
.syngenta-ant-input-group-wrapper-status-error .syngenta-ant-input-group-addon {
  color: #ff4d4f;
  border-color: #ff4d4f;
}
.syngenta-ant-input-group-wrapper-status-warning .syngenta-ant-input-group-addon {
  color: #faad14;
  border-color: #faad14;
}
.syngenta-ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: #00a0be;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #f5f8fc;
  background-image: none;
  border: 1px solid #e8eaed;
  border-radius: 2px;
  transition: all 0.3s;
}
.syngenta-ant-input::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.syngenta-ant-input:placeholder-shown {
  text-overflow: ellipsis;
}
.syngenta-ant-input:hover {
  border-color: #0d0d0d;
  border-right-width: 1px;
}
.syngenta-ant-input-rtl .syngenta-ant-input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syngenta-ant-input:focus,
.syngenta-ant-input-focused {
  border-color: #0d0d0d;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.syngenta-ant-input-rtl .syngenta-ant-input:focus,
.syngenta-ant-input-rtl .syngenta-ant-input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syngenta-ant-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #e8eaed;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.syngenta-ant-input-disabled:hover {
  border-color: #e8eaed;
  border-right-width: 1px;
}
.syngenta-ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #e8eaed;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.syngenta-ant-input[disabled]:hover {
  border-color: #e8eaed;
  border-right-width: 1px;
}
.syngenta-ant-input-borderless,
.syngenta-ant-input-borderless:hover,
.syngenta-ant-input-borderless:focus,
.syngenta-ant-input-borderless-focused,
.syngenta-ant-input-borderless-disabled,
.syngenta-ant-input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.syngenta-ant-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.syngenta-ant-input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.syngenta-ant-input-sm {
  padding: 0px 7px;
}
.syngenta-ant-input-rtl {
  direction: rtl;
}
.syngenta-ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.syngenta-ant-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.syngenta-ant-input-group > [class*='col-'] {
  padding-right: 8px;
}
.syngenta-ant-input-group > [class*='col-']:last-child {
  padding-right: 0;
}
.syngenta-ant-input-group-addon,
.syngenta-ant-input-group-wrap,
.syngenta-ant-input-group > .syngenta-ant-input {
  display: table-cell;
}
.syngenta-ant-input-group-addon:not(:first-child):not(:last-child),
.syngenta-ant-input-group-wrap:not(:first-child):not(:last-child),
.syngenta-ant-input-group > .syngenta-ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.syngenta-ant-input-group-addon,
.syngenta-ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.syngenta-ant-input-group-wrap > * {
  display: block !important;
}
.syngenta-ant-input-group .syngenta-ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.syngenta-ant-input-group .syngenta-ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
.syngenta-ant-input-group .syngenta-ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
.syngenta-ant-input-search-with-button .syngenta-ant-input-group .syngenta-ant-input:hover {
  z-index: 0;
}
.syngenta-ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: #00a0be;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #e8eaed;
  border-radius: 2px;
  transition: all 0.3s;
}
.syngenta-ant-input-group-addon .syngenta-ant-select {
  margin: -5px -11px;
}
.syngenta-ant-input-group-addon .syngenta-ant-select.syngenta-ant-select-single:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.syngenta-ant-input-group-addon .syngenta-ant-select-open .syngenta-ant-select-selector,
.syngenta-ant-input-group-addon .syngenta-ant-select-focused .syngenta-ant-select-selector {
  color: #000;
}
.syngenta-ant-input-group-addon .syngenta-ant-cascader-picker {
  margin: -9px -12px;
  background-color: transparent;
}
.syngenta-ant-input-group-addon .syngenta-ant-cascader-picker .syngenta-ant-cascader-input {
  text-align: left;
  border: 0;
  box-shadow: none;
}
.syngenta-ant-input-group > .syngenta-ant-input:first-child,
.syngenta-ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syngenta-ant-input-group > .syngenta-ant-input:first-child .syngenta-ant-select .syngenta-ant-select-selector,
.syngenta-ant-input-group-addon:first-child .syngenta-ant-select .syngenta-ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syngenta-ant-input-group > .syngenta-ant-input-affix-wrapper:not(:first-child) .syngenta-ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syngenta-ant-input-group > .syngenta-ant-input-affix-wrapper:not(:last-child) .syngenta-ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syngenta-ant-input-group-addon:first-child {
  border-right: 0;
}
.syngenta-ant-input-group-addon:last-child {
  border-left: 0;
}
.syngenta-ant-input-group > .syngenta-ant-input:last-child,
.syngenta-ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syngenta-ant-input-group > .syngenta-ant-input:last-child .syngenta-ant-select .syngenta-ant-select-selector,
.syngenta-ant-input-group-addon:last-child .syngenta-ant-select .syngenta-ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syngenta-ant-input-group-lg .syngenta-ant-input,
.syngenta-ant-input-group-lg > .syngenta-ant-input-group-addon {
  padding: 6.5px 11px;
  font-size: 16px;
}
.syngenta-ant-input-group-sm .syngenta-ant-input,
.syngenta-ant-input-group-sm > .syngenta-ant-input-group-addon {
  padding: 0px 7px;
}
.syngenta-ant-input-group-lg .syngenta-ant-select-single .syngenta-ant-select-selector {
  height: 40px;
}
.syngenta-ant-input-group-sm .syngenta-ant-select-single .syngenta-ant-select-selector {
  height: 24px;
}
.syngenta-ant-input-group .syngenta-ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syngenta-ant-input-search .syngenta-ant-input-group .syngenta-ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.syngenta-ant-input-group .syngenta-ant-input-affix-wrapper:not(:first-child),
.syngenta-ant-input-search .syngenta-ant-input-group .syngenta-ant-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact {
  display: block;
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact::before {
  display: table;
  content: '';
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact::before {
  display: table;
  content: '';
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact-addon:not(:first-child):not(:last-child),
.syngenta-ant-input-group.syngenta-ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.syngenta-ant-input-group.syngenta-ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.syngenta-ant-input-group.syngenta-ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-input-affix-wrapper {
  display: inline-flex;
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-picker-range {
  display: inline-flex;
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact .syngenta-ant-input {
  float: none;
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-select > .syngenta-ant-select-selector,
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-select-auto-complete .syngenta-ant-input,
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-cascader-picker .syngenta-ant-input,
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-input-group-wrapper .syngenta-ant-input {
  border-right-width: 1px;
  border-radius: 0;
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-select > .syngenta-ant-select-selector:hover,
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-select-auto-complete .syngenta-ant-input:hover,
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-cascader-picker .syngenta-ant-input:hover,
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-input-group-wrapper .syngenta-ant-input:hover {
  z-index: 1;
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-select > .syngenta-ant-select-selector:focus,
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-select-auto-complete .syngenta-ant-input:focus,
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-cascader-picker .syngenta-ant-input:focus,
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-input-group-wrapper .syngenta-ant-input:focus {
  z-index: 1;
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-select-focused {
  z-index: 1;
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-select > .syngenta-ant-select-arrow {
  z-index: 1;
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact > *:first-child,
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-select:first-child > .syngenta-ant-select-selector,
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-select-auto-complete:first-child .syngenta-ant-input,
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-cascader-picker:first-child .syngenta-ant-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact > *:last-child,
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-select:last-child > .syngenta-ant-select-selector,
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-cascader-picker:last-child .syngenta-ant-input,
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-cascader-picker-focused:last-child .syngenta-ant-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-select-auto-complete .syngenta-ant-input {
  vertical-align: top;
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact .syngenta-ant-input-group-wrapper + .syngenta-ant-input-group-wrapper {
  margin-left: -1px;
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact .syngenta-ant-input-group-wrapper + .syngenta-ant-input-group-wrapper .syngenta-ant-input-affix-wrapper {
  border-radius: 0;
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact .syngenta-ant-input-group-wrapper:not(:last-child).syngenta-ant-input-search > .syngenta-ant-input-group > .syngenta-ant-input-group-addon > .syngenta-ant-input-search-button {
  border-radius: 0;
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact .syngenta-ant-input-group-wrapper:not(:last-child).syngenta-ant-input-search > .syngenta-ant-input-group > .syngenta-ant-input {
  border-radius: 2px 0 0 2px;
}
.syngenta-ant-input-group > .syngenta-ant-input-rtl:first-child,
.syngenta-ant-input-group-rtl .syngenta-ant-input-group-addon:first-child {
  border-radius: 0 2px 2px 0;
}
.syngenta-ant-input-group-rtl .syngenta-ant-input-group-addon:first-child {
  border-right: 1px solid #e8eaed;
  border-left: 0;
}
.syngenta-ant-input-group-rtl .syngenta-ant-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #e8eaed;
  border-radius: 2px 0 0 2px;
}
.syngenta-ant-input-group-rtl.syngenta-ant-input-group > .syngenta-ant-input:last-child,
.syngenta-ant-input-group-rtl.syngenta-ant-input-group-addon:last-child {
  border-radius: 2px 0 0 2px;
}
.syngenta-ant-input-group-rtl.syngenta-ant-input-group .syngenta-ant-input-affix-wrapper:not(:first-child) {
  border-radius: 2px 0 0 2px;
}
.syngenta-ant-input-group-rtl.syngenta-ant-input-group .syngenta-ant-input-affix-wrapper:not(:last-child) {
  border-radius: 0 2px 2px 0;
}
.syngenta-ant-input-group-rtl.syngenta-ant-input-group.syngenta-ant-input-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}
.syngenta-ant-input-group-rtl.syngenta-ant-input-group.syngenta-ant-input-group-compact > *:first-child,
.syngenta-ant-input-group-rtl.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-select:first-child > .syngenta-ant-select-selector,
.syngenta-ant-input-group-rtl.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-select-auto-complete:first-child .syngenta-ant-input,
.syngenta-ant-input-group-rtl.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-cascader-picker:first-child .syngenta-ant-input {
  border-radius: 0 2px 2px 0;
}
.syngenta-ant-input-group-rtl.syngenta-ant-input-group.syngenta-ant-input-group-compact > *:last-child,
.syngenta-ant-input-group-rtl.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-select:last-child > .syngenta-ant-select-selector,
.syngenta-ant-input-group-rtl.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-select-auto-complete:last-child .syngenta-ant-input,
.syngenta-ant-input-group-rtl.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-cascader-picker:last-child .syngenta-ant-input,
.syngenta-ant-input-group-rtl.syngenta-ant-input-group.syngenta-ant-input-group-compact > .syngenta-ant-cascader-picker-focused:last-child .syngenta-ant-input {
  border-left-width: 1px;
  border-radius: 2px 0 0 2px;
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact .syngenta-ant-input-group-wrapper-rtl + .syngenta-ant-input-group-wrapper-rtl {
  margin-right: -1px;
  margin-left: 0;
}
.syngenta-ant-input-group.syngenta-ant-input-group-compact .syngenta-ant-input-group-wrapper-rtl:not(:last-child).syngenta-ant-input-search > .syngenta-ant-input-group > .syngenta-ant-input {
  border-radius: 0 2px 2px 0;
}
.syngenta-ant-input-group > .syngenta-ant-input-rtl:first-child {
  border-radius: 0 2px 2px 0;
}
.syngenta-ant-input-group > .syngenta-ant-input-rtl:last-child {
  border-radius: 2px 0 0 2px;
}
.syngenta-ant-input-group-rtl .syngenta-ant-input-group-addon:first-child {
  border-right: 1px solid #e8eaed;
  border-left: 0;
  border-radius: 0 2px 2px 0;
}
.syngenta-ant-input-group-rtl .syngenta-ant-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #e8eaed;
  border-radius: 2px 0 0 2px;
}
.syngenta-ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
.syngenta-ant-input-password-icon.anticon {
  color: #707374;
  cursor: pointer;
  transition: all 0.3s;
}
.syngenta-ant-input-password-icon.anticon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.syngenta-ant-input[type='color'] {
  height: 32px;
}
.syngenta-ant-input[type='color'].syngenta-ant-input-lg {
  height: 40px;
}
.syngenta-ant-input[type='color'].syngenta-ant-input-sm {
  height: 24px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.syngenta-ant-input-textarea-show-count > .syngenta-ant-input {
  height: 100%;
}
.syngenta-ant-input-textarea-show-count::after {
  float: right;
  color: #707374;
  white-space: nowrap;
  content: attr(data-count);
  pointer-events: none;
}
.syngenta-ant-input-textarea-show-count.syngenta-ant-input-textarea-in-form-item::after {
  margin-bottom: -22px;
}
.syngenta-ant-input-textarea-suffix {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: auto;
}
.syngenta-ant-input-compact-item:not(.syngenta-ant-input-compact-last-item):not(.syngenta-ant-input-compact-item-rtl) {
  margin-right: -1px;
}
.syngenta-ant-input-compact-item:not(.syngenta-ant-input-compact-last-item).syngenta-ant-input-compact-item-rtl {
  margin-left: -1px;
}
.syngenta-ant-input-compact-item:hover,
.syngenta-ant-input-compact-item:focus,
.syngenta-ant-input-compact-item:active {
  z-index: 2;
}
.syngenta-ant-input-compact-item[disabled] {
  z-index: 0;
}
.syngenta-ant-input-compact-item:not(.syngenta-ant-input-compact-first-item):not(.syngenta-ant-input-compact-last-item).syngenta-ant-input {
  border-radius: 0;
}
.syngenta-ant-input-compact-item.syngenta-ant-input.syngenta-ant-input-compact-first-item:not(.syngenta-ant-input-compact-last-item):not(.syngenta-ant-input-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syngenta-ant-input-compact-item.syngenta-ant-input.syngenta-ant-input-compact-last-item:not(.syngenta-ant-input-compact-first-item):not(.syngenta-ant-input-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syngenta-ant-input-compact-item.syngenta-ant-input.syngenta-ant-input-compact-item-rtl.syngenta-ant-input-compact-first-item:not(.syngenta-ant-input-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syngenta-ant-input-compact-item.syngenta-ant-input.syngenta-ant-input-compact-item-rtl.syngenta-ant-input-compact-last-item:not(.syngenta-ant-input-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syngenta-ant-input-search .syngenta-ant-input:hover,
.syngenta-ant-input-search .syngenta-ant-input:focus {
  border-color: #0d0d0d;
}
.syngenta-ant-input-search .syngenta-ant-input:hover + .syngenta-ant-input-group-addon .syngenta-ant-input-search-button:not(.syngenta-ant-btn-primary),
.syngenta-ant-input-search .syngenta-ant-input:focus + .syngenta-ant-input-group-addon .syngenta-ant-input-search-button:not(.syngenta-ant-btn-primary) {
  border-left-color: #0d0d0d;
}
.syngenta-ant-input-search .syngenta-ant-input-affix-wrapper {
  border-radius: 0;
}
.syngenta-ant-input-search .syngenta-ant-input-lg {
  line-height: 1.5713;
}
.syngenta-ant-input-search > .syngenta-ant-input-group > .syngenta-ant-input-group-addon:last-child {
  left: -1px;
  padding: 0;
  border: 0;
}
.syngenta-ant-input-search > .syngenta-ant-input-group > .syngenta-ant-input-group-addon:last-child .syngenta-ant-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0 2px 2px 0;
}
.syngenta-ant-input-search > .syngenta-ant-input-group > .syngenta-ant-input-group-addon:last-child .syngenta-ant-input-search-button:not(.syngenta-ant-btn-primary) {
  color: #707374;
}
.syngenta-ant-input-search > .syngenta-ant-input-group > .syngenta-ant-input-group-addon:last-child .syngenta-ant-input-search-button:not(.syngenta-ant-btn-primary).syngenta-ant-btn-loading::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.syngenta-ant-input-search-button {
  height: 32px;
}
.syngenta-ant-input-search-button:hover,
.syngenta-ant-input-search-button:focus {
  z-index: 1;
}
.syngenta-ant-input-search-large .syngenta-ant-input-search-button {
  height: 40px;
}
.syngenta-ant-input-search-small .syngenta-ant-input-search-button {
  height: 24px;
}
.syngenta-ant-input-search.syngenta-ant-input-compact-item:not(.syngenta-ant-input-compact-item-rtl):not(.syngenta-ant-input-compact-last-item) .syngenta-ant-input-group-addon .syngenta-ant-input-search-button {
  margin-right: -1px;
  border-radius: 0;
}
.syngenta-ant-input-search.syngenta-ant-input-compact-item:not(.syngenta-ant-input-compact-first-item) .syngenta-ant-input,
.syngenta-ant-input-search.syngenta-ant-input-compact-item:not(.syngenta-ant-input-compact-first-item) .syngenta-ant-input-affix-wrapper {
  border-radius: 0;
}
.syngenta-ant-input-search.syngenta-ant-input-compact-item > .syngenta-ant-input-group-addon .syngenta-ant-input-search-button:hover,
.syngenta-ant-input-search.syngenta-ant-input-compact-item > .syngenta-ant-input:hover,
.syngenta-ant-input-search.syngenta-ant-input-compact-item .syngenta-ant-input-affix-wrapper:hover,
.syngenta-ant-input-search.syngenta-ant-input-compact-item > .syngenta-ant-input-group-addon .syngenta-ant-input-search-button:focus,
.syngenta-ant-input-search.syngenta-ant-input-compact-item > .syngenta-ant-input:focus,
.syngenta-ant-input-search.syngenta-ant-input-compact-item .syngenta-ant-input-affix-wrapper:focus,
.syngenta-ant-input-search.syngenta-ant-input-compact-item > .syngenta-ant-input-group-addon .syngenta-ant-input-search-button:active,
.syngenta-ant-input-search.syngenta-ant-input-compact-item > .syngenta-ant-input:active,
.syngenta-ant-input-search.syngenta-ant-input-compact-item .syngenta-ant-input-affix-wrapper:active {
  z-index: 2;
}
.syngenta-ant-input-search.syngenta-ant-input-compact-item > .syngenta-ant-input-affix-wrapper-focused {
  z-index: 2;
}
.syngenta-ant-input-search.syngenta-ant-input-compact-item-rtl:not(.syngenta-ant-input-compact-last-item) .syngenta-ant-input-group-addon:last-child .syngenta-ant-input-search-button {
  margin-left: -1px;
  border-radius: 0;
}
.syngenta-ant-input-group-wrapper-rtl {
  direction: rtl;
}
.syngenta-ant-input-group-rtl {
  direction: rtl;
}
.syngenta-ant-input-affix-wrapper.syngenta-ant-input-affix-wrapper-rtl > input.syngenta-ant-input {
  border: none;
  outline: none;
}
.syngenta-ant-input-affix-wrapper-rtl .syngenta-ant-input-prefix {
  margin: 0 0 0 4px;
}
.syngenta-ant-input-affix-wrapper-rtl .syngenta-ant-input-suffix {
  margin: 0 4px 0 0;
}
.syngenta-ant-input-textarea-rtl {
  direction: rtl;
}
.syngenta-ant-input-textarea-rtl.syngenta-ant-input-textarea-show-count::after {
  text-align: left;
}
.syngenta-ant-input-affix-wrapper-rtl .syngenta-ant-input-clear-icon-has-suffix {
  margin-right: 0;
  margin-left: 4px;
}
.syngenta-ant-input-affix-wrapper-rtl .syngenta-ant-input-clear-icon {
  right: auto;
  left: 8px;
}
.syngenta-ant-input-search-rtl {
  direction: rtl;
}
.syngenta-ant-input-search-rtl .syngenta-ant-input:hover + .syngenta-ant-input-group-addon .syngenta-ant-input-search-button:not(.syngenta-ant-btn-primary),
.syngenta-ant-input-search-rtl .syngenta-ant-input:focus + .syngenta-ant-input-group-addon .syngenta-ant-input-search-button:not(.syngenta-ant-btn-primary) {
  border-left-color: #e8eaed;
}
.syngenta-ant-input-search-rtl .syngenta-ant-input:hover + .syngenta-ant-input-group-addon .syngenta-ant-input-search-button:not(.syngenta-ant-btn-primary):hover,
.syngenta-ant-input-search-rtl .syngenta-ant-input:focus + .syngenta-ant-input-group-addon .syngenta-ant-input-search-button:not(.syngenta-ant-btn-primary):hover {
  border-left-color: #0d0d0d;
}
.syngenta-ant-input-search-rtl > .syngenta-ant-input-group > .syngenta-ant-input-affix-wrapper:hover,
.syngenta-ant-input-search-rtl > .syngenta-ant-input-group > .syngenta-ant-input-affix-wrapper-focused {
  border-right-color: #0d0d0d;
}
.syngenta-ant-input-search-rtl > .syngenta-ant-input-group > .syngenta-ant-input-group-addon:last-child {
  right: -1px;
  left: auto;
}
.syngenta-ant-input-search-rtl > .syngenta-ant-input-group > .syngenta-ant-input-group-addon:last-child .syngenta-ant-input-search-button {
  border-radius: 2px 0 0 2px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .syngenta-ant-input {
    height: 32px;
  }
  .syngenta-ant-input-lg {
    height: 40px;
  }
  .syngenta-ant-input-sm {
    height: 24px;
  }
  .syngenta-ant-input-affix-wrapper > input.syngenta-ant-input {
    height: auto;
  }
}
.syngenta-ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #E5E5E5;
}
.syngenta-ant-layout,
.syngenta-ant-layout * {
  box-sizing: border-box;
}
.syngenta-ant-layout.syngenta-ant-layout-has-sider {
  flex-direction: row;
}
.syngenta-ant-layout.syngenta-ant-layout-has-sider > .syngenta-ant-layout,
.syngenta-ant-layout.syngenta-ant-layout-has-sider > .syngenta-ant-layout-content {
  width: 0;
}
.syngenta-ant-layout-header,
.syngenta-ant-layout-footer {
  flex: 0 0 auto;
}
.syngenta-ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: #2f3031;
  line-height: 64px;
  background: #2f3031;
}
.syngenta-ant-layout-footer {
  padding: 24px 50px;
  color: #2f3031;
  font-size: 14px;
  background: #E5E5E5;
}
.syngenta-ant-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
.syngenta-ant-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #2f3031;
  transition: all 0.2s;
}
.syngenta-ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.syngenta-ant-layout-sider-children .syngenta-ant-menu.syngenta-ant-menu-inline-collapsed {
  width: auto;
}
.syngenta-ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.syngenta-ant-layout-sider-right {
  order: 1;
}
.syngenta-ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}
.syngenta-ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.syngenta-ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #2f3031;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}
.syngenta-ant-layout-sider-zero-width-trigger::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: all 0.3s;
  content: '';
}
.syngenta-ant-layout-sider-zero-width-trigger:hover::after {
  background: rgba(255, 255, 255, 0.1);
}
.syngenta-ant-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 2px 0 0 2px;
}
.syngenta-ant-layout-sider-light {
  background: #fff;
}
.syngenta-ant-layout-sider-light .syngenta-ant-layout-sider-trigger {
  color: #2f3031;
  background: #fff;
}
.syngenta-ant-layout-sider-light .syngenta-ant-layout-sider-zero-width-trigger {
  color: #2f3031;
  background: #fff;
}
.syngenta-ant-layout-rtl {
  direction: rtl;
}
.syngenta-ant-table.syngenta-ant-table-middle {
  font-size: 14px;
}
.syngenta-ant-table.syngenta-ant-table-middle .syngenta-ant-table-title,
.syngenta-ant-table.syngenta-ant-table-middle .syngenta-ant-table-footer,
.syngenta-ant-table.syngenta-ant-table-middle .syngenta-ant-table-thead > tr > th,
.syngenta-ant-table.syngenta-ant-table-middle .syngenta-ant-table-tbody > tr > td,
.syngenta-ant-table.syngenta-ant-table-middle tfoot > tr > th,
.syngenta-ant-table.syngenta-ant-table-middle tfoot > tr > td {
  padding: 12px 8px;
}
.syngenta-ant-table.syngenta-ant-table-middle .syngenta-ant-table-filter-trigger {
  margin-right: -4px;
}
.syngenta-ant-table.syngenta-ant-table-middle .syngenta-ant-table-expanded-row-fixed {
  margin: -12px -8px;
}
.syngenta-ant-table.syngenta-ant-table-middle .syngenta-ant-table-tbody .syngenta-ant-table-wrapper:only-child .syngenta-ant-table {
  margin: -12px -8px -12px 40px;
}
.syngenta-ant-table.syngenta-ant-table-middle .syngenta-ant-table-selection-column {
  padding-inline-start: 2px;
}
.syngenta-ant-table.syngenta-ant-table-small {
  font-size: 14px;
}
.syngenta-ant-table.syngenta-ant-table-small .syngenta-ant-table-title,
.syngenta-ant-table.syngenta-ant-table-small .syngenta-ant-table-footer,
.syngenta-ant-table.syngenta-ant-table-small .syngenta-ant-table-thead > tr > th,
.syngenta-ant-table.syngenta-ant-table-small .syngenta-ant-table-tbody > tr > td,
.syngenta-ant-table.syngenta-ant-table-small tfoot > tr > th,
.syngenta-ant-table.syngenta-ant-table-small tfoot > tr > td {
  padding: 8px 8px;
}
.syngenta-ant-table.syngenta-ant-table-small .syngenta-ant-table-filter-trigger {
  margin-right: -4px;
}
.syngenta-ant-table.syngenta-ant-table-small .syngenta-ant-table-expanded-row-fixed {
  margin: -8px -8px;
}
.syngenta-ant-table.syngenta-ant-table-small .syngenta-ant-table-tbody .syngenta-ant-table-wrapper:only-child .syngenta-ant-table {
  margin: -8px -8px -8px 40px;
}
.syngenta-ant-table.syngenta-ant-table-small .syngenta-ant-table-selection-column {
  padding-inline-start: 2px;
}
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-title {
  border: 1px solid #f0f0f0;
  border-bottom: 0;
}
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container {
  border-left: 1px solid #f0f0f0;
}
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-content > table > thead > tr > th,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-header > table > thead > tr > th,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-body > table > thead > tr > th,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-summary > table > thead > tr > th,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-content > table > tbody > tr > td,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-header > table > tbody > tr > td,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-body > table > tbody > tr > td,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-summary > table > tbody > tr > td,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-content > table > tfoot > tr > th,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-header > table > tfoot > tr > th,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-body > table > tfoot > tr > th,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-summary > table > tfoot > tr > th,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-content > table > tfoot > tr > td,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-header > table > tfoot > tr > td,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-body > table > tfoot > tr > td,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-summary > table > tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-content > table > thead > tr:not(:last-child) > th,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-header > table > thead > tr:not(:last-child) > th,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-body > table > thead > tr:not(:last-child) > th,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-summary > table > thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #f0f0f0;
}
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-content > table > thead > tr > th::before,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-header > table > thead > tr > th::before,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-body > table > thead > tr > th::before,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-summary > table > thead > tr > th::before {
  background-color: transparent !important;
}
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-content > table > thead > tr > .syngenta-ant-table-cell-fix-right-first::after,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-header > table > thead > tr > .syngenta-ant-table-cell-fix-right-first::after,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-body > table > thead > tr > .syngenta-ant-table-cell-fix-right-first::after,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-summary > table > thead > tr > .syngenta-ant-table-cell-fix-right-first::after,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-content > table > tbody > tr > .syngenta-ant-table-cell-fix-right-first::after,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-header > table > tbody > tr > .syngenta-ant-table-cell-fix-right-first::after,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-body > table > tbody > tr > .syngenta-ant-table-cell-fix-right-first::after,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-summary > table > tbody > tr > .syngenta-ant-table-cell-fix-right-first::after,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-content > table > tfoot > tr > .syngenta-ant-table-cell-fix-right-first::after,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-header > table > tfoot > tr > .syngenta-ant-table-cell-fix-right-first::after,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-body > table > tfoot > tr > .syngenta-ant-table-cell-fix-right-first::after,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-summary > table > tfoot > tr > .syngenta-ant-table-cell-fix-right-first::after {
  border-right: 1px solid #f0f0f0;
}
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-content > table > tbody > tr > td > .syngenta-ant-table-expanded-row-fixed,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-header > table > tbody > tr > td > .syngenta-ant-table-expanded-row-fixed,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-body > table > tbody > tr > td > .syngenta-ant-table-expanded-row-fixed,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-summary > table > tbody > tr > td > .syngenta-ant-table-expanded-row-fixed {
  margin: -16px -17px;
}
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-content > table > tbody > tr > td > .syngenta-ant-table-expanded-row-fixed::after,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-header > table > tbody > tr > td > .syngenta-ant-table-expanded-row-fixed::after,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-body > table > tbody > tr > td > .syngenta-ant-table-expanded-row-fixed::after,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-summary > table > tbody > tr > td > .syngenta-ant-table-expanded-row-fixed::after {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  border-right: 1px solid #f0f0f0;
  content: '';
}
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-content > table,
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-container > .syngenta-ant-table-header > table {
  border-top: 1px solid #f0f0f0;
}
.syngenta-ant-table.syngenta-ant-table-bordered.syngenta-ant-table-scroll-horizontal > .syngenta-ant-table-container > .syngenta-ant-table-body > table > tbody > tr.syngenta-ant-table-expanded-row > td,
.syngenta-ant-table.syngenta-ant-table-bordered.syngenta-ant-table-scroll-horizontal > .syngenta-ant-table-container > .syngenta-ant-table-body > table > tbody > tr.syngenta-ant-table-placeholder > td {
  border-right: 0;
}
.syngenta-ant-table.syngenta-ant-table-bordered.syngenta-ant-table-middle > .syngenta-ant-table-container > .syngenta-ant-table-content > table > tbody > tr > td > .syngenta-ant-table-expanded-row-fixed,
.syngenta-ant-table.syngenta-ant-table-bordered.syngenta-ant-table-middle > .syngenta-ant-table-container > .syngenta-ant-table-body > table > tbody > tr > td > .syngenta-ant-table-expanded-row-fixed {
  margin: -12px -9px;
}
.syngenta-ant-table.syngenta-ant-table-bordered.syngenta-ant-table-small > .syngenta-ant-table-container > .syngenta-ant-table-content > table > tbody > tr > td > .syngenta-ant-table-expanded-row-fixed,
.syngenta-ant-table.syngenta-ant-table-bordered.syngenta-ant-table-small > .syngenta-ant-table-container > .syngenta-ant-table-body > table > tbody > tr > td > .syngenta-ant-table-expanded-row-fixed {
  margin: -8px -9px;
}
.syngenta-ant-table.syngenta-ant-table-bordered > .syngenta-ant-table-footer {
  border: 1px solid #f0f0f0;
  border-top: 0;
}
.syngenta-ant-table-cell .syngenta-ant-table-container:first-child {
  border-top: 0;
}
.syngenta-ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: 0 1px 0 1px #fafafa;
}
.syngenta-ant-table-wrapper {
  clear: both;
  max-width: 100%;
}
.syngenta-ant-table-wrapper::before {
  display: table;
  content: '';
}
.syngenta-ant-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-table-wrapper::before {
  display: table;
  content: '';
}
.syngenta-ant-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  font-size: 14px;
  background: #fff;
  border-radius: 2px;
}
.syngenta-ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.syngenta-ant-table-thead > tr > th,
.syngenta-ant-table-tbody > tr > td,
.syngenta-ant-table tfoot > tr > th,
.syngenta-ant-table tfoot > tr > td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
}
.syngenta-ant-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.syngenta-ant-table-cell-ellipsis.syngenta-ant-table-cell-fix-left-last,
.syngenta-ant-table-cell-ellipsis.syngenta-ant-table-cell-fix-right-first {
  overflow: visible;
}
.syngenta-ant-table-cell-ellipsis.syngenta-ant-table-cell-fix-left-last .syngenta-ant-table-cell-content,
.syngenta-ant-table-cell-ellipsis.syngenta-ant-table-cell-fix-right-first .syngenta-ant-table-cell-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.syngenta-ant-table-cell-ellipsis .syngenta-ant-table-column-title {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.syngenta-ant-table-title {
  padding: 16px 16px;
}
.syngenta-ant-table-footer {
  padding: 16px 16px;
  color: #707374;
  background: #fafafa;
}
.syngenta-ant-table-thead > tr > th {
  position: relative;
  color: #707374;
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}
.syngenta-ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.syngenta-ant-table-thead > tr > th:not(:last-child):not(.syngenta-ant-table-selection-column):not(.syngenta-ant-table-row-expand-icon-cell):not([colspan])::before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.6em;
  background-color: rgba(0, 0, 0, 0.06);
  transform: translateY(-50%);
  transition: background-color 0.3s;
  content: '';
}
.syngenta-ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.syngenta-ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s;
}
.syngenta-ant-table-tbody > tr > td > .syngenta-ant-table-wrapper:only-child .syngenta-ant-table,
.syngenta-ant-table-tbody > tr > td > .syngenta-ant-table-expanded-row-fixed > .syngenta-ant-table-wrapper:only-child .syngenta-ant-table {
  margin: -16px -16px -16px 32px;
}
.syngenta-ant-table-tbody > tr > td > .syngenta-ant-table-wrapper:only-child .syngenta-ant-table-tbody > tr:last-child > td,
.syngenta-ant-table-tbody > tr > td > .syngenta-ant-table-expanded-row-fixed > .syngenta-ant-table-wrapper:only-child .syngenta-ant-table-tbody > tr:last-child > td {
  border-bottom: 0;
}
.syngenta-ant-table-tbody > tr > td > .syngenta-ant-table-wrapper:only-child .syngenta-ant-table-tbody > tr:last-child > td:first-child,
.syngenta-ant-table-tbody > tr > td > .syngenta-ant-table-expanded-row-fixed > .syngenta-ant-table-wrapper:only-child .syngenta-ant-table-tbody > tr:last-child > td:first-child,
.syngenta-ant-table-tbody > tr > td > .syngenta-ant-table-wrapper:only-child .syngenta-ant-table-tbody > tr:last-child > td:last-child,
.syngenta-ant-table-tbody > tr > td > .syngenta-ant-table-expanded-row-fixed > .syngenta-ant-table-wrapper:only-child .syngenta-ant-table-tbody > tr:last-child > td:last-child {
  border-radius: 0;
}
.syngenta-ant-table-tbody > tr.syngenta-ant-table-row:hover > td,
.syngenta-ant-table-tbody > tr > td.syngenta-ant-table-cell-row-hover {
  background: #fafafa;
}
.syngenta-ant-table-tbody > tr.syngenta-ant-table-row-selected > td {
  background: #404040;
  border-color: rgba(0, 0, 0, 0.03);
}
.syngenta-ant-table-tbody > tr.syngenta-ant-table-row-selected:hover > td {
  background: #3b3b3b;
}
.syngenta-ant-table-summary {
  position: relative;
  z-index: 2;
  background: #fff;
}
div.syngenta-ant-table-summary {
  box-shadow: 0 -1px 0 #f0f0f0;
}
.syngenta-ant-table-summary > tr > th,
.syngenta-ant-table-summary > tr > td {
  border-bottom: 1px solid #f0f0f0;
}
.syngenta-ant-table-pagination.syngenta-ant-pagination {
  margin: 16px 0;
}
.syngenta-ant-table-pagination {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
}
.syngenta-ant-table-pagination > * {
  flex: none;
}
.syngenta-ant-table-pagination-left {
  justify-content: flex-start;
}
.syngenta-ant-table-pagination-center {
  justify-content: center;
}
.syngenta-ant-table-pagination-right {
  justify-content: flex-end;
}
.syngenta-ant-table-thead th.syngenta-ant-table-column-has-sorters {
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}
.syngenta-ant-table-thead th.syngenta-ant-table-column-has-sorters:hover {
  background: rgba(0, 0, 0, 0.04);
}
.syngenta-ant-table-thead th.syngenta-ant-table-column-has-sorters:hover::before {
  background-color: transparent !important;
}
.syngenta-ant-table-thead th.syngenta-ant-table-column-has-sorters:focus-visible {
  color: #000;
}
.syngenta-ant-table-thead th.syngenta-ant-table-column-has-sorters.syngenta-ant-table-cell-fix-left:hover,
.syngenta-ant-table-thead th.syngenta-ant-table-column-has-sorters.syngenta-ant-table-cell-fix-right:hover {
  background: #f5f5f5;
}
.syngenta-ant-table-thead th.syngenta-ant-table-column-sort {
  background: #f5f5f5;
}
.syngenta-ant-table-thead th.syngenta-ant-table-column-sort::before {
  background-color: transparent !important;
}
td.syngenta-ant-table-column-sort {
  background: #fafafa;
}
.syngenta-ant-table-column-title {
  position: relative;
  z-index: 1;
  flex: 1;
}
.syngenta-ant-table-column-sorters {
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: space-between;
}
.syngenta-ant-table-column-sorters::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}
.syngenta-ant-table-column-sorter {
  margin-left: 4px;
  color: #bfbfbf;
  font-size: 0;
  transition: color 0.3s;
}
.syngenta-ant-table-column-sorter-inner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.syngenta-ant-table-column-sorter-up,
.syngenta-ant-table-column-sorter-down {
  font-size: 11px;
}
.syngenta-ant-table-column-sorter-up.active,
.syngenta-ant-table-column-sorter-down.active {
  color: #000;
}
.syngenta-ant-table-column-sorter-up + .syngenta-ant-table-column-sorter-down {
  margin-top: -0.3em;
}
.syngenta-ant-table-column-sorters:hover .syngenta-ant-table-column-sorter {
  color: #a6a6a6;
}
.syngenta-ant-table-filter-column {
  display: flex;
  justify-content: space-between;
}
.syngenta-ant-table-filter-trigger {
  position: relative;
  display: flex;
  align-items: center;
  margin: -4px -8px -4px 4px;
  padding: 0 4px;
  color: #bfbfbf;
  font-size: 12px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.syngenta-ant-table-filter-trigger:hover {
  color: #707374;
  background: rgba(0, 0, 0, 0.04);
}
.syngenta-ant-table-filter-trigger.active {
  color: #000;
}
.syngenta-ant-table-filter-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  min-width: 120px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.syngenta-ant-table-filter-dropdown .syngenta-ant-dropdown-menu {
  max-height: 264px;
  overflow-x: hidden;
  border: 0;
  box-shadow: none;
}
.syngenta-ant-table-filter-dropdown .syngenta-ant-dropdown-menu:empty::after {
  display: block;
  padding: 8px 0;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  text-align: center;
  content: 'Not Found';
}
.syngenta-ant-table-filter-dropdown-tree {
  padding: 8px 8px 0;
}
.syngenta-ant-table-filter-dropdown-tree .syngenta-ant-tree-treenode .syngenta-ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.syngenta-ant-table-filter-dropdown-tree .syngenta-ant-tree-treenode-checkbox-checked .syngenta-ant-tree-node-content-wrapper,
.syngenta-ant-table-filter-dropdown-tree .syngenta-ant-tree-treenode-checkbox-checked .syngenta-ant-tree-node-content-wrapper:hover {
  background-color: #333333;
}
.syngenta-ant-table-filter-dropdown-search {
  padding: 8px;
  border-bottom: 1px #f0f0f0 solid;
}
.syngenta-ant-table-filter-dropdown-search-input input {
  min-width: 140px;
}
.syngenta-ant-table-filter-dropdown-search-input .anticon {
  color: rgba(0, 0, 0, 0.25);
}
.syngenta-ant-table-filter-dropdown-checkall {
  width: 100%;
  margin-bottom: 4px;
  margin-left: 4px;
}
.syngenta-ant-table-filter-dropdown-submenu > ul {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  overflow-y: auto;
}
.syngenta-ant-table-filter-dropdown .syngenta-ant-checkbox-wrapper + span,
.syngenta-ant-table-filter-dropdown-submenu .syngenta-ant-checkbox-wrapper + span {
  padding-left: 8px;
}
.syngenta-ant-table-filter-dropdown-btns {
  display: flex;
  justify-content: space-between;
  padding: 7px 8px;
  overflow: hidden;
  background-color: inherit;
  border-top: 1px solid #f0f0f0;
}
.syngenta-ant-table-selection-col {
  width: 32px;
}
.syngenta-ant-table-bordered .syngenta-ant-table-selection-col {
  width: 50px;
}
table tr th.syngenta-ant-table-selection-column,
table tr td.syngenta-ant-table-selection-column {
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
}
table tr th.syngenta-ant-table-selection-column .syngenta-ant-radio-wrapper,
table tr td.syngenta-ant-table-selection-column .syngenta-ant-radio-wrapper {
  margin-right: 0;
}
table tr th.syngenta-ant-table-selection-column.syngenta-ant-table-cell-fix-left {
  z-index: 3;
}
table tr th.syngenta-ant-table-selection-column::after {
  background-color: transparent !important;
}
.syngenta-ant-table-selection {
  position: relative;
  display: inline-flex;
  flex-direction: column;
}
.syngenta-ant-table-selection-extra {
  position: absolute;
  top: 0;
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s;
  margin-inline-start: 100%;
  padding-inline-start: 4px;
}
.syngenta-ant-table-selection-extra .anticon {
  color: #bfbfbf;
  font-size: 10px;
}
.syngenta-ant-table-selection-extra .anticon:hover {
  color: #a6a6a6;
}
.syngenta-ant-table-expand-icon-col {
  width: 48px;
}
.syngenta-ant-table-row-expand-icon-cell {
  text-align: center;
}
.syngenta-ant-table-row-expand-icon-cell .syngenta-ant-table-row-expand-icon {
  display: inline-flex;
  float: none;
  vertical-align: sub;
}
.syngenta-ant-table-row-indent {
  float: left;
  height: 1px;
}
.syngenta-ant-table-row-expand-icon {
  color: #000;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  float: left;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  padding: 0;
  color: inherit;
  line-height: 17px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  transform: scale(0.94117647);
  transition: all 0.3s;
  user-select: none;
}
.syngenta-ant-table-row-expand-icon:focus-visible,
.syngenta-ant-table-row-expand-icon:hover {
  color: #0d0d0d;
}
.syngenta-ant-table-row-expand-icon:active {
  color: #000000;
}
.syngenta-ant-table-row-expand-icon:focus,
.syngenta-ant-table-row-expand-icon:hover,
.syngenta-ant-table-row-expand-icon:active {
  border-color: currentcolor;
}
.syngenta-ant-table-row-expand-icon::before,
.syngenta-ant-table-row-expand-icon::after {
  position: absolute;
  background: currentcolor;
  transition: transform 0.3s ease-out;
  content: '';
}
.syngenta-ant-table-row-expand-icon::before {
  top: 7px;
  right: 3px;
  left: 3px;
  height: 1px;
}
.syngenta-ant-table-row-expand-icon::after {
  top: 3px;
  bottom: 3px;
  left: 7px;
  width: 1px;
  transform: rotate(90deg);
}
.syngenta-ant-table-row-expand-icon-collapsed::before {
  transform: rotate(-180deg);
}
.syngenta-ant-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.syngenta-ant-table-row-expand-icon-spaced {
  background: transparent;
  border: 0;
  visibility: hidden;
}
.syngenta-ant-table-row-expand-icon-spaced::before,
.syngenta-ant-table-row-expand-icon-spaced::after {
  display: none;
  content: none;
}
.syngenta-ant-table-row-indent + .syngenta-ant-table-row-expand-icon {
  margin-top: 2.5005px;
  margin-right: 8px;
}
tr.syngenta-ant-table-expanded-row > td,
tr.syngenta-ant-table-expanded-row:hover > td {
  background: #fbfbfb;
}
tr.syngenta-ant-table-expanded-row .syngenta-ant-descriptions-view {
  display: flex;
}
tr.syngenta-ant-table-expanded-row .syngenta-ant-descriptions-view table {
  flex: auto;
  width: auto;
}
.syngenta-ant-table .syngenta-ant-table-expanded-row-fixed {
  position: relative;
  margin: -16px -16px;
  padding: 16px 16px;
}
.syngenta-ant-table-tbody > tr.syngenta-ant-table-placeholder {
  text-align: center;
}
.syngenta-ant-table-empty .syngenta-ant-table-tbody > tr.syngenta-ant-table-placeholder {
  color: rgba(0, 0, 0, 0.25);
}
.syngenta-ant-table-tbody > tr.syngenta-ant-table-placeholder:hover > td {
  background: #fff;
}
.syngenta-ant-table-cell-fix-left,
.syngenta-ant-table-cell-fix-right {
  position: sticky !important;
  z-index: 2;
  background: #fff;
}
.syngenta-ant-table-cell-fix-left-first::after,
.syngenta-ant-table-cell-fix-left-last::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  transform: translateX(100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.syngenta-ant-table-cell-fix-left-all::after {
  display: none;
}
.syngenta-ant-table-cell-fix-right-first::after,
.syngenta-ant-table-cell-fix-right-last::after {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  width: 30px;
  transform: translateX(-100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.syngenta-ant-table .syngenta-ant-table-container::before,
.syngenta-ant-table .syngenta-ant-table-container::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: calc(calc(2 + 1) + 1);
  width: 30px;
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.syngenta-ant-table .syngenta-ant-table-container::before {
  left: 0;
}
.syngenta-ant-table .syngenta-ant-table-container::after {
  right: 0;
}
.syngenta-ant-table-ping-left:not(.syngenta-ant-table-has-fix-left) > .syngenta-ant-table-container {
  position: relative;
}
.syngenta-ant-table-ping-left:not(.syngenta-ant-table-has-fix-left) > .syngenta-ant-table-container::before {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.syngenta-ant-table-ping-left .syngenta-ant-table-cell-fix-left-first::after,
.syngenta-ant-table-ping-left .syngenta-ant-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.syngenta-ant-table-ping-left .syngenta-ant-table-cell-fix-left-last::before {
  background-color: transparent !important;
}
.syngenta-ant-table-ping-right:not(.syngenta-ant-table-has-fix-right) > .syngenta-ant-table-container {
  position: relative;
}
.syngenta-ant-table-ping-right:not(.syngenta-ant-table-has-fix-right) > .syngenta-ant-table-container::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.syngenta-ant-table-ping-right .syngenta-ant-table-cell-fix-right-first::after,
.syngenta-ant-table-ping-right .syngenta-ant-table-cell-fix-right-last::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.syngenta-ant-table-sticky-holder {
  position: sticky;
  z-index: calc(2 + 1);
  background: #fff;
}
.syngenta-ant-table-sticky-scroll {
  position: sticky;
  bottom: 0;
  z-index: calc(2 + 1);
  display: flex;
  align-items: center;
  background: #ffffff;
  border-top: 1px solid #f0f0f0;
  opacity: 0.6;
}
.syngenta-ant-table-sticky-scroll:hover {
  transform-origin: center bottom;
}
.syngenta-ant-table-sticky-scroll-bar {
  height: 8px;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}
.syngenta-ant-table-sticky-scroll-bar:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.syngenta-ant-table-sticky-scroll-bar-active {
  background-color: rgba(0, 0, 0, 0.8);
}
@media all and (-ms-high-contrast: none) {
  .syngenta-ant-table-ping-left .syngenta-ant-table-cell-fix-left-last::after {
    box-shadow: none !important;
  }
  .syngenta-ant-table-ping-right .syngenta-ant-table-cell-fix-right-first::after {
    box-shadow: none !important;
  }
}
.syngenta-ant-table {
  /* title + table */
  /* table */
  /* table + footer */
}
.syngenta-ant-table-title {
  border-radius: 2px 2px 0 0;
}
.syngenta-ant-table-title + .syngenta-ant-table-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.syngenta-ant-table-title + .syngenta-ant-table-container table {
  border-radius: 0;
}
.syngenta-ant-table-title + .syngenta-ant-table-container table > thead > tr:first-child th:first-child {
  border-radius: 0;
}
.syngenta-ant-table-title + .syngenta-ant-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0;
}
.syngenta-ant-table-container {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.syngenta-ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 2px;
}
.syngenta-ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 2px;
}
.syngenta-ant-table-footer {
  border-radius: 0 0 2px 2px;
}
.syngenta-ant-table-wrapper-rtl {
  direction: rtl;
}
.syngenta-ant-table-rtl {
  direction: rtl;
}
.syngenta-ant-table-wrapper-rtl .syngenta-ant-table table {
  text-align: right;
}
.syngenta-ant-table-wrapper-rtl .syngenta-ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.syngenta-ant-table-wrapper-rtl .syngenta-ant-table-thead > tr > th:not(:last-child):not(.syngenta-ant-table-selection-column):not(.syngenta-ant-table-row-expand-icon-cell):not([colspan])::before {
  right: auto;
  left: 0;
}
.syngenta-ant-table-wrapper-rtl .syngenta-ant-table-thead > tr > th {
  text-align: right;
}
.syngenta-ant-table-tbody > tr .syngenta-ant-table-wrapper:only-child .syngenta-ant-table.syngenta-ant-table-rtl {
  margin: -16px 33px -16px -16px;
}
.syngenta-ant-table-wrapper.syngenta-ant-table-wrapper-rtl .syngenta-ant-table-pagination-left {
  justify-content: flex-end;
}
.syngenta-ant-table-wrapper.syngenta-ant-table-wrapper-rtl .syngenta-ant-table-pagination-right {
  justify-content: flex-start;
}
.syngenta-ant-table-wrapper-rtl .syngenta-ant-table-column-sorter {
  margin-right: 4px;
  margin-left: 0;
}
.syngenta-ant-table-wrapper-rtl .syngenta-ant-table-filter-column-title {
  padding: 16px 16px 16px 2.3em;
}
.syngenta-ant-table-rtl .syngenta-ant-table-thead tr th.syngenta-ant-table-column-has-sorters .syngenta-ant-table-filter-column-title {
  padding: 0 0 0 2.3em;
}
.syngenta-ant-table-wrapper-rtl .syngenta-ant-table-filter-trigger {
  margin: -4px 4px -4px -8px;
}
.syngenta-ant-dropdown-rtl .syngenta-ant-table-filter-dropdown .syngenta-ant-checkbox-wrapper + span,
.syngenta-ant-dropdown-rtl .syngenta-ant-table-filter-dropdown-submenu .syngenta-ant-checkbox-wrapper + span,
.syngenta-ant-dropdown-menu-submenu-rtl.syngenta-ant-table-filter-dropdown .syngenta-ant-checkbox-wrapper + span,
.syngenta-ant-dropdown-menu-submenu-rtl.syngenta-ant-table-filter-dropdown-submenu .syngenta-ant-checkbox-wrapper + span {
  padding-right: 8px;
  padding-left: 0;
}
.syngenta-ant-table-wrapper-rtl .syngenta-ant-table-selection {
  text-align: center;
}
.syngenta-ant-table-wrapper-rtl .syngenta-ant-table-row-indent {
  float: right;
}
.syngenta-ant-table-wrapper-rtl .syngenta-ant-table-row-expand-icon {
  float: right;
}
.syngenta-ant-table-wrapper-rtl .syngenta-ant-table-row-indent + .syngenta-ant-table-row-expand-icon {
  margin-right: 0;
  margin-left: 8px;
}
.syngenta-ant-table-wrapper-rtl .syngenta-ant-table-row-expand-icon::after {
  transform: rotate(-90deg);
}
.syngenta-ant-table-wrapper-rtl .syngenta-ant-table-row-expand-icon-collapsed::before {
  transform: rotate(180deg);
}
.syngenta-ant-table-wrapper-rtl .syngenta-ant-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.syngenta-ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  outline: 0;
}
.syngenta-ant-upload p {
  margin: 0;
}
.syngenta-ant-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}
.syngenta-ant-upload input[type='file'] {
  cursor: pointer;
}
.syngenta-ant-upload.syngenta-ant-upload-select {
  display: inline-block;
}
.syngenta-ant-upload.syngenta-ant-upload-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.syngenta-ant-upload.syngenta-ant-upload-select-picture-card {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #e8eaed;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.syngenta-ant-upload.syngenta-ant-upload-select-picture-card > .syngenta-ant-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
.syngenta-ant-upload.syngenta-ant-upload-select-picture-card:hover {
  border-color: #000;
}
.syngenta-ant-upload-disabled.syngenta-ant-upload.syngenta-ant-upload-select-picture-card:hover {
  border-color: #e8eaed;
}
.syngenta-ant-upload.syngenta-ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #e8eaed;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.syngenta-ant-upload.syngenta-ant-upload-drag .syngenta-ant-upload {
  padding: 16px 0;
}
.syngenta-ant-upload.syngenta-ant-upload-drag.syngenta-ant-upload-drag-hover:not(.syngenta-ant-upload-disabled) {
  border-color: #000000;
}
.syngenta-ant-upload.syngenta-ant-upload-drag.syngenta-ant-upload-disabled {
  cursor: not-allowed;
}
.syngenta-ant-upload.syngenta-ant-upload-drag .syngenta-ant-upload-btn {
  display: table;
  height: 100%;
}
.syngenta-ant-upload.syngenta-ant-upload-drag .syngenta-ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.syngenta-ant-upload.syngenta-ant-upload-drag:not(.syngenta-ant-upload-disabled):hover {
  border-color: #0d0d0d;
}
.syngenta-ant-upload.syngenta-ant-upload-drag p.syngenta-ant-upload-drag-icon {
  margin-bottom: 20px;
}
.syngenta-ant-upload.syngenta-ant-upload-drag p.syngenta-ant-upload-drag-icon .anticon {
  color: #0d0d0d;
  font-size: 48px;
}
.syngenta-ant-upload.syngenta-ant-upload-drag p.syngenta-ant-upload-text {
  margin: 0 0 4px;
  color: #707374;
  font-size: 16px;
}
.syngenta-ant-upload.syngenta-ant-upload-drag p.syngenta-ant-upload-hint {
  color: #707374;
  font-size: 14px;
}
.syngenta-ant-upload.syngenta-ant-upload-drag .anticon-plus {
  color: rgba(0, 0, 0, 0.25);
  font-size: 30px;
  transition: all 0.3s;
}
.syngenta-ant-upload.syngenta-ant-upload-drag .anticon-plus:hover {
  color: #707374;
}
.syngenta-ant-upload.syngenta-ant-upload-drag:hover .anticon-plus {
  color: #707374;
}
.syngenta-ant-upload-picture-card-wrapper {
  display: inline-block;
  width: 100%;
}
.syngenta-ant-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.syngenta-ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.syngenta-ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  line-height: 1.5715;
}
.syngenta-ant-upload-list::before {
  display: table;
  content: '';
}
.syngenta-ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-upload-list::before {
  display: table;
  content: '';
}
.syngenta-ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-upload-list-item {
  position: relative;
  height: 22.001px;
  margin-top: 8px;
  font-size: 14px;
}
.syngenta-ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syngenta-ant-upload-list-item-card-actions {
  position: absolute;
  right: 0;
}
.syngenta-ant-upload-list-item-card-actions-btn {
  opacity: 0;
}
.syngenta-ant-upload-list-item-card-actions-btn.syngenta-ant-btn-sm {
  height: 22.001px;
  line-height: 1;
  vertical-align: top;
}
.syngenta-ant-upload-list-item-card-actions.picture {
  top: 22px;
  line-height: 0;
}
.syngenta-ant-upload-list-item-card-actions-btn:focus,
.syngenta-ant-upload-list-item-card-actions.picture .syngenta-ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.syngenta-ant-upload-list-item-card-actions .anticon {
  color: #707374;
  transition: all 0.3s;
}
.syngenta-ant-upload-list-item-card-actions:hover .anticon {
  color: #2f3031;
}
.syngenta-ant-upload-list-item-info {
  height: 100%;
  transition: background-color 0.3s;
}
.syngenta-ant-upload-list-item-info > span {
  display: block;
  width: 100%;
  height: 100%;
}
.syngenta-ant-upload-list-item-info .anticon-loading .anticon,
.syngenta-ant-upload-list-item-info .syngenta-ant-upload-text-icon .anticon {
  position: absolute;
  top: 5px;
  color: #707374;
  font-size: 14px;
}
.syngenta-ant-upload-list-item:hover .syngenta-ant-upload-list-item-info {
  background-color: #f5f5f5;
}
.syngenta-ant-upload-list-item:hover .syngenta-ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.syngenta-ant-upload-list-item-error,
.syngenta-ant-upload-list-item-error .syngenta-ant-upload-text-icon > .anticon,
.syngenta-ant-upload-list-item-error .syngenta-ant-upload-list-item-name {
  color: #ff4d4f;
}
.syngenta-ant-upload-list-item-error .syngenta-ant-upload-list-item-card-actions .anticon {
  color: #ff4d4f;
}
.syngenta-ant-upload-list-item-error .syngenta-ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.syngenta-ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}
.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #e8eaed;
  border-radius: 2px;
}
.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item:hover,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item:hover {
  background: transparent;
}
.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item-error,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-error {
  border-color: #ff4d4f;
}
.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item-info,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-info {
  padding: 0;
}
.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item:hover .syngenta-ant-upload-list-item-info,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item:hover .syngenta-ant-upload-list-item-info {
  background: transparent;
}
.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item-uploading,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-uploading {
  border-style: dashed;
}
.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item-thumbnail,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-thumbnail {
  width: 48px;
  height: 48px;
  line-height: 60px;
  text-align: center;
  opacity: 0.8;
}
.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item-thumbnail .anticon,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-thumbnail .anticon {
  font-size: 26px;
}
.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item-error .syngenta-ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-error .syngenta-ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
  fill: #fff2f0;
}
.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item-error .syngenta-ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-error .syngenta-ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
  fill: #ff4d4f;
}
.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item-icon,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  transform: translate(-50%, -50%);
}
.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item-icon .anticon,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-icon .anticon {
  font-size: 26px;
}
.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item-image,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-image {
  max-width: 100%;
}
.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item-thumbnail img,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item-name,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-name {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item-uploading .syngenta-ant-upload-list-item-name,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-uploading .syngenta-ant-upload-list-item-name {
  margin-bottom: 12px;
}
.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item-progress,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}
.syngenta-ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  vertical-align: top;
}
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item {
  height: 100%;
  margin: 0;
}
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: ' ';
}
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item:hover .syngenta-ant-upload-list-item-info::before {
  opacity: 1;
}
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-actions .anticon-eye,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-actions .anticon-download,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-actions .anticon-eye:hover,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-actions .anticon-download:hover,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-info:hover + .syngenta-ant-upload-list-item-actions,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-actions:hover {
  opacity: 1;
}
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-thumbnail,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5715;
  text-align: center;
}
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-file + .syngenta-ant-upload-list-item-name {
  position: absolute;
  bottom: 10px;
  display: block;
}
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-uploading.syngenta-ant-upload-list-item {
  background-color: #fafafa;
}
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-uploading .syngenta-ant-upload-list-item-info {
  height: auto;
}
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-uploading .syngenta-ant-upload-list-item-info::before,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-uploading .syngenta-ant-upload-list-item-info .anticon-eye,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-uploading .syngenta-ant-upload-list-item-info .anticon-delete {
  display: none;
}
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-progress {
  bottom: 32px;
  width: calc(100% - 14px);
  padding-left: 0;
}
.syngenta-ant-upload-list-text-container,
.syngenta-ant-upload-list-picture-container {
  transition: opacity 0.3s, height 0.3s;
}
.syngenta-ant-upload-list-text-container::before,
.syngenta-ant-upload-list-picture-container::before {
  display: table;
  width: 0;
  height: 0;
  content: '';
}
.syngenta-ant-upload-list-text-container .syngenta-ant-upload-span,
.syngenta-ant-upload-list-picture-container .syngenta-ant-upload-span {
  display: block;
  flex: auto;
}
.syngenta-ant-upload-list-text .syngenta-ant-upload-span,
.syngenta-ant-upload-list-picture .syngenta-ant-upload-span {
  display: flex;
  align-items: center;
}
.syngenta-ant-upload-list-text .syngenta-ant-upload-span > *,
.syngenta-ant-upload-list-picture .syngenta-ant-upload-span > * {
  flex: none;
}
.syngenta-ant-upload-list-text .syngenta-ant-upload-list-item-name,
.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item-name {
  flex: auto;
  margin: 0;
  padding: 0 8px;
}
.syngenta-ant-upload-list-text .syngenta-ant-upload-list-item-card-actions,
.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item-card-actions {
  position: static;
}
.syngenta-ant-upload-list-text .syngenta-ant-upload-text-icon .anticon {
  position: static;
}
.syngenta-ant-upload-list .syngenta-ant-upload-animate-inline-appear,
.syngenta-ant-upload-list .syngenta-ant-upload-animate-inline-enter,
.syngenta-ant-upload-list .syngenta-ant-upload-animate-inline-leave {
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-fill-mode: forwards;
}
.syngenta-ant-upload-list .syngenta-ant-upload-animate-inline-appear,
.syngenta-ant-upload-list .syngenta-ant-upload-animate-inline-enter {
  animation-name: uploadAnimateInlineIn;
}
.syngenta-ant-upload-list .syngenta-ant-upload-animate-inline-leave {
  animation-name: uploadAnimateInlineOut;
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
.syngenta-ant-upload-rtl {
  direction: rtl;
}
.syngenta-ant-upload-rtl.syngenta-ant-upload.syngenta-ant-upload-select-picture-card {
  margin-right: auto;
  margin-left: 8px;
}
.syngenta-ant-upload-list-rtl {
  direction: rtl;
}
.syngenta-ant-upload-list-rtl .syngenta-ant-upload-list-item-list-type-text:hover .syngenta-ant-upload-list-item-name-icon-count-1 {
  padding-right: 22px;
  padding-left: 14px;
}
.syngenta-ant-upload-list-rtl .syngenta-ant-upload-list-item-list-type-text:hover .syngenta-ant-upload-list-item-name-icon-count-2 {
  padding-right: 22px;
  padding-left: 28px;
}
.syngenta-ant-upload-list-rtl .syngenta-ant-upload-list-item-name {
  padding-right: 22px;
  padding-left: 0;
}
.syngenta-ant-upload-list-rtl .syngenta-ant-upload-list-item-name-icon-count-1 {
  padding-left: 14px;
}
.syngenta-ant-upload-list-rtl .syngenta-ant-upload-list-item-card-actions {
  right: auto;
  left: 0;
}
.syngenta-ant-upload-list-rtl .syngenta-ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.syngenta-ant-upload-list-rtl .syngenta-ant-upload-list-item-info {
  padding: 0 4px 0 12px;
}
.syngenta-ant-upload-list-rtl .syngenta-ant-upload-list-item-error .syngenta-ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.syngenta-ant-upload-list-rtl .syngenta-ant-upload-list-item-progress {
  padding-right: 26px;
  padding-left: 0;
}
.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item-info,
.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-info {
  padding: 0;
}
.syngenta-ant-upload-list-rtl.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item-thumbnail,
.syngenta-ant-upload-list-rtl.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-thumbnail {
  right: 8px;
  left: auto;
}
.syngenta-ant-upload-list-rtl.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item-icon,
.syngenta-ant-upload-list-rtl.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-icon {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.syngenta-ant-upload-list-rtl.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item-name,
.syngenta-ant-upload-list-rtl.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-name {
  margin: 0 8px 0 0;
  padding-right: 48px;
  padding-left: 8px;
}
.syngenta-ant-upload-list-rtl.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item-name-icon-count-1,
.syngenta-ant-upload-list-rtl.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-name-icon-count-1 {
  padding-right: 48px;
  padding-left: 18px;
}
.syngenta-ant-upload-list-rtl.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item-name-icon-count-2,
.syngenta-ant-upload-list-rtl.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-name-icon-count-2 {
  padding-right: 48px;
  padding-left: 36px;
}
.syngenta-ant-upload-list-rtl.syngenta-ant-upload-list-picture .syngenta-ant-upload-list-item-progress,
.syngenta-ant-upload-list-rtl.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-progress {
  padding-right: 0;
  padding-left: 0;
}
.syngenta-ant-upload-list-rtl .syngenta-ant-upload-list-picture-card-container {
  margin: 0 0 8px 8px;
}
.syngenta-ant-upload-list-rtl.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-actions {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.syngenta-ant-upload-list-rtl.syngenta-ant-upload-list-picture-card .syngenta-ant-upload-list-item-file + .syngenta-ant-upload-list-item-name {
  margin: 8px 0 0;
  padding: 0;
}
.syngenta-ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}
.syngenta-ant-message-notice {
  padding: 8px;
  text-align: center;
}
.syngenta-ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: all;
}
.syngenta-ant-message-success .anticon {
  color: #52c41a;
}
.syngenta-ant-message-error .anticon {
  color: #ff4d4f;
}
.syngenta-ant-message-warning .anticon {
  color: #faad14;
}
.syngenta-ant-message-info .anticon,
.syngenta-ant-message-loading .anticon {
  color: #000;
}
.syngenta-ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}
.syngenta-ant-message-notice.syngenta-ant-move-up-leave.syngenta-ant-move-up-leave-active {
  animation-name: MessageMoveOut;
  animation-duration: 0.3s;
}
@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
.syngenta-ant-message-rtl {
  direction: rtl;
}
.syngenta-ant-message-rtl span {
  direction: rtl;
}
.syngenta-ant-message-rtl .anticon {
  margin-right: 0;
  margin-left: 8px;
}
.syngenta-ant-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.syngenta-ant-pagination ul,
.syngenta-ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.syngenta-ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
.syngenta-ant-pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
}
.syngenta-ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: Rubik, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #e8eaed;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  user-select: none;
}
.syngenta-ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: #2f3031;
  transition: none;
}
.syngenta-ant-pagination-item a:hover {
  text-decoration: none;
}
.syngenta-ant-pagination-item:hover {
  border-color: #000;
  transition: all 0.3s;
}
.syngenta-ant-pagination-item:hover a {
  color: #000;
}
.syngenta-ant-pagination-item:focus-visible {
  border-color: #000;
  transition: all 0.3s;
}
.syngenta-ant-pagination-item:focus-visible a {
  color: #000;
}
.syngenta-ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #000;
}
.syngenta-ant-pagination-item-active a {
  color: #000;
}
.syngenta-ant-pagination-item-active:hover {
  border-color: #0d0d0d;
}
.syngenta-ant-pagination-item-active:focus-visible {
  border-color: #0d0d0d;
}
.syngenta-ant-pagination-item-active:hover a {
  color: #0d0d0d;
}
.syngenta-ant-pagination-item-active:focus-visible a {
  color: #0d0d0d;
}
.syngenta-ant-pagination-jump-prev,
.syngenta-ant-pagination-jump-next {
  outline: 0;
}
.syngenta-ant-pagination-jump-prev .syngenta-ant-pagination-item-container,
.syngenta-ant-pagination-jump-next .syngenta-ant-pagination-item-container {
  position: relative;
}
.syngenta-ant-pagination-jump-prev .syngenta-ant-pagination-item-container .syngenta-ant-pagination-item-link-icon,
.syngenta-ant-pagination-jump-next .syngenta-ant-pagination-item-container .syngenta-ant-pagination-item-link-icon {
  color: #000;
  font-size: 12px;
  letter-spacing: -1px;
  opacity: 0;
  transition: all 0.2s;
}
.syngenta-ant-pagination-jump-prev .syngenta-ant-pagination-item-container .syngenta-ant-pagination-item-link-icon-svg,
.syngenta-ant-pagination-jump-next .syngenta-ant-pagination-item-container .syngenta-ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.syngenta-ant-pagination-jump-prev .syngenta-ant-pagination-item-container .syngenta-ant-pagination-item-ellipsis,
.syngenta-ant-pagination-jump-next .syngenta-ant-pagination-item-container .syngenta-ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: rgba(0, 0, 0, 0.25);
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  transition: all 0.2s;
}
.syngenta-ant-pagination-jump-prev:hover .syngenta-ant-pagination-item-link-icon,
.syngenta-ant-pagination-jump-next:hover .syngenta-ant-pagination-item-link-icon {
  opacity: 1;
}
.syngenta-ant-pagination-jump-prev:hover .syngenta-ant-pagination-item-ellipsis,
.syngenta-ant-pagination-jump-next:hover .syngenta-ant-pagination-item-ellipsis {
  opacity: 0;
}
.syngenta-ant-pagination-jump-prev:focus-visible .syngenta-ant-pagination-item-link-icon,
.syngenta-ant-pagination-jump-next:focus-visible .syngenta-ant-pagination-item-link-icon {
  opacity: 1;
}
.syngenta-ant-pagination-jump-prev:focus-visible .syngenta-ant-pagination-item-ellipsis,
.syngenta-ant-pagination-jump-next:focus-visible .syngenta-ant-pagination-item-ellipsis {
  opacity: 0;
}
.syngenta-ant-pagination-prev,
.syngenta-ant-pagination-jump-prev,
.syngenta-ant-pagination-jump-next {
  margin-right: 8px;
}
.syngenta-ant-pagination-prev,
.syngenta-ant-pagination-next,
.syngenta-ant-pagination-jump-prev,
.syngenta-ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: #2f3031;
  font-family: Rubik, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.syngenta-ant-pagination-prev,
.syngenta-ant-pagination-next {
  font-family: Arial, Helvetica, sans-serif;
  outline: 0;
}
.syngenta-ant-pagination-prev button,
.syngenta-ant-pagination-next button {
  color: #2f3031;
  cursor: pointer;
  user-select: none;
}
.syngenta-ant-pagination-prev:hover button,
.syngenta-ant-pagination-next:hover button {
  border-color: #0d0d0d;
}
.syngenta-ant-pagination-prev .syngenta-ant-pagination-item-link,
.syngenta-ant-pagination-next .syngenta-ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #e8eaed;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
}
.syngenta-ant-pagination-prev:focus-visible .syngenta-ant-pagination-item-link,
.syngenta-ant-pagination-next:focus-visible .syngenta-ant-pagination-item-link {
  color: #000;
  border-color: #000;
}
.syngenta-ant-pagination-prev:hover .syngenta-ant-pagination-item-link,
.syngenta-ant-pagination-next:hover .syngenta-ant-pagination-item-link {
  color: #000;
  border-color: #000;
}
.syngenta-ant-pagination-disabled,
.syngenta-ant-pagination-disabled:hover {
  cursor: not-allowed;
}
.syngenta-ant-pagination-disabled .syngenta-ant-pagination-item-link,
.syngenta-ant-pagination-disabled:hover .syngenta-ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #e8eaed;
  cursor: not-allowed;
}
.syngenta-ant-pagination-disabled:focus-visible {
  cursor: not-allowed;
}
.syngenta-ant-pagination-disabled:focus-visible .syngenta-ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #e8eaed;
  cursor: not-allowed;
}
.syngenta-ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.syngenta-ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
@media all and (-ms-high-contrast: none) {
  .syngenta-ant-pagination-options *::-ms-backdrop,
  .syngenta-ant-pagination-options {
    vertical-align: top;
  }
}
.syngenta-ant-pagination-options-size-changer.syngenta-ant-select {
  display: inline-block;
  width: auto;
}
.syngenta-ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 32px;
  margin-left: 8px;
  line-height: 32px;
  vertical-align: top;
}
.syngenta-ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: #00a0be;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #f5f8fc;
  background-image: none;
  border: 1px solid #e8eaed;
  border-radius: 2px;
  transition: all 0.3s;
  width: 50px;
  height: 32px;
  margin: 0 8px;
}
.syngenta-ant-pagination-options-quick-jumper input::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.syngenta-ant-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}
.syngenta-ant-pagination-options-quick-jumper input:hover {
  border-color: #0d0d0d;
  border-right-width: 1px;
}
.syngenta-ant-input-rtl .syngenta-ant-pagination-options-quick-jumper input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syngenta-ant-pagination-options-quick-jumper input:focus,
.syngenta-ant-pagination-options-quick-jumper input-focused {
  border-color: #0d0d0d;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.syngenta-ant-input-rtl .syngenta-ant-pagination-options-quick-jumper input:focus,
.syngenta-ant-input-rtl .syngenta-ant-pagination-options-quick-jumper input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syngenta-ant-pagination-options-quick-jumper input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #e8eaed;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.syngenta-ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #e8eaed;
  border-right-width: 1px;
}
.syngenta-ant-pagination-options-quick-jumper input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #e8eaed;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.syngenta-ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #e8eaed;
  border-right-width: 1px;
}
.syngenta-ant-pagination-options-quick-jumper input-borderless,
.syngenta-ant-pagination-options-quick-jumper input-borderless:hover,
.syngenta-ant-pagination-options-quick-jumper input-borderless:focus,
.syngenta-ant-pagination-options-quick-jumper input-borderless-focused,
.syngenta-ant-pagination-options-quick-jumper input-borderless-disabled,
.syngenta-ant-pagination-options-quick-jumper input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.syngenta-ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.syngenta-ant-pagination-options-quick-jumper input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.syngenta-ant-pagination-options-quick-jumper input-sm {
  padding: 0px 7px;
}
.syngenta-ant-pagination-options-quick-jumper input-rtl {
  direction: rtl;
}
.syngenta-ant-pagination-simple .syngenta-ant-pagination-prev,
.syngenta-ant-pagination-simple .syngenta-ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.syngenta-ant-pagination-simple .syngenta-ant-pagination-prev .syngenta-ant-pagination-item-link,
.syngenta-ant-pagination-simple .syngenta-ant-pagination-next .syngenta-ant-pagination-item-link {
  height: 24px;
  background-color: transparent;
  border: 0;
}
.syngenta-ant-pagination-simple .syngenta-ant-pagination-prev .syngenta-ant-pagination-item-link::after,
.syngenta-ant-pagination-simple .syngenta-ant-pagination-next .syngenta-ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.syngenta-ant-pagination-simple .syngenta-ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.syngenta-ant-pagination-simple .syngenta-ant-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #e8eaed;
  border-radius: 2px;
  outline: none;
  transition: border-color 0.3s;
}
.syngenta-ant-pagination-simple .syngenta-ant-pagination-simple-pager input:hover {
  border-color: #000;
}
.syngenta-ant-pagination-simple .syngenta-ant-pagination-simple-pager input:focus {
  border-color: #0d0d0d;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}
.syngenta-ant-pagination-simple .syngenta-ant-pagination-simple-pager input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #e8eaed;
  cursor: not-allowed;
}
.syngenta-ant-pagination.syngenta-ant-pagination-mini .syngenta-ant-pagination-total-text,
.syngenta-ant-pagination.syngenta-ant-pagination-mini .syngenta-ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
.syngenta-ant-pagination.syngenta-ant-pagination-mini .syngenta-ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}
.syngenta-ant-pagination.syngenta-ant-pagination-mini .syngenta-ant-pagination-item:not(.syngenta-ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
.syngenta-ant-pagination.syngenta-ant-pagination-mini .syngenta-ant-pagination-prev,
.syngenta-ant-pagination.syngenta-ant-pagination-mini .syngenta-ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}
.syngenta-ant-pagination.syngenta-ant-pagination-mini .syngenta-ant-pagination-prev .syngenta-ant-pagination-item-link,
.syngenta-ant-pagination.syngenta-ant-pagination-mini .syngenta-ant-pagination-next .syngenta-ant-pagination-item-link {
  background: transparent;
  border-color: transparent;
}
.syngenta-ant-pagination.syngenta-ant-pagination-mini .syngenta-ant-pagination-prev .syngenta-ant-pagination-item-link::after,
.syngenta-ant-pagination.syngenta-ant-pagination-mini .syngenta-ant-pagination-next .syngenta-ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.syngenta-ant-pagination.syngenta-ant-pagination-mini .syngenta-ant-pagination-jump-prev,
.syngenta-ant-pagination.syngenta-ant-pagination-mini .syngenta-ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
.syngenta-ant-pagination.syngenta-ant-pagination-mini .syngenta-ant-pagination-options {
  margin-left: 2px;
}
.syngenta-ant-pagination.syngenta-ant-pagination-mini .syngenta-ant-pagination-options-size-changer {
  top: 0px;
}
.syngenta-ant-pagination.syngenta-ant-pagination-mini .syngenta-ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.syngenta-ant-pagination.syngenta-ant-pagination-mini .syngenta-ant-pagination-options-quick-jumper input {
  padding: 0px 7px;
  width: 44px;
  height: 24px;
}
.syngenta-ant-pagination.syngenta-ant-pagination-disabled {
  cursor: not-allowed;
}
.syngenta-ant-pagination.syngenta-ant-pagination-disabled .syngenta-ant-pagination-item {
  background: #f5f5f5;
  border-color: #e8eaed;
  cursor: not-allowed;
}
.syngenta-ant-pagination.syngenta-ant-pagination-disabled .syngenta-ant-pagination-item a {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}
.syngenta-ant-pagination.syngenta-ant-pagination-disabled .syngenta-ant-pagination-item-active {
  background: #e6e6e6;
}
.syngenta-ant-pagination.syngenta-ant-pagination-disabled .syngenta-ant-pagination-item-active a {
  color: rgba(0, 0, 0, 0.25);
}
.syngenta-ant-pagination.syngenta-ant-pagination-disabled .syngenta-ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #e8eaed;
  cursor: not-allowed;
}
.syngenta-ant-pagination-simple.syngenta-ant-pagination.syngenta-ant-pagination-disabled .syngenta-ant-pagination-item-link {
  background: transparent;
}
.syngenta-ant-pagination.syngenta-ant-pagination-disabled .syngenta-ant-pagination-item-link-icon {
  opacity: 0;
}
.syngenta-ant-pagination.syngenta-ant-pagination-disabled .syngenta-ant-pagination-item-ellipsis {
  opacity: 1;
}
.syngenta-ant-pagination.syngenta-ant-pagination-disabled .syngenta-ant-pagination-simple-pager {
  color: rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 992px) {
  .syngenta-ant-pagination-item-after-jump-prev,
  .syngenta-ant-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .syngenta-ant-pagination-options {
    display: none;
  }
}
.syngenta-ant-pagination-rtl .syngenta-ant-pagination-total-text {
  margin-right: 0;
  margin-left: 8px;
}
.syngenta-ant-pagination-rtl .syngenta-ant-pagination-item,
.syngenta-ant-pagination-rtl .syngenta-ant-pagination-prev,
.syngenta-ant-pagination-rtl .syngenta-ant-pagination-jump-prev,
.syngenta-ant-pagination-rtl .syngenta-ant-pagination-jump-next {
  margin-right: 0;
  margin-left: 8px;
}
.syngenta-ant-pagination-rtl .syngenta-ant-pagination-slash {
  margin: 0 5px 0 10px;
}
.syngenta-ant-pagination-rtl .syngenta-ant-pagination-options {
  margin-right: 16px;
  margin-left: 0;
}
.syngenta-ant-pagination-rtl .syngenta-ant-pagination-options .syngenta-ant-pagination-options-size-changer.syngenta-ant-select {
  margin-right: 0;
  margin-left: 8px;
}
.syngenta-ant-pagination-rtl .syngenta-ant-pagination-options .syngenta-ant-pagination-options-quick-jumper {
  margin-left: 0;
}
.syngenta-ant-pagination-rtl.syngenta-ant-pagination-simple .syngenta-ant-pagination-simple-pager {
  margin-right: 0;
  margin-left: 8px;
}
.syngenta-ant-pagination-rtl.syngenta-ant-pagination-simple .syngenta-ant-pagination-simple-pager input {
  margin-right: 0;
  margin-left: 8px;
}
.syngenta-ant-pagination-rtl.syngenta-ant-pagination.mini .syngenta-ant-pagination-options {
  margin-right: 2px;
  margin-left: 0;
}
.syngenta-ant-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  color: #707374;
  font-size: 14px;
}
.syngenta-ant-breadcrumb .anticon {
  font-size: 14px;
}
.syngenta-ant-breadcrumb ol {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.syngenta-ant-breadcrumb a {
  color: #707374;
  transition: color 0.3s;
}
.syngenta-ant-breadcrumb a:hover {
  color: #2f3031;
}
.syngenta-ant-breadcrumb li:last-child {
  color: #2f3031;
}
.syngenta-ant-breadcrumb li:last-child a {
  color: #2f3031;
}
li:last-child > .syngenta-ant-breadcrumb-separator {
  display: none;
}
.syngenta-ant-breadcrumb-separator {
  margin: 0 8px;
  color: #707374;
}
.syngenta-ant-breadcrumb-link > .anticon + span,
.syngenta-ant-breadcrumb-link > .anticon + a {
  margin-left: 4px;
}
.syngenta-ant-breadcrumb-overlay-link > .anticon {
  margin-left: 4px;
}
.syngenta-ant-breadcrumb-rtl {
  direction: rtl;
}
.syngenta-ant-breadcrumb-rtl::before {
  display: table;
  content: '';
}
.syngenta-ant-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-breadcrumb-rtl::before {
  display: table;
  content: '';
}
.syngenta-ant-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-breadcrumb-rtl > span {
  float: right;
}
.syngenta-ant-breadcrumb-rtl .syngenta-ant-breadcrumb-link > .anticon + span,
.syngenta-ant-breadcrumb-rtl .syngenta-ant-breadcrumb-link > .anticon + a {
  margin-right: 4px;
  margin-left: 0;
}
.syngenta-ant-breadcrumb-rtl .syngenta-ant-breadcrumb-overlay-link > .anticon {
  margin-right: 4px;
  margin-left: 0;
}
.syngenta-ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.syngenta-ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
.syngenta-ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 24px 0;
}
.syngenta-ant-divider-horizontal.syngenta-ant-divider-with-text {
  display: flex;
  align-items: center;
  margin: 16px 0;
  color: #707374;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  border-top: 0;
  border-top-color: rgba(0, 0, 0, 0.06);
}
.syngenta-ant-divider-horizontal.syngenta-ant-divider-with-text::before,
.syngenta-ant-divider-horizontal.syngenta-ant-divider-with-text::after {
  position: relative;
  width: 50%;
  border-top: 1px solid transparent;
  border-top-color: inherit;
  border-bottom: 0;
  transform: translateY(50%);
  content: '';
}
.syngenta-ant-divider-horizontal.syngenta-ant-divider-with-text-left::before {
  width: 5%;
}
.syngenta-ant-divider-horizontal.syngenta-ant-divider-with-text-left::after {
  width: 95%;
}
.syngenta-ant-divider-horizontal.syngenta-ant-divider-with-text-right::before {
  width: 95%;
}
.syngenta-ant-divider-horizontal.syngenta-ant-divider-with-text-right::after {
  width: 5%;
}
.syngenta-ant-divider-inner-text {
  display: inline-block;
  padding: 0 1em;
}
.syngenta-ant-divider-dashed {
  background: none;
  border-color: rgba(0, 0, 0, 0.06);
  border-style: dashed;
  border-width: 1px 0 0;
}
.syngenta-ant-divider-horizontal.syngenta-ant-divider-with-text.syngenta-ant-divider-dashed::before,
.syngenta-ant-divider-horizontal.syngenta-ant-divider-with-text.syngenta-ant-divider-dashed::after {
  border-style: dashed none none;
}
.syngenta-ant-divider-vertical.syngenta-ant-divider-dashed {
  border-width: 0 0 0 1px;
}
.syngenta-ant-divider-plain.syngenta-ant-divider-with-text {
  color: #2f3031;
  font-weight: normal;
  font-size: 14px;
}
.syngenta-ant-divider-horizontal.syngenta-ant-divider-with-text-left.syngenta-ant-divider-no-default-orientation-margin-left::before {
  width: 0;
}
.syngenta-ant-divider-horizontal.syngenta-ant-divider-with-text-left.syngenta-ant-divider-no-default-orientation-margin-left::after {
  width: 100%;
}
.syngenta-ant-divider-horizontal.syngenta-ant-divider-with-text-left.syngenta-ant-divider-no-default-orientation-margin-left .syngenta-ant-divider-inner-text {
  padding-left: 0;
}
.syngenta-ant-divider-horizontal.syngenta-ant-divider-with-text-right.syngenta-ant-divider-no-default-orientation-margin-right::before {
  width: 100%;
}
.syngenta-ant-divider-horizontal.syngenta-ant-divider-with-text-right.syngenta-ant-divider-no-default-orientation-margin-right::after {
  width: 0;
}
.syngenta-ant-divider-horizontal.syngenta-ant-divider-with-text-right.syngenta-ant-divider-no-default-orientation-margin-right .syngenta-ant-divider-inner-text {
  padding-right: 0;
}
.syngenta-ant-divider-rtl {
  direction: rtl;
}
.syngenta-ant-divider-rtl.syngenta-ant-divider-horizontal.syngenta-ant-divider-with-text-left::before {
  width: 95%;
}
.syngenta-ant-divider-rtl.syngenta-ant-divider-horizontal.syngenta-ant-divider-with-text-left::after {
  width: 5%;
}
.syngenta-ant-divider-rtl.syngenta-ant-divider-horizontal.syngenta-ant-divider-with-text-right::before {
  width: 5%;
}
.syngenta-ant-divider-rtl.syngenta-ant-divider-horizontal.syngenta-ant-divider-with-text-right::after {
  width: 95%;
}
.syngenta-ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  background: #fff;
  border-radius: 2px;
}
.syngenta-ant-card-rtl {
  direction: rtl;
}
.syngenta-ant-card-hoverable {
  cursor: pointer;
  transition: box-shadow 0.3s, border-color 0.3s;
}
.syngenta-ant-card-hoverable:hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.syngenta-ant-card-bordered {
  border: 1px solid #f0f0f0;
}
.syngenta-ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: #707374;
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.syngenta-ant-card-head::before {
  display: table;
  content: '';
}
.syngenta-ant-card-head::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-card-head::before {
  display: table;
  content: '';
}
.syngenta-ant-card-head::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-card-head-wrapper {
  display: flex;
  align-items: center;
}
.syngenta-ant-card-head-title {
  display: inline-block;
  flex: 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syngenta-ant-card-head-title > .syngenta-ant-typography,
.syngenta-ant-card-head-title > .syngenta-ant-typography-edit-content {
  left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.syngenta-ant-card-head .syngenta-ant-tabs-top {
  clear: both;
  margin-bottom: -17px;
  color: #2f3031;
  font-weight: normal;
  font-size: 14px;
}
.syngenta-ant-card-head .syngenta-ant-tabs-top-bar {
  border-bottom: 1px solid #f0f0f0;
}
.syngenta-ant-card-extra {
  margin-left: auto;
  padding: 16px 0;
  color: #2f3031;
  font-weight: normal;
  font-size: 14px;
}
.syngenta-ant-card-rtl .syngenta-ant-card-extra {
  margin-right: auto;
  margin-left: 0;
}
.syngenta-ant-card-body {
  padding: 24px;
}
.syngenta-ant-card-body::before {
  display: table;
  content: '';
}
.syngenta-ant-card-body::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-card-body::before {
  display: table;
  content: '';
}
.syngenta-ant-card-body::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-card-contain-grid .syngenta-ant-card-body {
  display: flex;
  flex-wrap: wrap;
}
.syngenta-ant-card-contain-grid:not(.syngenta-ant-card-loading) .syngenta-ant-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}
.syngenta-ant-card-grid {
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
  transition: all 0.3s;
}
.syngenta-ant-card-grid-hoverable:hover {
  position: relative;
  z-index: 1;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.syngenta-ant-card-contain-tabs > .syngenta-ant-card-head .syngenta-ant-card-head-title {
  min-height: 32px;
  padding-bottom: 0;
}
.syngenta-ant-card-contain-tabs > .syngenta-ant-card-head .syngenta-ant-card-extra {
  padding-bottom: 0;
}
.syngenta-ant-card-bordered .syngenta-ant-card-cover {
  margin-top: -1px;
  margin-right: -1px;
  margin-left: -1px;
}
.syngenta-ant-card-cover > * {
  display: block;
  width: 100%;
}
.syngenta-ant-card-cover img {
  border-radius: 2px 2px 0 0;
}
.syngenta-ant-card-actions {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  border-top: 1px solid #f0f0f0;
}
.syngenta-ant-card-actions::before {
  display: table;
  content: '';
}
.syngenta-ant-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-card-actions::before {
  display: table;
  content: '';
}
.syngenta-ant-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-card-actions > li {
  margin: 12px 0;
  color: #707374;
  text-align: center;
}
.syngenta-ant-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 1.5715;
  cursor: pointer;
}
.syngenta-ant-card-actions > li > span:hover {
  color: #000;
  transition: color 0.3s;
}
.syngenta-ant-card-actions > li > span a:not(.syngenta-ant-btn),
.syngenta-ant-card-actions > li > span > .anticon {
  display: inline-block;
  width: 100%;
  color: #707374;
  line-height: 22px;
  transition: color 0.3s;
}
.syngenta-ant-card-actions > li > span a:not(.syngenta-ant-btn):hover,
.syngenta-ant-card-actions > li > span > .anticon:hover {
  color: #000;
}
.syngenta-ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.syngenta-ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #f0f0f0;
}
.syngenta-ant-card-rtl .syngenta-ant-card-actions > li:not(:last-child) {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.syngenta-ant-card-type-inner .syngenta-ant-card-head {
  padding: 0 24px;
  background: #fafafa;
}
.syngenta-ant-card-type-inner .syngenta-ant-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}
.syngenta-ant-card-type-inner .syngenta-ant-card-body {
  padding: 16px 24px;
}
.syngenta-ant-card-type-inner .syngenta-ant-card-extra {
  padding: 13.5px 0;
}
.syngenta-ant-card-meta {
  display: flex;
  margin: -4px 0;
}
.syngenta-ant-card-meta::before {
  display: table;
  content: '';
}
.syngenta-ant-card-meta::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-card-meta::before {
  display: table;
  content: '';
}
.syngenta-ant-card-meta::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-card-meta-avatar {
  padding-right: 16px;
}
.syngenta-ant-card-rtl .syngenta-ant-card-meta-avatar {
  padding-right: 0;
  padding-left: 16px;
}
.syngenta-ant-card-meta-detail {
  flex: 1;
  overflow: hidden;
}
.syngenta-ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}
.syngenta-ant-card-meta-title {
  overflow: hidden;
  color: #707374;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syngenta-ant-card-meta-description {
  color: #707374;
}
.syngenta-ant-card-loading {
  overflow: hidden;
}
.syngenta-ant-card-loading .syngenta-ant-card-body {
  user-select: none;
}
.syngenta-ant-card-small > .syngenta-ant-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
}
.syngenta-ant-card-small > .syngenta-ant-card-head > .syngenta-ant-card-head-wrapper > .syngenta-ant-card-head-title {
  padding: 8px 0;
}
.syngenta-ant-card-small > .syngenta-ant-card-head > .syngenta-ant-card-head-wrapper > .syngenta-ant-card-extra {
  padding: 8px 0;
  font-size: 14px;
}
.syngenta-ant-card-small > .syngenta-ant-card-body {
  padding: 12px;
}
.syngenta-ant-switch {
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  user-select: none;
}
.syngenta-ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}
.syngenta-ant-switch-checked:focus {
  box-shadow: 0 0 0 2px #404040;
}
.syngenta-ant-switch:focus:hover {
  box-shadow: none;
}
.syngenta-ant-switch-checked {
  background-color: #000;
}
.syngenta-ant-switch-loading,
.syngenta-ant-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.syngenta-ant-switch-loading *,
.syngenta-ant-switch-disabled * {
  box-shadow: none;
  cursor: not-allowed;
}
.syngenta-ant-switch-inner {
  display: block;
  margin: 0 7px 0 25px;
  color: #fff;
  font-size: 12px;
  transition: margin 0.2s;
}
.syngenta-ant-switch-checked .syngenta-ant-switch-inner {
  margin: 0 25px 0 7px;
}
.syngenta-ant-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
}
.syngenta-ant-switch-handle::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  transition: all 0.2s ease-in-out;
  content: '';
}
.syngenta-ant-switch-checked .syngenta-ant-switch-handle {
  left: calc(100% - 18px - 2px);
}
.syngenta-ant-switch:not(.syngenta-ant-switch-disabled):active .syngenta-ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.syngenta-ant-switch:not(.syngenta-ant-switch-disabled):active.syngenta-ant-switch-checked .syngenta-ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.syngenta-ant-switch-loading-icon.anticon {
  position: relative;
  top: 2px;
  color: rgba(0, 0, 0, 0.65);
  vertical-align: top;
}
.syngenta-ant-switch-checked .syngenta-ant-switch-loading-icon {
  color: #000;
}
.syngenta-ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 16px;
}
.syngenta-ant-switch-small .syngenta-ant-switch-inner {
  margin: 0 5px 0 18px;
  font-size: 12px;
}
.syngenta-ant-switch-small .syngenta-ant-switch-handle {
  width: 12px;
  height: 12px;
}
.syngenta-ant-switch-small .syngenta-ant-switch-loading-icon {
  top: 1.5px;
  font-size: 9px;
}
.syngenta-ant-switch-small.syngenta-ant-switch-checked .syngenta-ant-switch-inner {
  margin: 0 18px 0 5px;
}
.syngenta-ant-switch-small.syngenta-ant-switch-checked .syngenta-ant-switch-handle {
  left: calc(100% - 12px - 2px);
}
.syngenta-ant-switch-rtl {
  direction: rtl;
}
.syngenta-ant-switch-rtl .syngenta-ant-switch-inner {
  margin: 0 25px 0 7px;
}
.syngenta-ant-switch-rtl .syngenta-ant-switch-handle {
  right: 2px;
  left: auto;
}
.syngenta-ant-switch-rtl:not(.syngenta-ant-switch-rtl-disabled):active .syngenta-ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.syngenta-ant-switch-rtl:not(.syngenta-ant-switch-rtl-disabled):active.syngenta-ant-switch-checked .syngenta-ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.syngenta-ant-switch-rtl.syngenta-ant-switch-checked .syngenta-ant-switch-inner {
  margin: 0 7px 0 25px;
}
.syngenta-ant-switch-rtl.syngenta-ant-switch-checked .syngenta-ant-switch-handle {
  right: calc(100% - 18px - 2px);
}
.syngenta-ant-switch-rtl.syngenta-ant-switch-small.syngenta-ant-switch-checked .syngenta-ant-switch-handle {
  right: calc(100% - 12px - 2px);
}
.syngenta-ant-space {
  display: inline-flex;
}
.syngenta-ant-space-vertical {
  flex-direction: column;
}
.syngenta-ant-space-align-center {
  align-items: center;
}
.syngenta-ant-space-align-start {
  align-items: flex-start;
}
.syngenta-ant-space-align-end {
  align-items: flex-end;
}
.syngenta-ant-space-align-baseline {
  align-items: baseline;
}
.syngenta-ant-space-item:empty {
  display: none;
}
.syngenta-ant-space-compact {
  display: inline-flex;
}
.syngenta-ant-space-compact-block {
  display: flex;
  width: 100%;
}
.syngenta-ant-space-compact-vertical {
  flex-direction: column;
}
.syngenta-ant-space-rtl {
  direction: rtl;
}
.syngenta-ant-space-compact-rtl {
  direction: rtl;
}
.syngenta-ant-page-header {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding: 16px 24px;
  background-color: #fff;
}
.syngenta-ant-page-header-ghost {
  background-color: inherit;
}
.syngenta-ant-page-header.has-breadcrumb {
  padding-top: 12px;
}
.syngenta-ant-page-header.has-footer {
  padding-bottom: 0;
}
.syngenta-ant-page-header-back {
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
}
.syngenta-ant-page-header-back-button {
  color: #000;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  color: #00A0BE;
}
.syngenta-ant-page-header-back-button:focus-visible,
.syngenta-ant-page-header-back-button:hover {
  color: #0d0d0d;
}
.syngenta-ant-page-header-back-button:active {
  color: #000000;
}
.syngenta-ant-page-header .syngenta-ant-divider-vertical {
  height: 14px;
  margin: 0 12px;
  vertical-align: middle;
}
.syngenta-ant-breadcrumb + .syngenta-ant-page-header-heading {
  margin-top: 8px;
}
.syngenta-ant-page-header-heading {
  display: flex;
  justify-content: space-between;
}
.syngenta-ant-page-header-heading-left {
  display: flex;
  align-items: center;
  margin: 4px 0;
  overflow: hidden;
}
.syngenta-ant-page-header-heading-title {
  margin-right: 12px;
  margin-bottom: 0;
  color: #707374;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syngenta-ant-page-header-heading .syngenta-ant-avatar {
  margin-right: 12px;
}
.syngenta-ant-page-header-heading-sub-title {
  margin-right: 12px;
  color: #707374;
  font-size: 14px;
  line-height: 1.5715;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syngenta-ant-page-header-heading-extra {
  margin: 4px 0;
  white-space: nowrap;
}
.syngenta-ant-page-header-heading-extra > * {
  white-space: unset;
}
.syngenta-ant-page-header-content {
  padding-top: 12px;
}
.syngenta-ant-page-header-footer {
  margin-top: 16px;
}
.syngenta-ant-page-header-footer .syngenta-ant-tabs > .syngenta-ant-tabs-nav {
  margin: 0;
}
.syngenta-ant-page-header-footer .syngenta-ant-tabs > .syngenta-ant-tabs-nav::before {
  border: none;
}
.syngenta-ant-page-header-footer .syngenta-ant-tabs .syngenta-ant-tabs-tab {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
}
.syngenta-ant-page-header-compact .syngenta-ant-page-header-heading {
  flex-wrap: wrap;
}
.syngenta-ant-page-header-rtl {
  direction: rtl;
}
.syngenta-ant-page-header-rtl .syngenta-ant-page-header-back {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.syngenta-ant-page-header-rtl .syngenta-ant-page-header-heading-title {
  margin-right: 0;
  margin-left: 12px;
}
.syngenta-ant-page-header-rtl .syngenta-ant-page-header-heading .syngenta-ant-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.syngenta-ant-page-header-rtl .syngenta-ant-page-header-heading-sub-title {
  float: right;
  margin-right: 0;
  margin-left: 12px;
}
.syngenta-ant-page-header-rtl .syngenta-ant-page-header-heading-tags {
  float: right;
}
.syngenta-ant-page-header-rtl .syngenta-ant-page-header-heading-extra {
  float: left;
}
.syngenta-ant-page-header-rtl .syngenta-ant-page-header-heading-extra > * {
  margin-right: 12px;
  margin-left: 0;
}
.syngenta-ant-page-header-rtl .syngenta-ant-page-header-heading-extra > *:first-child {
  margin-right: 0;
}
.syngenta-ant-page-header-rtl .syngenta-ant-page-header-footer .syngenta-ant-tabs-bar .syngenta-ant-tabs-nav {
  float: right;
}
[class^=syngenta-ant-]::-ms-clear,
[class*= syngenta-ant-]::-ms-clear,
[class^=syngenta-ant-] input::-ms-clear,
[class*= syngenta-ant-] input::-ms-clear,
[class^=syngenta-ant-] input::-ms-reveal,
[class*= syngenta-ant-] input::-ms-reveal {
  display: none;
}
/* stylelint-disable property-no-vendor-prefix, at-rule-no-vendor-prefix */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
body {
  margin: 0;
  color: #2f3031;
  font-size: 14px;
  font-family: Rubik, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: 'tnum';
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: #707374;
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #000;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #0d0d0d;
}
a:active {
  color: #000000;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a:focus {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: #707374;
  text-align: left;
  caption-side: bottom;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::selection {
  color: #fff;
  background: #000;
}
.clearfix::before {
  display: table;
  content: '';
}
.clearfix::after {
  display: table;
  clear: both;
  content: '';
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon > .anticon {
  line-height: 0;
  vertical-align: 0;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin,
.anticon-spin::before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
.syngenta-ant-fade-enter,
.syngenta-ant-fade-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-fade-enter.syngenta-ant-fade-enter-active,
.syngenta-ant-fade-appear.syngenta-ant-fade-appear-active {
  animation-name: antFadeIn;
  animation-play-state: running;
}
.syngenta-ant-fade-leave.syngenta-ant-fade-leave-active {
  animation-name: antFadeOut;
  animation-play-state: running;
  pointer-events: none;
}
.syngenta-ant-fade-enter,
.syngenta-ant-fade-appear {
  opacity: 0;
  animation-timing-function: linear;
}
.syngenta-ant-fade-leave {
  animation-timing-function: linear;
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.syngenta-ant-move-up-enter,
.syngenta-ant-move-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-move-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-move-up-enter.syngenta-ant-move-up-enter-active,
.syngenta-ant-move-up-appear.syngenta-ant-move-up-appear-active {
  animation-name: antMoveUpIn;
  animation-play-state: running;
}
.syngenta-ant-move-up-leave.syngenta-ant-move-up-leave-active {
  animation-name: antMoveUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.syngenta-ant-move-up-enter,
.syngenta-ant-move-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.syngenta-ant-move-up-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.syngenta-ant-move-down-enter,
.syngenta-ant-move-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-move-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-move-down-enter.syngenta-ant-move-down-enter-active,
.syngenta-ant-move-down-appear.syngenta-ant-move-down-appear-active {
  animation-name: antMoveDownIn;
  animation-play-state: running;
}
.syngenta-ant-move-down-leave.syngenta-ant-move-down-leave-active {
  animation-name: antMoveDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.syngenta-ant-move-down-enter,
.syngenta-ant-move-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.syngenta-ant-move-down-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.syngenta-ant-move-left-enter,
.syngenta-ant-move-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-move-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-move-left-enter.syngenta-ant-move-left-enter-active,
.syngenta-ant-move-left-appear.syngenta-ant-move-left-appear-active {
  animation-name: antMoveLeftIn;
  animation-play-state: running;
}
.syngenta-ant-move-left-leave.syngenta-ant-move-left-leave-active {
  animation-name: antMoveLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.syngenta-ant-move-left-enter,
.syngenta-ant-move-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.syngenta-ant-move-left-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.syngenta-ant-move-right-enter,
.syngenta-ant-move-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-move-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-move-right-enter.syngenta-ant-move-right-enter-active,
.syngenta-ant-move-right-appear.syngenta-ant-move-right-appear-active {
  animation-name: antMoveRightIn;
  animation-play-state: running;
}
.syngenta-ant-move-right-leave.syngenta-ant-move-right-leave-active {
  animation-name: antMoveRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.syngenta-ant-move-right-enter,
.syngenta-ant-move-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.syngenta-ant-move-right-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
[syngenta-ant-click-animating='true'],
[syngenta-ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html {
  --antd-wave-shadow-color: #000;
  --scroll-bar: 0;
}
[syngenta-ant-click-animating-without-extra-node='true']::after,
.syngenta-ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #000;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #000;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.syngenta-ant-slide-up-enter,
.syngenta-ant-slide-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-slide-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-slide-up-enter.syngenta-ant-slide-up-enter-active,
.syngenta-ant-slide-up-appear.syngenta-ant-slide-up-appear-active {
  animation-name: antSlideUpIn;
  animation-play-state: running;
}
.syngenta-ant-slide-up-leave.syngenta-ant-slide-up-leave-active {
  animation-name: antSlideUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.syngenta-ant-slide-up-enter,
.syngenta-ant-slide-up-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.syngenta-ant-slide-up-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.syngenta-ant-slide-down-enter,
.syngenta-ant-slide-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-slide-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-slide-down-enter.syngenta-ant-slide-down-enter-active,
.syngenta-ant-slide-down-appear.syngenta-ant-slide-down-appear-active {
  animation-name: antSlideDownIn;
  animation-play-state: running;
}
.syngenta-ant-slide-down-leave.syngenta-ant-slide-down-leave-active {
  animation-name: antSlideDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.syngenta-ant-slide-down-enter,
.syngenta-ant-slide-down-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.syngenta-ant-slide-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.syngenta-ant-slide-left-enter,
.syngenta-ant-slide-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-slide-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-slide-left-enter.syngenta-ant-slide-left-enter-active,
.syngenta-ant-slide-left-appear.syngenta-ant-slide-left-appear-active {
  animation-name: antSlideLeftIn;
  animation-play-state: running;
}
.syngenta-ant-slide-left-leave.syngenta-ant-slide-left-leave-active {
  animation-name: antSlideLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.syngenta-ant-slide-left-enter,
.syngenta-ant-slide-left-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.syngenta-ant-slide-left-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.syngenta-ant-slide-right-enter,
.syngenta-ant-slide-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-slide-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-slide-right-enter.syngenta-ant-slide-right-enter-active,
.syngenta-ant-slide-right-appear.syngenta-ant-slide-right-appear-active {
  animation-name: antSlideRightIn;
  animation-play-state: running;
}
.syngenta-ant-slide-right-leave.syngenta-ant-slide-right-leave-active {
  animation-name: antSlideRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.syngenta-ant-slide-right-enter,
.syngenta-ant-slide-right-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.syngenta-ant-slide-right-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
.syngenta-ant-zoom-enter,
.syngenta-ant-zoom-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-zoom-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-zoom-enter.syngenta-ant-zoom-enter-active,
.syngenta-ant-zoom-appear.syngenta-ant-zoom-appear-active {
  animation-name: antZoomIn;
  animation-play-state: running;
}
.syngenta-ant-zoom-leave.syngenta-ant-zoom-leave-active {
  animation-name: antZoomOut;
  animation-play-state: running;
  pointer-events: none;
}
.syngenta-ant-zoom-enter,
.syngenta-ant-zoom-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.syngenta-ant-zoom-enter-prepare,
.syngenta-ant-zoom-appear-prepare {
  transform: none;
}
.syngenta-ant-zoom-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.syngenta-ant-zoom-big-enter,
.syngenta-ant-zoom-big-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-zoom-big-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-zoom-big-enter.syngenta-ant-zoom-big-enter-active,
.syngenta-ant-zoom-big-appear.syngenta-ant-zoom-big-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.syngenta-ant-zoom-big-leave.syngenta-ant-zoom-big-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.syngenta-ant-zoom-big-enter,
.syngenta-ant-zoom-big-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.syngenta-ant-zoom-big-enter-prepare,
.syngenta-ant-zoom-big-appear-prepare {
  transform: none;
}
.syngenta-ant-zoom-big-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.syngenta-ant-zoom-big-fast-enter,
.syngenta-ant-zoom-big-fast-appear {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-zoom-big-fast-leave {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-zoom-big-fast-enter.syngenta-ant-zoom-big-fast-enter-active,
.syngenta-ant-zoom-big-fast-appear.syngenta-ant-zoom-big-fast-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.syngenta-ant-zoom-big-fast-leave.syngenta-ant-zoom-big-fast-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.syngenta-ant-zoom-big-fast-enter,
.syngenta-ant-zoom-big-fast-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.syngenta-ant-zoom-big-fast-enter-prepare,
.syngenta-ant-zoom-big-fast-appear-prepare {
  transform: none;
}
.syngenta-ant-zoom-big-fast-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.syngenta-ant-zoom-up-enter,
.syngenta-ant-zoom-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-zoom-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-zoom-up-enter.syngenta-ant-zoom-up-enter-active,
.syngenta-ant-zoom-up-appear.syngenta-ant-zoom-up-appear-active {
  animation-name: antZoomUpIn;
  animation-play-state: running;
}
.syngenta-ant-zoom-up-leave.syngenta-ant-zoom-up-leave-active {
  animation-name: antZoomUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.syngenta-ant-zoom-up-enter,
.syngenta-ant-zoom-up-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.syngenta-ant-zoom-up-enter-prepare,
.syngenta-ant-zoom-up-appear-prepare {
  transform: none;
}
.syngenta-ant-zoom-up-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.syngenta-ant-zoom-down-enter,
.syngenta-ant-zoom-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-zoom-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-zoom-down-enter.syngenta-ant-zoom-down-enter-active,
.syngenta-ant-zoom-down-appear.syngenta-ant-zoom-down-appear-active {
  animation-name: antZoomDownIn;
  animation-play-state: running;
}
.syngenta-ant-zoom-down-leave.syngenta-ant-zoom-down-leave-active {
  animation-name: antZoomDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.syngenta-ant-zoom-down-enter,
.syngenta-ant-zoom-down-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.syngenta-ant-zoom-down-enter-prepare,
.syngenta-ant-zoom-down-appear-prepare {
  transform: none;
}
.syngenta-ant-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.syngenta-ant-zoom-left-enter,
.syngenta-ant-zoom-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-zoom-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-zoom-left-enter.syngenta-ant-zoom-left-enter-active,
.syngenta-ant-zoom-left-appear.syngenta-ant-zoom-left-appear-active {
  animation-name: antZoomLeftIn;
  animation-play-state: running;
}
.syngenta-ant-zoom-left-leave.syngenta-ant-zoom-left-leave-active {
  animation-name: antZoomLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.syngenta-ant-zoom-left-enter,
.syngenta-ant-zoom-left-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.syngenta-ant-zoom-left-enter-prepare,
.syngenta-ant-zoom-left-appear-prepare {
  transform: none;
}
.syngenta-ant-zoom-left-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.syngenta-ant-zoom-right-enter,
.syngenta-ant-zoom-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-zoom-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.syngenta-ant-zoom-right-enter.syngenta-ant-zoom-right-enter-active,
.syngenta-ant-zoom-right-appear.syngenta-ant-zoom-right-appear-active {
  animation-name: antZoomRightIn;
  animation-play-state: running;
}
.syngenta-ant-zoom-right-leave.syngenta-ant-zoom-right-leave-active {
  animation-name: antZoomRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.syngenta-ant-zoom-right-enter,
.syngenta-ant-zoom-right-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.syngenta-ant-zoom-right-enter-prepare,
.syngenta-ant-zoom-right-appear-prepare {
  transform: none;
}
.syngenta-ant-zoom-right-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.syngenta-ant-select-single .syngenta-ant-select-selector {
  display: flex;
}
.syngenta-ant-select-single .syngenta-ant-select-selector .syngenta-ant-select-selection-search {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  left: 11px;
}
.syngenta-ant-select-single .syngenta-ant-select-selector .syngenta-ant-select-selection-search-input {
  width: 100%;
}
.syngenta-ant-select-single .syngenta-ant-select-selector .syngenta-ant-select-selection-item,
.syngenta-ant-select-single .syngenta-ant-select-selector .syngenta-ant-select-selection-placeholder {
  padding: 0;
  line-height: 30px;
  transition: all 0.3s, visibility 0s;
}
.syngenta-ant-select-single .syngenta-ant-select-selector .syngenta-ant-select-selection-item {
  position: relative;
  user-select: none;
}
.syngenta-ant-select-single .syngenta-ant-select-selector .syngenta-ant-select-selection-placeholder {
  transition: none;
  pointer-events: none;
}
.syngenta-ant-select-single .syngenta-ant-select-selector::after,
.syngenta-ant-select-single .syngenta-ant-select-selector .syngenta-ant-select-selection-item::after,
.syngenta-ant-select-single .syngenta-ant-select-selector .syngenta-ant-select-selection-placeholder::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.syngenta-ant-select-single.syngenta-ant-select-show-arrow .syngenta-ant-select-selection-search {
  right: 25px;
}
.syngenta-ant-select-single.syngenta-ant-select-show-arrow .syngenta-ant-select-selection-item,
.syngenta-ant-select-single.syngenta-ant-select-show-arrow .syngenta-ant-select-selection-placeholder {
  padding-right: 18px;
}
.syngenta-ant-select-single.syngenta-ant-select-open .syngenta-ant-select-selection-item {
  color: #bfbfbf;
}
.syngenta-ant-select-single:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector {
  width: 100%;
  height: 32px;
  padding: 0 11px;
}
.syngenta-ant-select-single:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector .syngenta-ant-select-selection-search-input {
  height: 30px;
}
.syngenta-ant-select-single:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector::after {
  line-height: 30px;
}
.syngenta-ant-select-single.syngenta-ant-select-customize-input .syngenta-ant-select-selector::after {
  display: none;
}
.syngenta-ant-select-single.syngenta-ant-select-customize-input .syngenta-ant-select-selector .syngenta-ant-select-selection-search {
  position: static;
  width: 100%;
}
.syngenta-ant-select-single.syngenta-ant-select-customize-input .syngenta-ant-select-selector .syngenta-ant-select-selection-placeholder {
  position: absolute;
  right: 0;
  left: 0;
  padding: 0 11px;
}
.syngenta-ant-select-single.syngenta-ant-select-customize-input .syngenta-ant-select-selector .syngenta-ant-select-selection-placeholder::after {
  display: none;
}
.syngenta-ant-select-single.syngenta-ant-select-lg:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector {
  height: 40px;
}
.syngenta-ant-select-single.syngenta-ant-select-lg:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector::after,
.syngenta-ant-select-single.syngenta-ant-select-lg:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector .syngenta-ant-select-selection-item,
.syngenta-ant-select-single.syngenta-ant-select-lg:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector .syngenta-ant-select-selection-placeholder {
  line-height: 38px;
}
.syngenta-ant-select-single.syngenta-ant-select-lg:not(.syngenta-ant-select-customize-input):not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selection-search-input {
  height: 38px;
}
.syngenta-ant-select-single.syngenta-ant-select-sm:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector {
  height: 24px;
}
.syngenta-ant-select-single.syngenta-ant-select-sm:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector::after,
.syngenta-ant-select-single.syngenta-ant-select-sm:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector .syngenta-ant-select-selection-item,
.syngenta-ant-select-single.syngenta-ant-select-sm:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector .syngenta-ant-select-selection-placeholder {
  line-height: 22px;
}
.syngenta-ant-select-single.syngenta-ant-select-sm:not(.syngenta-ant-select-customize-input):not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selection-search-input {
  height: 22px;
}
.syngenta-ant-select-single.syngenta-ant-select-sm:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selection-search {
  right: 7px;
  left: 7px;
}
.syngenta-ant-select-single.syngenta-ant-select-sm:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector {
  padding: 0 7px;
}
.syngenta-ant-select-single.syngenta-ant-select-sm:not(.syngenta-ant-select-customize-input).syngenta-ant-select-show-arrow .syngenta-ant-select-selection-search {
  right: 28px;
}
.syngenta-ant-select-single.syngenta-ant-select-sm:not(.syngenta-ant-select-customize-input).syngenta-ant-select-show-arrow .syngenta-ant-select-selection-item,
.syngenta-ant-select-single.syngenta-ant-select-sm:not(.syngenta-ant-select-customize-input).syngenta-ant-select-show-arrow .syngenta-ant-select-selection-placeholder {
  padding-right: 21px;
}
.syngenta-ant-select-single.syngenta-ant-select-lg:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector {
  padding: 0 11px;
}
/**
 * Do not merge `height` & `line-height` under style with `selection` & `search`,
 * since chrome may update to redesign with its align logic.
 */
.syngenta-ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 100%;
}
.syngenta-ant-select-selection-overflow-item {
  flex: none;
  align-self: center;
  max-width: 100%;
}
.syngenta-ant-select-multiple .syngenta-ant-select-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 4px;
}
.syngenta-ant-select-show-search.syngenta-ant-select-multiple .syngenta-ant-select-selector {
  cursor: text;
}
.syngenta-ant-select-disabled.syngenta-ant-select-multiple .syngenta-ant-select-selector {
  background: #f5f5f5;
  cursor: not-allowed;
}
.syngenta-ant-select-multiple .syngenta-ant-select-selector::after {
  display: inline-block;
  width: 0;
  margin: 2px 0;
  line-height: 24px;
  content: '\a0';
}
.syngenta-ant-select-multiple.syngenta-ant-select-show-arrow .syngenta-ant-select-selector,
.syngenta-ant-select-multiple.syngenta-ant-select-allow-clear .syngenta-ant-select-selector {
  padding-right: 24px;
}
.syngenta-ant-select-multiple .syngenta-ant-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 24px;
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 22px;
  background: #f5f5f5;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  cursor: default;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  user-select: none;
  margin-inline-end: 4px;
  padding-inline-start: 8px;
  padding-inline-end: 4px;
}
.syngenta-ant-select-disabled.syngenta-ant-select-multiple .syngenta-ant-select-selection-item {
  color: #bfbfbf;
  border-color: #e8eaed;
  cursor: not-allowed;
}
.syngenta-ant-select-multiple .syngenta-ant-select-selection-item-content {
  display: inline-block;
  margin-right: 4px;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}
.syngenta-ant-select-multiple .syngenta-ant-select-selection-item-remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  color: #707374;
  font-weight: bold;
  font-size: 10px;
  line-height: inherit;
  cursor: pointer;
}
.syngenta-ant-select-multiple .syngenta-ant-select-selection-item-remove > * {
  line-height: 1;
}
.syngenta-ant-select-multiple .syngenta-ant-select-selection-item-remove svg {
  display: inline-block;
}
.syngenta-ant-select-multiple .syngenta-ant-select-selection-item-remove::before {
  display: none;
}
.syngenta-ant-select-multiple .syngenta-ant-select-selection-item-remove .syngenta-ant-select-multiple .syngenta-ant-select-selection-item-remove-icon {
  display: block;
}
.syngenta-ant-select-multiple .syngenta-ant-select-selection-item-remove > .anticon {
  vertical-align: middle;
}
.syngenta-ant-select-multiple .syngenta-ant-select-selection-item-remove:hover {
  color: rgba(0, 0, 0, 0.75);
}
.syngenta-ant-select-multiple .syngenta-ant-select-selection-overflow-item + .syngenta-ant-select-selection-overflow-item .syngenta-ant-select-selection-search {
  margin-inline-start: 0;
}
.syngenta-ant-select-multiple .syngenta-ant-select-selection-search {
  position: relative;
  max-width: 100%;
  margin-inline-start: 7px;
}
.syngenta-ant-select-multiple .syngenta-ant-select-selection-search-input,
.syngenta-ant-select-multiple .syngenta-ant-select-selection-search-mirror {
  height: 24px;
  font-family: Rubik, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 24px;
  transition: all 0.3s;
}
.syngenta-ant-select-multiple .syngenta-ant-select-selection-search-input {
  width: 100%;
  min-width: 4.1px;
}
.syngenta-ant-select-multiple .syngenta-ant-select-selection-search-mirror {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  white-space: pre;
  visibility: hidden;
}
.syngenta-ant-select-multiple .syngenta-ant-select-selection-placeholder {
  position: absolute;
  top: 50%;
  right: 11px;
  left: 11px;
  transform: translateY(-50%);
  transition: all 0.3s;
}
.syngenta-ant-select-multiple.syngenta-ant-select-lg .syngenta-ant-select-selector::after {
  line-height: 32px;
}
.syngenta-ant-select-multiple.syngenta-ant-select-lg .syngenta-ant-select-selection-item {
  height: 32px;
  line-height: 30px;
}
.syngenta-ant-select-multiple.syngenta-ant-select-lg .syngenta-ant-select-selection-search {
  height: 32px;
  line-height: 32px;
}
.syngenta-ant-select-multiple.syngenta-ant-select-lg .syngenta-ant-select-selection-search-input,
.syngenta-ant-select-multiple.syngenta-ant-select-lg .syngenta-ant-select-selection-search-mirror {
  height: 32px;
  line-height: 30px;
}
.syngenta-ant-select-multiple.syngenta-ant-select-sm .syngenta-ant-select-selector::after {
  line-height: 16px;
}
.syngenta-ant-select-multiple.syngenta-ant-select-sm .syngenta-ant-select-selection-item {
  height: 16px;
  line-height: 14px;
}
.syngenta-ant-select-multiple.syngenta-ant-select-sm .syngenta-ant-select-selection-search {
  height: 16px;
  line-height: 16px;
}
.syngenta-ant-select-multiple.syngenta-ant-select-sm .syngenta-ant-select-selection-search-input,
.syngenta-ant-select-multiple.syngenta-ant-select-sm .syngenta-ant-select-selection-search-mirror {
  height: 16px;
  line-height: 14px;
}
.syngenta-ant-select-multiple.syngenta-ant-select-sm .syngenta-ant-select-selection-placeholder {
  left: 7px;
}
.syngenta-ant-select-multiple.syngenta-ant-select-sm .syngenta-ant-select-selection-search {
  margin-inline-start: 3px;
}
.syngenta-ant-select-multiple.syngenta-ant-select-lg .syngenta-ant-select-selection-item {
  height: 32px;
  line-height: 32px;
}
.syngenta-ant-select-disabled .syngenta-ant-select-selection-item-remove {
  display: none;
}
.syngenta-ant-select-status-error.syngenta-ant-select:not(.syngenta-ant-select-disabled):not(.syngenta-ant-select-customize-input):not(.syngenta-ant-pagination-size-changer) .syngenta-ant-select-selector {
  background-color: #f5f8fc;
  border-color: #ff4d4f !important;
}
.syngenta-ant-select-status-error.syngenta-ant-select:not(.syngenta-ant-select-disabled):not(.syngenta-ant-select-customize-input):not(.syngenta-ant-pagination-size-changer).syngenta-ant-select-open .syngenta-ant-select-selector,
.syngenta-ant-select-status-error.syngenta-ant-select:not(.syngenta-ant-select-disabled):not(.syngenta-ant-select-customize-input):not(.syngenta-ant-pagination-size-changer).syngenta-ant-select-focused .syngenta-ant-select-selector {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.syngenta-ant-select-status-warning.syngenta-ant-select:not(.syngenta-ant-select-disabled):not(.syngenta-ant-select-customize-input):not(.syngenta-ant-pagination-size-changer) .syngenta-ant-select-selector {
  background-color: #f5f8fc;
  border-color: #faad14 !important;
}
.syngenta-ant-select-status-warning.syngenta-ant-select:not(.syngenta-ant-select-disabled):not(.syngenta-ant-select-customize-input):not(.syngenta-ant-pagination-size-changer).syngenta-ant-select-open .syngenta-ant-select-selector,
.syngenta-ant-select-status-warning.syngenta-ant-select:not(.syngenta-ant-select-disabled):not(.syngenta-ant-select-customize-input):not(.syngenta-ant-pagination-size-changer).syngenta-ant-select-focused .syngenta-ant-select-selector {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.syngenta-ant-select-status-error.syngenta-ant-select-has-feedback .syngenta-ant-select-clear,
.syngenta-ant-select-status-warning.syngenta-ant-select-has-feedback .syngenta-ant-select-clear,
.syngenta-ant-select-status-success.syngenta-ant-select-has-feedback .syngenta-ant-select-clear,
.syngenta-ant-select-status-validating.syngenta-ant-select-has-feedback .syngenta-ant-select-clear {
  right: 32px;
}
.syngenta-ant-select-status-error.syngenta-ant-select-has-feedback .syngenta-ant-select-selection-selected-value,
.syngenta-ant-select-status-warning.syngenta-ant-select-has-feedback .syngenta-ant-select-selection-selected-value,
.syngenta-ant-select-status-success.syngenta-ant-select-has-feedback .syngenta-ant-select-selection-selected-value,
.syngenta-ant-select-status-validating.syngenta-ant-select-has-feedback .syngenta-ant-select-selection-selected-value {
  padding-right: 42px;
}
/* Reset search input style */
.syngenta-ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.syngenta-ant-select:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector {
  position: relative;
  background-color: #f5f8fc;
  border: 1px solid #e8eaed;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.syngenta-ant-select:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector input {
  cursor: pointer;
}
.syngenta-ant-select-show-search.syngenta-ant-select:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector {
  cursor: text;
}
.syngenta-ant-select-show-search.syngenta-ant-select:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector input {
  cursor: auto;
}
.syngenta-ant-select-focused:not(.syngenta-ant-select-disabled).syngenta-ant-select:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector {
  border-color: #0d0d0d;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.syngenta-ant-input-rtl .syngenta-ant-select-focused:not(.syngenta-ant-select-disabled).syngenta-ant-select:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syngenta-ant-select-disabled.syngenta-ant-select:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.syngenta-ant-select-multiple.syngenta-ant-select-disabled.syngenta-ant-select:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector {
  background: #f5f5f5;
}
.syngenta-ant-select-disabled.syngenta-ant-select:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector input {
  cursor: not-allowed;
}
.syngenta-ant-select:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector .syngenta-ant-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  appearance: none;
}
.syngenta-ant-select:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector .syngenta-ant-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
}
.syngenta-ant-select:not(.syngenta-ant-select-disabled):hover .syngenta-ant-select-selector {
  border-color: #0d0d0d;
  border-right-width: 1px;
}
.syngenta-ant-input-rtl .syngenta-ant-select:not(.syngenta-ant-select-disabled):hover .syngenta-ant-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syngenta-ant-select-selection-item {
  flex: 1;
  overflow: hidden;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media all and (-ms-high-contrast: none) {
  .syngenta-ant-select-selection-item *::-ms-backdrop,
  .syngenta-ant-select-selection-item {
    flex: auto;
  }
}
.syngenta-ant-select-selection-placeholder {
  flex: 1;
  overflow: hidden;
  color: #bfbfbf;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
@media all and (-ms-high-contrast: none) {
  .syngenta-ant-select-selection-placeholder *::-ms-backdrop,
  .syngenta-ant-select-selection-placeholder {
    flex: auto;
  }
}
.syngenta-ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}
.syngenta-ant-select-arrow > * {
  line-height: 1;
}
.syngenta-ant-select-arrow svg {
  display: inline-block;
}
.syngenta-ant-select-arrow::before {
  display: none;
}
.syngenta-ant-select-arrow .syngenta-ant-select-arrow-icon {
  display: block;
}
.syngenta-ant-select-arrow .anticon {
  vertical-align: top;
  transition: transform 0.3s;
}
.syngenta-ant-select-arrow .anticon > svg {
  vertical-align: top;
}
.syngenta-ant-select-arrow .anticon:not(.syngenta-ant-select-suffix) {
  pointer-events: auto;
}
.syngenta-ant-select-disabled .syngenta-ant-select-arrow {
  cursor: not-allowed;
}
.syngenta-ant-select-arrow > *:not(:last-child) {
  margin-inline-end: 8px;
}
.syngenta-ant-select-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: #f5f8fc;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.syngenta-ant-select-clear::before {
  display: block;
}
.syngenta-ant-select-clear:hover {
  color: #707374;
}
.syngenta-ant-select:hover .syngenta-ant-select-clear {
  opacity: 1;
}
.syngenta-ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  padding: 4px 0;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #f5f8fc;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.syngenta-ant-select-dropdown.syngenta-ant-slide-up-enter.syngenta-ant-slide-up-enter-active.syngenta-ant-select-dropdown-placement-bottomLeft,
.syngenta-ant-select-dropdown.syngenta-ant-slide-up-appear.syngenta-ant-slide-up-appear-active.syngenta-ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpIn;
}
.syngenta-ant-select-dropdown.syngenta-ant-slide-up-enter.syngenta-ant-slide-up-enter-active.syngenta-ant-select-dropdown-placement-topLeft,
.syngenta-ant-select-dropdown.syngenta-ant-slide-up-appear.syngenta-ant-slide-up-appear-active.syngenta-ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownIn;
}
.syngenta-ant-select-dropdown.syngenta-ant-slide-up-leave.syngenta-ant-slide-up-leave-active.syngenta-ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpOut;
}
.syngenta-ant-select-dropdown.syngenta-ant-slide-up-leave.syngenta-ant-slide-up-leave-active.syngenta-ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownOut;
}
.syngenta-ant-select-dropdown-hidden {
  display: none;
}
.syngenta-ant-select-dropdown-empty {
  color: rgba(0, 0, 0, 0.25);
}
.syngenta-ant-select-item-empty {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: #2f3031;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.25);
}
.syngenta-ant-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: #2f3031;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.syngenta-ant-select-item-group {
  color: #707374;
  font-size: 12px;
  cursor: default;
}
.syngenta-ant-select-item-option {
  display: flex;
}
.syngenta-ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syngenta-ant-select-item-option-state {
  flex: none;
}
.syngenta-ant-select-item-option-active:not(.syngenta-ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}
.syngenta-ant-select-item-option-selected:not(.syngenta-ant-select-item-option-disabled) {
  color: #2f3031;
  font-weight: 600;
  background-color: #404040;
}
.syngenta-ant-select-item-option-selected:not(.syngenta-ant-select-item-option-disabled) .syngenta-ant-select-item-option-state {
  color: #000;
}
.syngenta-ant-select-item-option-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.syngenta-ant-select-item-option-disabled.syngenta-ant-select-item-option-selected {
  background-color: #f5f5f5;
}
.syngenta-ant-select-item-option-grouped {
  padding-left: 24px;
}
.syngenta-ant-select-lg {
  font-size: 16px;
}
.syngenta-ant-select-borderless .syngenta-ant-select-selector {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.syngenta-ant-select.syngenta-ant-select-in-form-item {
  width: 100%;
}
.syngenta-ant-select-compact-item:not(.syngenta-ant-select-compact-last-item) {
  margin-right: -1px;
}
.syngenta-ant-select-compact-item:not(.syngenta-ant-select-compact-last-item).syngenta-ant-select-compact-item-rtl {
  margin-right: 0;
  margin-left: -1px;
}
.syngenta-ant-select-compact-item:hover > *,
.syngenta-ant-select-compact-item:focus > *,
.syngenta-ant-select-compact-item:active > * {
  z-index: 2;
}
.syngenta-ant-select-compact-item.syngenta-ant-select-focused > * {
  z-index: 2;
}
.syngenta-ant-select-compact-item[disabled] > * {
  z-index: 0;
}
.syngenta-ant-select-compact-item:not(.syngenta-ant-select-compact-first-item):not(.syngenta-ant-select-compact-last-item).syngenta-ant-select > .syngenta-ant-select-selector {
  border-radius: 0;
}
.syngenta-ant-select-compact-item.syngenta-ant-select-compact-first-item.syngenta-ant-select:not(.syngenta-ant-select-compact-last-item):not(.syngenta-ant-select-compact-item-rtl) > .syngenta-ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syngenta-ant-select-compact-item.syngenta-ant-select-compact-last-item.syngenta-ant-select:not(.syngenta-ant-select-compact-first-item):not(.syngenta-ant-select-compact-item-rtl) > .syngenta-ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syngenta-ant-select-compact-item.syngenta-ant-select.syngenta-ant-select-compact-first-item.syngenta-ant-select-compact-item-rtl:not(.syngenta-ant-select-compact-last-item) > .syngenta-ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syngenta-ant-select-compact-item.syngenta-ant-select.syngenta-ant-select-compact-last-item.syngenta-ant-select-compact-item-rtl:not(.syngenta-ant-select-compact-first-item) > .syngenta-ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syngenta-ant-select-rtl {
  direction: rtl;
}
.syngenta-ant-select-rtl .syngenta-ant-select-arrow {
  right: initial;
  left: 11px;
}
.syngenta-ant-select-rtl .syngenta-ant-select-clear {
  right: initial;
  left: 11px;
}
.syngenta-ant-select-dropdown-rtl {
  direction: rtl;
}
.syngenta-ant-select-dropdown-rtl .syngenta-ant-select-item-option-grouped {
  padding-right: 24px;
  padding-left: 12px;
}
.syngenta-ant-select-rtl.syngenta-ant-select-multiple.syngenta-ant-select-show-arrow .syngenta-ant-select-selector,
.syngenta-ant-select-rtl.syngenta-ant-select-multiple.syngenta-ant-select-allow-clear .syngenta-ant-select-selector {
  padding-right: 4px;
  padding-left: 24px;
}
.syngenta-ant-select-rtl.syngenta-ant-select-multiple .syngenta-ant-select-selection-item {
  text-align: right;
}
.syngenta-ant-select-rtl.syngenta-ant-select-multiple .syngenta-ant-select-selection-item-content {
  margin-right: 0;
  margin-left: 4px;
  text-align: right;
}
.syngenta-ant-select-rtl.syngenta-ant-select-multiple .syngenta-ant-select-selection-search-mirror {
  right: 0;
  left: auto;
}
.syngenta-ant-select-rtl.syngenta-ant-select-multiple .syngenta-ant-select-selection-placeholder {
  right: 11px;
  left: auto;
}
.syngenta-ant-select-rtl.syngenta-ant-select-multiple.syngenta-ant-select-sm .syngenta-ant-select-selection-placeholder {
  right: 7px;
}
.syngenta-ant-select-rtl.syngenta-ant-select-single .syngenta-ant-select-selector .syngenta-ant-select-selection-item,
.syngenta-ant-select-rtl.syngenta-ant-select-single .syngenta-ant-select-selector .syngenta-ant-select-selection-placeholder {
  right: 0;
  left: 9px;
  text-align: right;
}
.syngenta-ant-select-rtl.syngenta-ant-select-single.syngenta-ant-select-show-arrow .syngenta-ant-select-selection-search {
  right: 11px;
  left: 25px;
}
.syngenta-ant-select-rtl.syngenta-ant-select-single.syngenta-ant-select-show-arrow .syngenta-ant-select-selection-item,
.syngenta-ant-select-rtl.syngenta-ant-select-single.syngenta-ant-select-show-arrow .syngenta-ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 18px;
}
.syngenta-ant-select-rtl.syngenta-ant-select-single.syngenta-ant-select-sm:not(.syngenta-ant-select-customize-input).syngenta-ant-select-show-arrow .syngenta-ant-select-selection-search {
  right: 6px;
}
.syngenta-ant-select-rtl.syngenta-ant-select-single.syngenta-ant-select-sm:not(.syngenta-ant-select-customize-input).syngenta-ant-select-show-arrow .syngenta-ant-select-selection-item,
.syngenta-ant-select-rtl.syngenta-ant-select-single.syngenta-ant-select-sm:not(.syngenta-ant-select-customize-input).syngenta-ant-select-show-arrow .syngenta-ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 21px;
}
.syngenta-ant-skeleton {
  display: table;
  width: 100%;
}
.syngenta-ant-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top;
}
.syngenta-ant-skeleton-header .syngenta-ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.syngenta-ant-skeleton-header .syngenta-ant-skeleton-avatar.syngenta-ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.syngenta-ant-skeleton-header .syngenta-ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.syngenta-ant-skeleton-header .syngenta-ant-skeleton-avatar-lg.syngenta-ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.syngenta-ant-skeleton-header .syngenta-ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.syngenta-ant-skeleton-header .syngenta-ant-skeleton-avatar-sm.syngenta-ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.syngenta-ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
.syngenta-ant-skeleton-content .syngenta-ant-skeleton-title {
  width: 100%;
  height: 16px;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 2px;
}
.syngenta-ant-skeleton-content .syngenta-ant-skeleton-title + .syngenta-ant-skeleton-paragraph {
  margin-top: 24px;
}
.syngenta-ant-skeleton-content .syngenta-ant-skeleton-paragraph {
  padding: 0;
}
.syngenta-ant-skeleton-content .syngenta-ant-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 2px;
}
.syngenta-ant-skeleton-content .syngenta-ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
.syngenta-ant-skeleton-content .syngenta-ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}
.syngenta-ant-skeleton-with-avatar .syngenta-ant-skeleton-content .syngenta-ant-skeleton-title {
  margin-top: 12px;
}
.syngenta-ant-skeleton-with-avatar .syngenta-ant-skeleton-content .syngenta-ant-skeleton-title + .syngenta-ant-skeleton-paragraph {
  margin-top: 28px;
}
.syngenta-ant-skeleton-round .syngenta-ant-skeleton-content .syngenta-ant-skeleton-title,
.syngenta-ant-skeleton-round .syngenta-ant-skeleton-content .syngenta-ant-skeleton-paragraph > li {
  border-radius: 100px;
}
.syngenta-ant-skeleton-active .syngenta-ant-skeleton-title,
.syngenta-ant-skeleton-active .syngenta-ant-skeleton-paragraph > li,
.syngenta-ant-skeleton-active .syngenta-ant-skeleton-avatar,
.syngenta-ant-skeleton-active .syngenta-ant-skeleton-button,
.syngenta-ant-skeleton-active .syngenta-ant-skeleton-input,
.syngenta-ant-skeleton-active .syngenta-ant-skeleton-image {
  position: relative;
  /* stylelint-disable-next-line property-no-vendor-prefix,value-no-vendor-prefix */
  z-index: 0;
  overflow: hidden;
  background: transparent;
}
.syngenta-ant-skeleton-active .syngenta-ant-skeleton-title::after,
.syngenta-ant-skeleton-active .syngenta-ant-skeleton-paragraph > li::after,
.syngenta-ant-skeleton-active .syngenta-ant-skeleton-avatar::after,
.syngenta-ant-skeleton-active .syngenta-ant-skeleton-button::after,
.syngenta-ant-skeleton-active .syngenta-ant-skeleton-input::after,
.syngenta-ant-skeleton-active .syngenta-ant-skeleton-image::after {
  position: absolute;
  top: 0;
  right: -150%;
  bottom: 0;
  left: -150%;
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  animation: syngenta-ant-skeleton-loading 1.4s ease infinite;
  content: '';
}
.syngenta-ant-skeleton.syngenta-ant-skeleton-block {
  width: 100%;
}
.syngenta-ant-skeleton.syngenta-ant-skeleton-block .syngenta-ant-skeleton-button {
  width: 100%;
}
.syngenta-ant-skeleton.syngenta-ant-skeleton-block .syngenta-ant-skeleton-input {
  width: 100%;
}
.syngenta-ant-skeleton-element {
  display: inline-block;
  width: auto;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-button {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 2px;
  width: 64px;
  min-width: 64px;
  height: 32px;
  line-height: 32px;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-button.syngenta-ant-skeleton-button-square {
  width: 32px;
  min-width: 32px;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-button.syngenta-ant-skeleton-button-circle {
  width: 32px;
  min-width: 32px;
  border-radius: 50%;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-button.syngenta-ant-skeleton-button-round {
  border-radius: 32px;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-button-lg {
  width: 80px;
  min-width: 80px;
  height: 40px;
  line-height: 40px;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-button-lg.syngenta-ant-skeleton-button-square {
  width: 40px;
  min-width: 40px;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-button-lg.syngenta-ant-skeleton-button-circle {
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-button-lg.syngenta-ant-skeleton-button-round {
  border-radius: 40px;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-button-sm {
  width: 48px;
  min-width: 48px;
  height: 24px;
  line-height: 24px;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-button-sm.syngenta-ant-skeleton-button-square {
  width: 24px;
  min-width: 24px;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-button-sm.syngenta-ant-skeleton-button-circle {
  width: 24px;
  min-width: 24px;
  border-radius: 50%;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-button-sm.syngenta-ant-skeleton-button-round {
  border-radius: 24px;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-avatar.syngenta-ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-avatar-lg.syngenta-ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-avatar-sm.syngenta-ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-input {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 160px;
  min-width: 160px;
  height: 32px;
  line-height: 32px;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-input-lg {
  width: 200px;
  min-width: 200px;
  height: 40px;
  line-height: 40px;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-input-sm {
  width: 120px;
  min-width: 120px;
  height: 24px;
  line-height: 24px;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-image {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 96px;
  height: 96px;
  line-height: 96px;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-image.syngenta-ant-skeleton-image-circle {
  border-radius: 50%;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-image-path {
  fill: #bfbfbf;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-image-svg {
  width: 48px;
  height: 48px;
  line-height: 48px;
  max-width: 192px;
  max-height: 192px;
}
.syngenta-ant-skeleton-element .syngenta-ant-skeleton-image-svg.syngenta-ant-skeleton-image-circle {
  border-radius: 50%;
}
@keyframes syngenta-ant-skeleton-loading {
  0% {
    transform: translateX(-37.5%);
  }
  100% {
    transform: translateX(37.5%);
  }
}
.syngenta-ant-skeleton-rtl {
  direction: rtl;
}
.syngenta-ant-skeleton-rtl .syngenta-ant-skeleton-header {
  padding-right: 0;
  padding-left: 16px;
}
.syngenta-ant-skeleton-rtl.syngenta-ant-skeleton.syngenta-ant-skeleton-active .syngenta-ant-skeleton-content .syngenta-ant-skeleton-title,
.syngenta-ant-skeleton-rtl.syngenta-ant-skeleton.syngenta-ant-skeleton-active .syngenta-ant-skeleton-content .syngenta-ant-skeleton-paragraph > li {
  animation-name: syngenta-ant-skeleton-loading-rtl;
}
.syngenta-ant-skeleton-rtl.syngenta-ant-skeleton.syngenta-ant-skeleton-active .syngenta-ant-skeleton-avatar {
  animation-name: syngenta-ant-skeleton-loading-rtl;
}
@keyframes syngenta-ant-skeleton-loading-rtl {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
.syngenta-ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}
.syngenta-ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}
.syngenta-ant-empty-image img {
  height: 100%;
}
.syngenta-ant-empty-image svg {
  height: 100%;
  margin: auto;
}
.syngenta-ant-empty-footer {
  margin-top: 16px;
}
.syngenta-ant-empty-normal {
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25);
}
.syngenta-ant-empty-normal .syngenta-ant-empty-image {
  height: 40px;
}
.syngenta-ant-empty-small {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.25);
}
.syngenta-ant-empty-small .syngenta-ant-empty-image {
  height: 35px;
}
.syngenta-ant-empty-img-default-ellipse {
  fill: #f5f5f5;
  fill-opacity: 0.8;
}
.syngenta-ant-empty-img-default-path-1 {
  fill: #aeb8c2;
}
.syngenta-ant-empty-img-default-path-2 {
  fill: url('#linearGradient-1');
}
.syngenta-ant-empty-img-default-path-3 {
  fill: #f5f5f7;
}
.syngenta-ant-empty-img-default-path-4 {
  fill: #dce0e6;
}
.syngenta-ant-empty-img-default-path-5 {
  fill: #dce0e6;
}
.syngenta-ant-empty-img-default-g {
  fill: #fff;
}
.syngenta-ant-empty-img-simple-ellipse {
  fill: #f5f5f5;
}
.syngenta-ant-empty-img-simple-g {
  stroke: #d9d9d9;
}
.syngenta-ant-empty-img-simple-path {
  fill: #fafafa;
}
.syngenta-ant-empty-rtl {
  direction: rtl;
}
@keyframes ant-tree-node-fx-do-not-use {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.syngenta-ant-tree.syngenta-ant-tree-directory .syngenta-ant-tree-treenode {
  position: relative;
}
.syngenta-ant-tree.syngenta-ant-tree-directory .syngenta-ant-tree-treenode::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  transition: background-color 0.3s;
  content: '';
  pointer-events: none;
}
.syngenta-ant-tree.syngenta-ant-tree-directory .syngenta-ant-tree-treenode:hover::before {
  background: #f5f5f5;
}
.syngenta-ant-tree.syngenta-ant-tree-directory .syngenta-ant-tree-treenode > * {
  z-index: 1;
}
.syngenta-ant-tree.syngenta-ant-tree-directory .syngenta-ant-tree-treenode .syngenta-ant-tree-switcher {
  transition: color 0.3s;
}
.syngenta-ant-tree.syngenta-ant-tree-directory .syngenta-ant-tree-treenode .syngenta-ant-tree-node-content-wrapper {
  border-radius: 0;
  user-select: none;
}
.syngenta-ant-tree.syngenta-ant-tree-directory .syngenta-ant-tree-treenode .syngenta-ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.syngenta-ant-tree.syngenta-ant-tree-directory .syngenta-ant-tree-treenode .syngenta-ant-tree-node-content-wrapper.syngenta-ant-tree-node-selected {
  color: #fff;
  background: transparent;
}
.syngenta-ant-tree.syngenta-ant-tree-directory .syngenta-ant-tree-treenode-selected:hover::before,
.syngenta-ant-tree.syngenta-ant-tree-directory .syngenta-ant-tree-treenode-selected::before {
  background: #000;
}
.syngenta-ant-tree.syngenta-ant-tree-directory .syngenta-ant-tree-treenode-selected .syngenta-ant-tree-switcher {
  color: #fff;
}
.syngenta-ant-tree.syngenta-ant-tree-directory .syngenta-ant-tree-treenode-selected .syngenta-ant-tree-node-content-wrapper {
  color: #fff;
  background: transparent;
}
.syngenta-ant-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.syngenta-ant-tree-checkbox-wrapper:hover .syngenta-ant-tree-checkbox-inner,
.syngenta-ant-tree-checkbox:hover .syngenta-ant-tree-checkbox-inner,
.syngenta-ant-tree-checkbox-input:focus + .syngenta-ant-tree-checkbox-inner {
  border-color: #000;
}
.syngenta-ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #000;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.syngenta-ant-tree-checkbox:hover::after,
.syngenta-ant-tree-checkbox-wrapper:hover .syngenta-ant-tree-checkbox::after {
  visibility: visible;
}
.syngenta-ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #e8eaed;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.syngenta-ant-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.syngenta-ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.syngenta-ant-tree-checkbox-checked .syngenta-ant-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.syngenta-ant-tree-checkbox-checked .syngenta-ant-tree-checkbox-inner {
  background-color: #000;
  border-color: #000;
}
.syngenta-ant-tree-checkbox-disabled {
  cursor: not-allowed;
}
.syngenta-ant-tree-checkbox-disabled.syngenta-ant-tree-checkbox-checked .syngenta-ant-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.syngenta-ant-tree-checkbox-disabled .syngenta-ant-tree-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.syngenta-ant-tree-checkbox-disabled .syngenta-ant-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #e8eaed !important;
}
.syngenta-ant-tree-checkbox-disabled .syngenta-ant-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.syngenta-ant-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.syngenta-ant-tree-checkbox-disabled:hover::after,
.syngenta-ant-tree-checkbox-wrapper:hover .syngenta-ant-tree-checkbox-disabled::after {
  visibility: hidden;
}
.syngenta-ant-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.syngenta-ant-tree-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.syngenta-ant-tree-checkbox-wrapper.syngenta-ant-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.syngenta-ant-tree-checkbox-wrapper + .syngenta-ant-tree-checkbox-wrapper {
  margin-left: 8px;
}
.syngenta-ant-tree-checkbox-wrapper.syngenta-ant-tree-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.syngenta-ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.syngenta-ant-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.syngenta-ant-tree-checkbox-group-item {
  margin-right: 8px;
}
.syngenta-ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.syngenta-ant-tree-checkbox-group-item + .syngenta-ant-tree-checkbox-group-item {
  margin-left: 0;
}
.syngenta-ant-tree-checkbox-indeterminate .syngenta-ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #e8eaed;
}
.syngenta-ant-tree-checkbox-indeterminate .syngenta-ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #000;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.syngenta-ant-tree-checkbox-indeterminate.syngenta-ant-tree-checkbox-disabled .syngenta-ant-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.syngenta-ant-tree-checkbox-rtl {
  direction: rtl;
}
.syngenta-ant-tree-checkbox-group-rtl .syngenta-ant-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.syngenta-ant-tree-checkbox-group-rtl .syngenta-ant-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.syngenta-ant-tree-checkbox-group-rtl .syngenta-ant-tree-checkbox-group-item + .syngenta-ant-tree-checkbox-group-item {
  margin-left: 8px;
}
.syngenta-ant-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #fff;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.syngenta-ant-tree-focused:not(:hover):not(.syngenta-ant-tree-active-focused) {
  background: #404040;
}
.syngenta-ant-tree-list-holder-inner {
  align-items: flex-start;
}
.syngenta-ant-tree.syngenta-ant-tree-block-node .syngenta-ant-tree-list-holder-inner {
  align-items: stretch;
}
.syngenta-ant-tree.syngenta-ant-tree-block-node .syngenta-ant-tree-list-holder-inner .syngenta-ant-tree-node-content-wrapper {
  flex: auto;
}
.syngenta-ant-tree.syngenta-ant-tree-block-node .syngenta-ant-tree-list-holder-inner .syngenta-ant-tree-treenode.dragging {
  position: relative;
}
.syngenta-ant-tree.syngenta-ant-tree-block-node .syngenta-ant-tree-list-holder-inner .syngenta-ant-tree-treenode.dragging::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  border: 1px solid #000;
  opacity: 0;
  animation: ant-tree-node-fx-do-not-use 0.3s;
  animation-play-state: running;
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
.syngenta-ant-tree .syngenta-ant-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.syngenta-ant-tree .syngenta-ant-tree-treenode-disabled .syngenta-ant-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.syngenta-ant-tree .syngenta-ant-tree-treenode-disabled .syngenta-ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.syngenta-ant-tree .syngenta-ant-tree-treenode-active .syngenta-ant-tree-node-content-wrapper {
  background: #f5f5f5;
}
.syngenta-ant-tree .syngenta-ant-tree-treenode:not(.syngenta-ant-tree .syngenta-ant-tree-treenode-disabled).filter-node .syngenta-ant-tree-title {
  color: inherit;
  font-weight: 500;
}
.syngenta-ant-tree .syngenta-ant-tree-treenode-draggable .syngenta-ant-tree-draggable-icon {
  width: 24px;
  line-height: 24px;
  text-align: center;
  visibility: visible;
  opacity: 0.2;
  transition: opacity 0.3s;
}
.syngenta-ant-tree-treenode:hover .syngenta-ant-tree .syngenta-ant-tree-treenode-draggable .syngenta-ant-tree-draggable-icon {
  opacity: 0.45;
}
.syngenta-ant-tree .syngenta-ant-tree-treenode-draggable.syngenta-ant-tree-treenode-disabled .syngenta-ant-tree-draggable-icon {
  visibility: hidden;
}
.syngenta-ant-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  user-select: none;
}
.syngenta-ant-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.syngenta-ant-tree-draggable-icon {
  visibility: hidden;
}
.syngenta-ant-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.syngenta-ant-tree-switcher .syngenta-ant-tree-switcher-icon,
.syngenta-ant-tree-switcher .syngenta-ant-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.syngenta-ant-tree-switcher .syngenta-ant-tree-switcher-icon svg,
.syngenta-ant-tree-switcher .syngenta-ant-select-tree-switcher-icon svg {
  transition: transform 0.3s;
}
.syngenta-ant-tree-switcher-noop {
  cursor: default;
}
.syngenta-ant-tree-switcher_close .syngenta-ant-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.syngenta-ant-tree-switcher-loading-icon {
  color: #000;
}
.syngenta-ant-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.syngenta-ant-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  margin-left: -1px;
  border-right: 1px solid #d9d9d9;
  content: ' ';
}
.syngenta-ant-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  border-bottom: 1px solid #d9d9d9;
  content: ' ';
}
.syngenta-ant-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}
.syngenta-ant-tree .syngenta-ant-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.syngenta-ant-tree .syngenta-ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.syngenta-ant-tree .syngenta-ant-tree-node-content-wrapper.syngenta-ant-tree-node-selected {
  background-color: #333333;
}
.syngenta-ant-tree .syngenta-ant-tree-node-content-wrapper .syngenta-ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.syngenta-ant-tree .syngenta-ant-tree-node-content-wrapper .syngenta-ant-tree-iconEle:empty {
  display: none;
}
.syngenta-ant-tree-unselectable .syngenta-ant-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.syngenta-ant-tree-node-content-wrapper {
  line-height: 24px;
  user-select: none;
}
.syngenta-ant-tree-node-content-wrapper .syngenta-ant-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: #000;
  border-radius: 1px;
  pointer-events: none;
}
.syngenta-ant-tree-node-content-wrapper .syngenta-ant-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #000;
  border-radius: 50%;
  content: '';
}
.syngenta-ant-tree .syngenta-ant-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #000;
}
.syngenta-ant-tree-show-line .syngenta-ant-tree-indent-unit {
  position: relative;
  height: 100%;
}
.syngenta-ant-tree-show-line .syngenta-ant-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #e8eaed;
  content: '';
}
.syngenta-ant-tree-show-line .syngenta-ant-tree-indent-unit-end::before {
  display: none;
}
.syngenta-ant-tree-show-line .syngenta-ant-tree-switcher {
  background: #fff;
}
.syngenta-ant-tree-show-line .syngenta-ant-tree-switcher-line-icon {
  vertical-align: -0.15em;
}
.syngenta-ant-tree .syngenta-ant-tree-treenode-leaf-last .syngenta-ant-tree-switcher-leaf-line::before {
  top: auto !important;
  bottom: auto !important;
  height: 14px !important;
}
.syngenta-ant-tree-rtl {
  direction: rtl;
}
.syngenta-ant-tree-rtl .syngenta-ant-tree-node-content-wrapper[draggable='true'] .syngenta-ant-tree-drop-indicator::after {
  right: -6px;
  left: unset;
}
.syngenta-ant-tree .syngenta-ant-tree-treenode-rtl {
  direction: rtl;
}
.syngenta-ant-tree-rtl .syngenta-ant-tree-switcher_close .syngenta-ant-tree-switcher-icon svg {
  transform: rotate(90deg);
}
.syngenta-ant-tree-rtl.syngenta-ant-tree-show-line .syngenta-ant-tree-indent-unit::before {
  right: auto;
  left: -13px;
  border-right: none;
  border-left: 1px solid #e8eaed;
}
.syngenta-ant-tree-rtl .syngenta-ant-tree-checkbox {
  margin: 4px 0 0 8px;
}
.syngenta-ant-tree-select-dropdown-rtl .syngenta-ant-select-tree-checkbox {
  margin: 4px 0 0 8px;
}
.syngenta-ant-drawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;
}
.syngenta-ant-drawer-inline {
  position: absolute;
}
.syngenta-ant-drawer-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.45);
  pointer-events: auto;
}
.syngenta-ant-drawer-content-wrapper {
  position: absolute;
  z-index: 1000;
  transition: all 0.3s;
}
.syngenta-ant-drawer-content-wrapper-hidden {
  display: none;
}
.syngenta-ant-drawer-left > .syngenta-ant-drawer-content-wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.syngenta-ant-drawer-right > .syngenta-ant-drawer-content-wrapper {
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.syngenta-ant-drawer-top > .syngenta-ant-drawer-content-wrapper {
  top: 0;
  right: 0;
  left: 0;
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}
.syngenta-ant-drawer-bottom > .syngenta-ant-drawer-content-wrapper {
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}
.syngenta-ant-drawer-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fff;
  pointer-events: auto;
}
.syngenta-ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.syngenta-ant-drawer-header {
  display: flex;
  flex: 0;
  align-items: center;
  padding: 16px 24px;
  font-size: 16px;
  line-height: 22px;
  border-bottom: 1px solid #f0f0f0;
}
.syngenta-ant-drawer-header-title {
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.syngenta-ant-drawer-extra {
  flex: none;
}
.syngenta-ant-drawer-close {
  display: inline-block;
  margin-right: 12px;
  color: #707374;
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.syngenta-ant-drawer-close:focus,
.syngenta-ant-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.syngenta-ant-drawer-title {
  flex: 1;
  margin: 0;
  color: #707374;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.syngenta-ant-drawer-body {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 24px;
  overflow: auto;
}
.syngenta-ant-drawer-footer {
  flex-shrink: 0;
  padding: 10px 16px;
  border-top: 1px solid #f0f0f0;
}
.panel-motion-enter-start,
.panel-motion-appear-start,
.panel-motion-leave-start {
  transition: none;
}
.panel-motion-enter-active,
.panel-motion-appear-active,
.panel-motion-leave-active {
  transition: all 0.3s;
}
.syngenta-ant-drawer-mask-motion-enter-active,
.syngenta-ant-drawer-mask-motion-appear-active,
.syngenta-ant-drawer-mask-motion-leave-active {
  transition: all 0.3s;
}
.syngenta-ant-drawer-mask-motion-enter,
.syngenta-ant-drawer-mask-motion-appear {
  opacity: 0;
}
.syngenta-ant-drawer-mask-motion-enter-active,
.syngenta-ant-drawer-mask-motion-appear-active {
  opacity: 1;
}
.syngenta-ant-drawer-mask-motion-leave {
  opacity: 1;
}
.syngenta-ant-drawer-mask-motion-leave-active {
  opacity: 0;
}
.syngenta-ant-drawer-panel-motion-left-enter-start,
.syngenta-ant-drawer-panel-motion-left-appear-start,
.syngenta-ant-drawer-panel-motion-left-leave-start {
  transition: none;
}
.syngenta-ant-drawer-panel-motion-left-enter-active,
.syngenta-ant-drawer-panel-motion-left-appear-active,
.syngenta-ant-drawer-panel-motion-left-leave-active {
  transition: all 0.3s;
}
.syngenta-ant-drawer-panel-motion-left-enter-start,
.syngenta-ant-drawer-panel-motion-left-appear-start {
  transform: translateX(-100%) !important;
}
.syngenta-ant-drawer-panel-motion-left-enter-active,
.syngenta-ant-drawer-panel-motion-left-appear-active {
  transform: translateX(0);
}
.syngenta-ant-drawer-panel-motion-left-leave {
  transform: translateX(0);
}
.syngenta-ant-drawer-panel-motion-left-leave-active {
  transform: translateX(-100%);
}
.syngenta-ant-drawer-panel-motion-right-enter-start,
.syngenta-ant-drawer-panel-motion-right-appear-start,
.syngenta-ant-drawer-panel-motion-right-leave-start {
  transition: none;
}
.syngenta-ant-drawer-panel-motion-right-enter-active,
.syngenta-ant-drawer-panel-motion-right-appear-active,
.syngenta-ant-drawer-panel-motion-right-leave-active {
  transition: all 0.3s;
}
.syngenta-ant-drawer-panel-motion-right-enter-start,
.syngenta-ant-drawer-panel-motion-right-appear-start {
  transform: translateX(100%) !important;
}
.syngenta-ant-drawer-panel-motion-right-enter-active,
.syngenta-ant-drawer-panel-motion-right-appear-active {
  transform: translateX(0);
}
.syngenta-ant-drawer-panel-motion-right-leave {
  transform: translateX(0);
}
.syngenta-ant-drawer-panel-motion-right-leave-active {
  transform: translateX(100%);
}
.syngenta-ant-drawer-panel-motion-top-enter-start,
.syngenta-ant-drawer-panel-motion-top-appear-start,
.syngenta-ant-drawer-panel-motion-top-leave-start {
  transition: none;
}
.syngenta-ant-drawer-panel-motion-top-enter-active,
.syngenta-ant-drawer-panel-motion-top-appear-active,
.syngenta-ant-drawer-panel-motion-top-leave-active {
  transition: all 0.3s;
}
.syngenta-ant-drawer-panel-motion-top-enter-start,
.syngenta-ant-drawer-panel-motion-top-appear-start {
  transform: translateY(-100%) !important;
}
.syngenta-ant-drawer-panel-motion-top-enter-active,
.syngenta-ant-drawer-panel-motion-top-appear-active {
  transform: translateY(0);
}
.syngenta-ant-drawer-panel-motion-top-leave {
  transform: translateY(0);
}
.syngenta-ant-drawer-panel-motion-top-leave-active {
  transform: translateY(-100%);
}
.syngenta-ant-drawer-panel-motion-bottom-enter-start,
.syngenta-ant-drawer-panel-motion-bottom-appear-start,
.syngenta-ant-drawer-panel-motion-bottom-leave-start {
  transition: none;
}
.syngenta-ant-drawer-panel-motion-bottom-enter-active,
.syngenta-ant-drawer-panel-motion-bottom-appear-active,
.syngenta-ant-drawer-panel-motion-bottom-leave-active {
  transition: all 0.3s;
}
.syngenta-ant-drawer-panel-motion-bottom-enter-start,
.syngenta-ant-drawer-panel-motion-bottom-appear-start {
  transform: translateY(100%) !important;
}
.syngenta-ant-drawer-panel-motion-bottom-enter-active,
.syngenta-ant-drawer-panel-motion-bottom-appear-active {
  transform: translateY(0);
}
.syngenta-ant-drawer-panel-motion-bottom-leave {
  transform: translateY(0);
}
.syngenta-ant-drawer-panel-motion-bottom-leave-active {
  transform: translateY(100%);
}
.syngenta-ant-drawer-rtl {
  direction: rtl;
}
.syngenta-ant-drawer-rtl .syngenta-ant-drawer-close {
  margin-right: 0;
  margin-left: 12px;
}
.syngenta-ant-input-number-affix-wrapper {
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: #00a0be;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #f5f8fc;
  background-image: none;
  border: 1px solid #e8eaed;
  border-radius: 2px;
  transition: all 0.3s;
  position: relative;
  display: inline-flex;
  width: 90px;
  padding: 0;
  padding-inline-start: 11px;
}
.syngenta-ant-input-number-affix-wrapper::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.syngenta-ant-input-number-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}
.syngenta-ant-input-number-affix-wrapper:hover {
  border-color: #0d0d0d;
  border-right-width: 1px;
}
.syngenta-ant-input-rtl .syngenta-ant-input-number-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syngenta-ant-input-number-affix-wrapper:focus,
.syngenta-ant-input-number-affix-wrapper-focused {
  border-color: #0d0d0d;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.syngenta-ant-input-rtl .syngenta-ant-input-number-affix-wrapper:focus,
.syngenta-ant-input-rtl .syngenta-ant-input-number-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syngenta-ant-input-number-affix-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #e8eaed;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.syngenta-ant-input-number-affix-wrapper-disabled:hover {
  border-color: #e8eaed;
  border-right-width: 1px;
}
.syngenta-ant-input-number-affix-wrapper[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #e8eaed;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.syngenta-ant-input-number-affix-wrapper[disabled]:hover {
  border-color: #e8eaed;
  border-right-width: 1px;
}
.syngenta-ant-input-number-affix-wrapper-borderless,
.syngenta-ant-input-number-affix-wrapper-borderless:hover,
.syngenta-ant-input-number-affix-wrapper-borderless:focus,
.syngenta-ant-input-number-affix-wrapper-borderless-focused,
.syngenta-ant-input-number-affix-wrapper-borderless-disabled,
.syngenta-ant-input-number-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.syngenta-ant-input-number-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.syngenta-ant-input-number-affix-wrapper-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.syngenta-ant-input-number-affix-wrapper-sm {
  padding: 0px 7px;
}
.syngenta-ant-input-number-affix-wrapper-rtl {
  direction: rtl;
}
.syngenta-ant-input-number-affix-wrapper:not(.syngenta-ant-input-number-affix-wrapper-disabled):hover {
  border-color: #0d0d0d;
  border-right-width: 1px;
  z-index: 1;
}
.syngenta-ant-input-rtl .syngenta-ant-input-number-affix-wrapper:not(.syngenta-ant-input-number-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syngenta-ant-input-number-affix-wrapper-focused,
.syngenta-ant-input-number-affix-wrapper:focus {
  z-index: 1;
}
.syngenta-ant-input-number-affix-wrapper-disabled .syngenta-ant-input-number[disabled] {
  background: transparent;
}
.syngenta-ant-input-number-affix-wrapper > div.syngenta-ant-input-number {
  width: 100%;
  border: none;
  outline: none;
}
.syngenta-ant-input-number-affix-wrapper > div.syngenta-ant-input-number.syngenta-ant-input-number-focused {
  box-shadow: none !important;
}
.syngenta-ant-input-number-affix-wrapper input.syngenta-ant-input-number-input {
  padding: 0;
}
.syngenta-ant-input-number-affix-wrapper::before {
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.syngenta-ant-input-number-affix-wrapper .syngenta-ant-input-number-handler-wrap {
  z-index: 2;
}
.syngenta-ant-input-number-prefix,
.syngenta-ant-input-number-suffix {
  display: flex;
  flex: none;
  align-items: center;
  pointer-events: none;
}
.syngenta-ant-input-number-prefix {
  margin-inline-end: 4px;
}
.syngenta-ant-input-number-suffix {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  height: 100%;
  margin-right: 11px;
  margin-left: 4px;
}
.syngenta-ant-input-number-group-wrapper .syngenta-ant-input-number-affix-wrapper {
  width: 100%;
}
.syngenta-ant-input-number-status-error:not(.syngenta-ant-input-number-disabled):not(.syngenta-ant-input-number-borderless).syngenta-ant-input-number,
.syngenta-ant-input-number-status-error:not(.syngenta-ant-input-number-disabled):not(.syngenta-ant-input-number-borderless).syngenta-ant-input-number:hover {
  background: #f5f8fc;
  border-color: #ff4d4f;
}
.syngenta-ant-input-number-status-error:not(.syngenta-ant-input-number-disabled):not(.syngenta-ant-input-number-borderless).syngenta-ant-input-number:focus,
.syngenta-ant-input-number-status-error:not(.syngenta-ant-input-number-disabled):not(.syngenta-ant-input-number-borderless).syngenta-ant-input-number-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.syngenta-ant-input-number-status-error .syngenta-ant-input-number-prefix {
  color: #ff4d4f;
}
.syngenta-ant-input-number-status-warning:not(.syngenta-ant-input-number-disabled):not(.syngenta-ant-input-number-borderless).syngenta-ant-input-number,
.syngenta-ant-input-number-status-warning:not(.syngenta-ant-input-number-disabled):not(.syngenta-ant-input-number-borderless).syngenta-ant-input-number:hover {
  background: #f5f8fc;
  border-color: #faad14;
}
.syngenta-ant-input-number-status-warning:not(.syngenta-ant-input-number-disabled):not(.syngenta-ant-input-number-borderless).syngenta-ant-input-number:focus,
.syngenta-ant-input-number-status-warning:not(.syngenta-ant-input-number-disabled):not(.syngenta-ant-input-number-borderless).syngenta-ant-input-number-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.syngenta-ant-input-number-status-warning .syngenta-ant-input-number-prefix {
  color: #faad14;
}
.syngenta-ant-input-number-affix-wrapper-status-error:not(.syngenta-ant-input-number-affix-wrapper-disabled):not(.syngenta-ant-input-number-affix-wrapper-borderless).syngenta-ant-input-number-affix-wrapper,
.syngenta-ant-input-number-affix-wrapper-status-error:not(.syngenta-ant-input-number-affix-wrapper-disabled):not(.syngenta-ant-input-number-affix-wrapper-borderless).syngenta-ant-input-number-affix-wrapper:hover {
  background: #f5f8fc;
  border-color: #ff4d4f;
}
.syngenta-ant-input-number-affix-wrapper-status-error:not(.syngenta-ant-input-number-affix-wrapper-disabled):not(.syngenta-ant-input-number-affix-wrapper-borderless).syngenta-ant-input-number-affix-wrapper:focus,
.syngenta-ant-input-number-affix-wrapper-status-error:not(.syngenta-ant-input-number-affix-wrapper-disabled):not(.syngenta-ant-input-number-affix-wrapper-borderless).syngenta-ant-input-number-affix-wrapper-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.syngenta-ant-input-number-affix-wrapper-status-error .syngenta-ant-input-number-prefix {
  color: #ff4d4f;
}
.syngenta-ant-input-number-affix-wrapper-status-warning:not(.syngenta-ant-input-number-affix-wrapper-disabled):not(.syngenta-ant-input-number-affix-wrapper-borderless).syngenta-ant-input-number-affix-wrapper,
.syngenta-ant-input-number-affix-wrapper-status-warning:not(.syngenta-ant-input-number-affix-wrapper-disabled):not(.syngenta-ant-input-number-affix-wrapper-borderless).syngenta-ant-input-number-affix-wrapper:hover {
  background: #f5f8fc;
  border-color: #faad14;
}
.syngenta-ant-input-number-affix-wrapper-status-warning:not(.syngenta-ant-input-number-affix-wrapper-disabled):not(.syngenta-ant-input-number-affix-wrapper-borderless).syngenta-ant-input-number-affix-wrapper:focus,
.syngenta-ant-input-number-affix-wrapper-status-warning:not(.syngenta-ant-input-number-affix-wrapper-disabled):not(.syngenta-ant-input-number-affix-wrapper-borderless).syngenta-ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.syngenta-ant-input-number-affix-wrapper-status-warning .syngenta-ant-input-number-prefix {
  color: #faad14;
}
.syngenta-ant-input-number-group-wrapper-status-error .syngenta-ant-input-number-group-addon {
  color: #ff4d4f;
  border-color: #ff4d4f;
}
.syngenta-ant-input-number-group-wrapper-status-warning .syngenta-ant-input-number-group-addon {
  color: #faad14;
  border-color: #faad14;
}
.syngenta-ant-input-number {
  box-sizing: border-box;
  color: #2f3031;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: #00a0be;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #f5f8fc;
  background-image: none;
  transition: all 0.3s;
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: 1px solid #e8eaed;
  border-radius: 2px;
}
.syngenta-ant-input-number::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.syngenta-ant-input-number:placeholder-shown {
  text-overflow: ellipsis;
}
.syngenta-ant-input-number:hover {
  border-color: #0d0d0d;
  border-right-width: 1px;
}
.syngenta-ant-input-rtl .syngenta-ant-input-number:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syngenta-ant-input-number:focus,
.syngenta-ant-input-number-focused {
  border-color: #0d0d0d;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.syngenta-ant-input-rtl .syngenta-ant-input-number:focus,
.syngenta-ant-input-rtl .syngenta-ant-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syngenta-ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #e8eaed;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.syngenta-ant-input-number-disabled:hover {
  border-color: #e8eaed;
  border-right-width: 1px;
}
.syngenta-ant-input-number[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #e8eaed;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.syngenta-ant-input-number[disabled]:hover {
  border-color: #e8eaed;
  border-right-width: 1px;
}
.syngenta-ant-input-number-borderless,
.syngenta-ant-input-number-borderless:hover,
.syngenta-ant-input-number-borderless:focus,
.syngenta-ant-input-number-borderless-focused,
.syngenta-ant-input-number-borderless-disabled,
.syngenta-ant-input-number-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.syngenta-ant-input-number {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.syngenta-ant-input-number-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.syngenta-ant-input-number-sm {
  padding: 0px 7px;
}
.syngenta-ant-input-number-rtl {
  direction: rtl;
}
.syngenta-ant-input-number-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.syngenta-ant-input-number-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.syngenta-ant-input-number-group > [class*='col-'] {
  padding-right: 8px;
}
.syngenta-ant-input-number-group > [class*='col-']:last-child {
  padding-right: 0;
}
.syngenta-ant-input-number-group-addon,
.syngenta-ant-input-number-group-wrap,
.syngenta-ant-input-number-group > .syngenta-ant-input-number {
  display: table-cell;
}
.syngenta-ant-input-number-group-addon:not(:first-child):not(:last-child),
.syngenta-ant-input-number-group-wrap:not(:first-child):not(:last-child),
.syngenta-ant-input-number-group > .syngenta-ant-input-number:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.syngenta-ant-input-number-group-addon,
.syngenta-ant-input-number-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.syngenta-ant-input-number-group-wrap > * {
  display: block !important;
}
.syngenta-ant-input-number-group .syngenta-ant-input-number {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.syngenta-ant-input-number-group .syngenta-ant-input-number:focus {
  z-index: 1;
  border-right-width: 1px;
}
.syngenta-ant-input-number-group .syngenta-ant-input-number:hover {
  z-index: 1;
  border-right-width: 1px;
}
.syngenta-ant-input-search-with-button .syngenta-ant-input-number-group .syngenta-ant-input-number:hover {
  z-index: 0;
}
.syngenta-ant-input-number-group-addon {
  position: relative;
  padding: 0 11px;
  color: #00a0be;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #e8eaed;
  border-radius: 2px;
  transition: all 0.3s;
}
.syngenta-ant-input-number-group-addon .syngenta-ant-select {
  margin: -5px -11px;
}
.syngenta-ant-input-number-group-addon .syngenta-ant-select.syngenta-ant-select-single:not(.syngenta-ant-select-customize-input) .syngenta-ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.syngenta-ant-input-number-group-addon .syngenta-ant-select-open .syngenta-ant-select-selector,
.syngenta-ant-input-number-group-addon .syngenta-ant-select-focused .syngenta-ant-select-selector {
  color: #000;
}
.syngenta-ant-input-number-group-addon .syngenta-ant-cascader-picker {
  margin: -9px -12px;
  background-color: transparent;
}
.syngenta-ant-input-number-group-addon .syngenta-ant-cascader-picker .syngenta-ant-cascader-input {
  text-align: left;
  border: 0;
  box-shadow: none;
}
.syngenta-ant-input-number-group > .syngenta-ant-input-number:first-child,
.syngenta-ant-input-number-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syngenta-ant-input-number-group > .syngenta-ant-input-number:first-child .syngenta-ant-select .syngenta-ant-select-selector,
.syngenta-ant-input-number-group-addon:first-child .syngenta-ant-select .syngenta-ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syngenta-ant-input-number-group > .syngenta-ant-input-number-affix-wrapper:not(:first-child) .syngenta-ant-input-number {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syngenta-ant-input-number-group > .syngenta-ant-input-number-affix-wrapper:not(:last-child) .syngenta-ant-input-number {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syngenta-ant-input-number-group-addon:first-child {
  border-right: 0;
}
.syngenta-ant-input-number-group-addon:last-child {
  border-left: 0;
}
.syngenta-ant-input-number-group > .syngenta-ant-input-number:last-child,
.syngenta-ant-input-number-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syngenta-ant-input-number-group > .syngenta-ant-input-number:last-child .syngenta-ant-select .syngenta-ant-select-selector,
.syngenta-ant-input-number-group-addon:last-child .syngenta-ant-select .syngenta-ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syngenta-ant-input-number-group-lg .syngenta-ant-input-number,
.syngenta-ant-input-number-group-lg > .syngenta-ant-input-number-group-addon {
  padding: 6.5px 11px;
  font-size: 16px;
}
.syngenta-ant-input-number-group-sm .syngenta-ant-input-number,
.syngenta-ant-input-number-group-sm > .syngenta-ant-input-number-group-addon {
  padding: 0px 7px;
}
.syngenta-ant-input-number-group-lg .syngenta-ant-select-single .syngenta-ant-select-selector {
  height: 40px;
}
.syngenta-ant-input-number-group-sm .syngenta-ant-select-single .syngenta-ant-select-selector {
  height: 24px;
}
.syngenta-ant-input-number-group .syngenta-ant-input-number-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syngenta-ant-input-search .syngenta-ant-input-number-group .syngenta-ant-input-number-affix-wrapper:not(:last-child) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.syngenta-ant-input-number-group .syngenta-ant-input-number-affix-wrapper:not(:first-child),
.syngenta-ant-input-search .syngenta-ant-input-number-group .syngenta-ant-input-number-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact {
  display: block;
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact::before {
  display: table;
  content: '';
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact::before {
  display: table;
  content: '';
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact-addon:not(:first-child):not(:last-child),
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact-wrap:not(:first-child):not(:last-child),
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-input-number:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact-addon:not(:first-child):not(:last-child):hover,
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact-wrap:not(:first-child):not(:last-child):hover,
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-input-number:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact-addon:not(:first-child):not(:last-child):focus,
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact-wrap:not(:first-child):not(:last-child):focus,
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-input-number:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-input-number-affix-wrapper {
  display: inline-flex;
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-picker-range {
  display: inline-flex;
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact .syngenta-ant-input-number {
  float: none;
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-select > .syngenta-ant-select-selector,
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-select-auto-complete .syngenta-ant-input,
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-cascader-picker .syngenta-ant-input,
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-input-group-wrapper .syngenta-ant-input {
  border-right-width: 1px;
  border-radius: 0;
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-select > .syngenta-ant-select-selector:hover,
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-select-auto-complete .syngenta-ant-input:hover,
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-cascader-picker .syngenta-ant-input:hover,
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-input-group-wrapper .syngenta-ant-input:hover {
  z-index: 1;
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-select > .syngenta-ant-select-selector:focus,
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-select-auto-complete .syngenta-ant-input:focus,
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-cascader-picker .syngenta-ant-input:focus,
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-input-group-wrapper .syngenta-ant-input:focus {
  z-index: 1;
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-select-focused {
  z-index: 1;
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-select > .syngenta-ant-select-arrow {
  z-index: 1;
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > *:first-child,
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-select:first-child > .syngenta-ant-select-selector,
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-select-auto-complete:first-child .syngenta-ant-input,
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-cascader-picker:first-child .syngenta-ant-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > *:last-child,
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-select:last-child > .syngenta-ant-select-selector,
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-cascader-picker:last-child .syngenta-ant-input,
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-cascader-picker-focused:last-child .syngenta-ant-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-select-auto-complete .syngenta-ant-input {
  vertical-align: top;
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact .syngenta-ant-input-group-wrapper + .syngenta-ant-input-group-wrapper {
  margin-left: -1px;
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact .syngenta-ant-input-group-wrapper + .syngenta-ant-input-group-wrapper .syngenta-ant-input-affix-wrapper {
  border-radius: 0;
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact .syngenta-ant-input-group-wrapper:not(:last-child).syngenta-ant-input-search > .syngenta-ant-input-group > .syngenta-ant-input-group-addon > .syngenta-ant-input-search-button {
  border-radius: 0;
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact .syngenta-ant-input-group-wrapper:not(:last-child).syngenta-ant-input-search > .syngenta-ant-input-group > .syngenta-ant-input {
  border-radius: 2px 0 0 2px;
}
.syngenta-ant-input-number-group > .syngenta-ant-input-number-rtl:first-child,
.syngenta-ant-input-number-group-rtl .syngenta-ant-input-number-group-addon:first-child {
  border-radius: 0 2px 2px 0;
}
.syngenta-ant-input-number-group-rtl .syngenta-ant-input-number-group-addon:first-child {
  border-right: 1px solid #e8eaed;
  border-left: 0;
}
.syngenta-ant-input-number-group-rtl .syngenta-ant-input-number-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #e8eaed;
  border-radius: 2px 0 0 2px;
}
.syngenta-ant-input-number-group-rtl.syngenta-ant-input-number-group > .syngenta-ant-input-number:last-child,
.syngenta-ant-input-number-group-rtl.syngenta-ant-input-number-group-addon:last-child {
  border-radius: 2px 0 0 2px;
}
.syngenta-ant-input-number-group-rtl.syngenta-ant-input-number-group .syngenta-ant-input-number-affix-wrapper:not(:first-child) {
  border-radius: 2px 0 0 2px;
}
.syngenta-ant-input-number-group-rtl.syngenta-ant-input-number-group .syngenta-ant-input-number-affix-wrapper:not(:last-child) {
  border-radius: 0 2px 2px 0;
}
.syngenta-ant-input-number-group-rtl.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}
.syngenta-ant-input-number-group-rtl.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > *:first-child,
.syngenta-ant-input-number-group-rtl.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-select:first-child > .syngenta-ant-select-selector,
.syngenta-ant-input-number-group-rtl.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-select-auto-complete:first-child .syngenta-ant-input,
.syngenta-ant-input-number-group-rtl.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-cascader-picker:first-child .syngenta-ant-input {
  border-radius: 0 2px 2px 0;
}
.syngenta-ant-input-number-group-rtl.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > *:last-child,
.syngenta-ant-input-number-group-rtl.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-select:last-child > .syngenta-ant-select-selector,
.syngenta-ant-input-number-group-rtl.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-select-auto-complete:last-child .syngenta-ant-input,
.syngenta-ant-input-number-group-rtl.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-cascader-picker:last-child .syngenta-ant-input,
.syngenta-ant-input-number-group-rtl.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact > .syngenta-ant-cascader-picker-focused:last-child .syngenta-ant-input {
  border-left-width: 1px;
  border-radius: 2px 0 0 2px;
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact .syngenta-ant-input-group-wrapper-rtl + .syngenta-ant-input-group-wrapper-rtl {
  margin-right: -1px;
  margin-left: 0;
}
.syngenta-ant-input-number-group.syngenta-ant-input-number-group-compact .syngenta-ant-input-group-wrapper-rtl:not(:last-child).syngenta-ant-input-search > .syngenta-ant-input-group > .syngenta-ant-input {
  border-radius: 0 2px 2px 0;
}
.syngenta-ant-input-number-group > .syngenta-ant-input-number-rtl:first-child {
  border-radius: 0 2px 2px 0;
}
.syngenta-ant-input-number-group > .syngenta-ant-input-number-rtl:last-child {
  border-radius: 2px 0 0 2px;
}
.syngenta-ant-input-number-group-rtl .syngenta-ant-input-number-group-addon:first-child {
  border-right: 1px solid #e8eaed;
  border-left: 0;
  border-radius: 0 2px 2px 0;
}
.syngenta-ant-input-number-group-rtl .syngenta-ant-input-number-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #e8eaed;
  border-radius: 2px 0 0 2px;
}
.syngenta-ant-input-number-group-wrapper {
  display: inline-block;
  text-align: start;
  vertical-align: top;
}
.syngenta-ant-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: #707374;
  font-weight: bold;
  line-height: 0;
  text-align: center;
  border-left: 1px solid #e8eaed;
  transition: all 0.1s linear;
}
.syngenta-ant-input-number-handler:active {
  background: #f4f4f4;
}
.syngenta-ant-input-number-handler:hover .syngenta-ant-input-number-handler-up-inner,
.syngenta-ant-input-number-handler:hover .syngenta-ant-input-number-handler-down-inner {
  color: #0d0d0d;
}
.syngenta-ant-input-number-handler-up-inner,
.syngenta-ant-input-number-handler-down-inner {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: #707374;
  line-height: 12px;
  transition: all 0.1s linear;
  user-select: none;
}
.syngenta-ant-input-number-handler-up-inner > *,
.syngenta-ant-input-number-handler-down-inner > * {
  line-height: 1;
}
.syngenta-ant-input-number-handler-up-inner svg,
.syngenta-ant-input-number-handler-down-inner svg {
  display: inline-block;
}
.syngenta-ant-input-number-handler-up-inner::before,
.syngenta-ant-input-number-handler-down-inner::before {
  display: none;
}
.syngenta-ant-input-number-handler-up-inner .syngenta-ant-input-number-handler-up-inner-icon,
.syngenta-ant-input-number-handler-up-inner .syngenta-ant-input-number-handler-down-inner-icon,
.syngenta-ant-input-number-handler-down-inner .syngenta-ant-input-number-handler-up-inner-icon,
.syngenta-ant-input-number-handler-down-inner .syngenta-ant-input-number-handler-down-inner-icon {
  display: block;
}
.syngenta-ant-input-number:hover {
  border-color: #0d0d0d;
  border-right-width: 1px;
}
.syngenta-ant-input-number:hover + .syngenta-ant-form-item-children-icon {
  opacity: 0;
  transition: opacity 0.24s linear 0.24s;
}
.syngenta-ant-input-number-focused {
  border-color: #0d0d0d;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.syngenta-ant-input-rtl .syngenta-ant-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.syngenta-ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #e8eaed;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.syngenta-ant-input-number-disabled:hover {
  border-color: #e8eaed;
  border-right-width: 1px;
}
.syngenta-ant-input-number-disabled .syngenta-ant-input-number-input {
  cursor: not-allowed;
}
.syngenta-ant-input-number-disabled .syngenta-ant-input-number-handler-wrap {
  display: none;
}
.syngenta-ant-input-number-readonly .syngenta-ant-input-number-handler-wrap {
  display: none;
}
.syngenta-ant-input-number-input {
  width: 100%;
  height: 30px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 2px;
  outline: 0;
  transition: all 0.3s linear;
  appearance: textfield !important;
}
.syngenta-ant-input-number-input::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.syngenta-ant-input-number-input:placeholder-shown {
  text-overflow: ellipsis;
}
.syngenta-ant-input-number-input[type='number']::-webkit-inner-spin-button,
.syngenta-ant-input-number-input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  appearance: none;
}
.syngenta-ant-input-number-lg {
  padding: 0;
  font-size: 16px;
}
.syngenta-ant-input-number-lg input {
  height: 38px;
}
.syngenta-ant-input-number-sm {
  padding: 0;
}
.syngenta-ant-input-number-sm input {
  height: 22px;
  padding: 0 7px;
}
.syngenta-ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-radius: 0 2px 2px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
.syngenta-ant-input-number-handler-wrap .syngenta-ant-input-number-handler .syngenta-ant-input-number-handler-up-inner,
.syngenta-ant-input-number-handler-wrap .syngenta-ant-input-number-handler .syngenta-ant-input-number-handler-down-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: auto;
  margin-right: 0;
  font-size: 7px;
}
.syngenta-ant-input-number-borderless .syngenta-ant-input-number-handler-wrap {
  border-left-width: 0;
}
.syngenta-ant-input-number-handler-wrap:hover .syngenta-ant-input-number-handler {
  height: 40%;
}
.syngenta-ant-input-number:hover .syngenta-ant-input-number-handler-wrap,
.syngenta-ant-input-number-focused .syngenta-ant-input-number-handler-wrap {
  opacity: 1;
}
.syngenta-ant-input-number-handler-up {
  border-top-right-radius: 2px;
  cursor: pointer;
}
.syngenta-ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}
.syngenta-ant-input-number-handler-up:hover {
  height: 60% !important;
}
.syngenta-ant-input-number-handler-down {
  top: 0;
  border-top: 1px solid #e8eaed;
  border-bottom-right-radius: 2px;
  cursor: pointer;
}
.syngenta-ant-input-number-handler-down-inner {
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
}
.syngenta-ant-input-number-handler-down:hover {
  height: 60% !important;
}
.syngenta-ant-input-number-borderless .syngenta-ant-input-number-handler-down {
  border-top-width: 0;
}
.syngenta-ant-input-number:hover:not(.syngenta-ant-input-number-borderless) .syngenta-ant-input-number-handler-down,
.syngenta-ant-input-number-focused:not(.syngenta-ant-input-number-borderless) .syngenta-ant-input-number-handler-down {
  border-top: 1px solid #e8eaed;
}
.syngenta-ant-input-number-handler-up-disabled,
.syngenta-ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}
.syngenta-ant-input-number-handler-up-disabled:hover .syngenta-ant-input-number-handler-up-inner,
.syngenta-ant-input-number-handler-down-disabled:hover .syngenta-ant-input-number-handler-down-inner {
  color: rgba(0, 0, 0, 0.25);
}
.syngenta-ant-input-number-borderless {
  box-shadow: none;
}
.syngenta-ant-input-number-out-of-range input {
  color: #ff4d4f;
}
.syngenta-ant-input-number-compact-item:not(.syngenta-ant-input-number-compact-last-item):not(.syngenta-ant-input-number-compact-item-rtl) {
  margin-right: -1px;
}
.syngenta-ant-input-number-compact-item:not(.syngenta-ant-input-number-compact-last-item).syngenta-ant-input-number-compact-item-rtl {
  margin-left: -1px;
}
.syngenta-ant-input-number-compact-item:hover,
.syngenta-ant-input-number-compact-item:focus,
.syngenta-ant-input-number-compact-item:active {
  z-index: 2;
}
.syngenta-ant-input-number-compact-item.syngenta-ant-input-number-focused {
  z-index: 2;
}
.syngenta-ant-input-number-compact-item[disabled] {
  z-index: 0;
}
.syngenta-ant-input-number-compact-item:not(.syngenta-ant-input-number-compact-first-item):not(.syngenta-ant-input-number-compact-last-item).syngenta-ant-input-number {
  border-radius: 0;
}
.syngenta-ant-input-number-compact-item.syngenta-ant-input-number.syngenta-ant-input-number-compact-first-item:not(.syngenta-ant-input-number-compact-last-item):not(.syngenta-ant-input-number-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syngenta-ant-input-number-compact-item.syngenta-ant-input-number.syngenta-ant-input-number-compact-last-item:not(.syngenta-ant-input-number-compact-first-item):not(.syngenta-ant-input-number-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syngenta-ant-input-number-compact-item.syngenta-ant-input-number.syngenta-ant-input-number-compact-item-rtl.syngenta-ant-input-number-compact-first-item:not(.syngenta-ant-input-number-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.syngenta-ant-input-number-compact-item.syngenta-ant-input-number.syngenta-ant-input-number-compact-item-rtl.syngenta-ant-input-number-compact-last-item:not(.syngenta-ant-input-number-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.syngenta-ant-input-number-rtl {
  direction: rtl;
}
.syngenta-ant-input-number-rtl .syngenta-ant-input-number-handler {
  border-right: 1px solid #e8eaed;
  border-left: 0;
}
.syngenta-ant-input-number-rtl .syngenta-ant-input-number-handler-wrap {
  right: auto;
  left: 0;
}
.syngenta-ant-input-number-rtl.syngenta-ant-input-number-borderless .syngenta-ant-input-number-handler-wrap {
  border-right-width: 0;
}
.syngenta-ant-input-number-rtl .syngenta-ant-input-number-handler-up {
  border-top-right-radius: 0;
}
.syngenta-ant-input-number-rtl .syngenta-ant-input-number-handler-down {
  border-bottom-right-radius: 0;
}
.syngenta-ant-input-number-rtl .syngenta-ant-input-number-input {
  direction: ltr;
  text-align: right;
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
.anticon-spin::before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}
.anticon-spin {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}
.st-grid {
  display: grid;
}
.st-grid-row-gap--4 {
  grid-row-gap: 4px;
}
.st-grid-column-gap--4 {
  grid-column-gap: 4px;
}
.st-grid-row-gap--8 {
  grid-row-gap: 8px;
}
.st-grid-column-gap--8 {
  grid-column-gap: 8px;
}
.st-grid-row-gap--12 {
  grid-row-gap: 12px;
}
.st-grid-column-gap--12 {
  grid-column-gap: 12px;
}
.st-grid-row-gap--16 {
  grid-row-gap: 16px;
}
.st-grid-column-gap--16 {
  grid-column-gap: 16px;
}
.st-grid-row-gap--24 {
  grid-row-gap: 24px;
}
.st-grid-column-gap--24 {
  grid-column-gap: 24px;
}
.st-grid--5 {
  display: grid;
  height: inherit;
  grid-template-columns: repeat(5, auto);
}
.st-grid-fr--5 {
  display: grid;
  height: inherit;
  grid-template-columns: repeat(5, 1fr);
}
.st-grid-min--5 {
  display: grid;
  grid-template-columns: repeat(5, min-content);
}
.st-grid-item-start--5 {
  grid-column-start: 5;
}
.st-grid-item-end--5 {
  grid-column-end: 5;
}
.st-grid-row--5 {
  display: grid;
  grid-template-rows: repeat(5, auto);
}
.st-grid--4 {
  display: grid;
  height: inherit;
  grid-template-columns: repeat(4, auto);
}
.st-grid-fr--4 {
  display: grid;
  height: inherit;
  grid-template-columns: repeat(4, 1fr);
}
.st-grid-min--4 {
  display: grid;
  grid-template-columns: repeat(4, min-content);
}
.st-grid-item-start--4 {
  grid-column-start: 4;
}
.st-grid-item-end--4 {
  grid-column-end: 4;
}
.st-grid-row--4 {
  display: grid;
  grid-template-rows: repeat(4, auto);
}
.st-grid--3 {
  display: grid;
  height: inherit;
  grid-template-columns: repeat(3, auto);
}
.st-grid-fr--3 {
  display: grid;
  height: inherit;
  grid-template-columns: repeat(3, 1fr);
}
.st-grid-min--3 {
  display: grid;
  grid-template-columns: repeat(3, min-content);
}
.st-grid-item-start--3 {
  grid-column-start: 3;
}
.st-grid-item-end--3 {
  grid-column-end: 3;
}
.st-grid-row--3 {
  display: grid;
  grid-template-rows: repeat(3, auto);
}
.st-grid--2 {
  display: grid;
  height: inherit;
  grid-template-columns: repeat(2, auto);
}
.st-grid-fr--2 {
  display: grid;
  height: inherit;
  grid-template-columns: repeat(2, 1fr);
}
.st-grid-min--2 {
  display: grid;
  grid-template-columns: repeat(2, min-content);
}
.st-grid-item-start--2 {
  grid-column-start: 2;
}
.st-grid-item-end--2 {
  grid-column-end: 2;
}
.st-grid-row--2 {
  display: grid;
  grid-template-rows: repeat(2, auto);
}
.st-grid--1 {
  display: grid;
  height: inherit;
  grid-template-columns: repeat(1, auto);
}
.st-grid-fr--1 {
  display: grid;
  height: inherit;
  grid-template-columns: repeat(1, 1fr);
}
.st-grid-min--1 {
  display: grid;
  grid-template-columns: repeat(1, min-content);
}
.st-grid-item-start--1 {
  grid-column-start: 1;
}
.st-grid-item-end--1 {
  grid-column-end: 1;
}
.st-grid-row--1 {
  display: grid;
  grid-template-rows: repeat(1, auto);
}
.st-self-align--start {
  align-self: start;
}
.st-self-justify--start {
  justify-self: start;
}
.st-align-items--start {
  align-items: start;
}
.st-justify-content--start {
  justify-content: start;
}
.st-text-align--start {
  text-align: start;
}
.st-self-align--end {
  align-self: end;
}
.st-self-justify--end {
  justify-self: end;
}
.st-align-items--end {
  align-items: end;
}
.st-justify-content--end {
  justify-content: end;
}
.st-text-align--end {
  text-align: end;
}
.st-self-align--center {
  align-self: center;
}
.st-self-justify--center {
  justify-self: center;
}
.st-align-items--center {
  align-items: center;
}
.st-justify-content--center {
  justify-content: center;
}
.st-text-align--center {
  text-align: center;
}
.st-self-align--left {
  align-self: left;
}
.st-self-justify--left {
  justify-self: left;
}
.st-align-items--left {
  align-items: left;
}
.st-justify-content--left {
  justify-content: left;
}
.st-text-align--left {
  text-align: left;
}
.st-self-align--right {
  align-self: right;
}
.st-self-justify--right {
  justify-self: right;
}
.st-align-items--right {
  align-items: right;
}
.st-justify-content--right {
  justify-content: right;
}
.st-text-align--right {
  text-align: right;
}
.st-action {
  cursor: pointer;
}
.st-action-btn:hover {
  cursor: pointer;
  background-color: var(--pale-grey);
}
.st-action-btn-reverse:hover {
  cursor: pointer;
  background-color: white;
}
.st-tag {
  height: 16px !important;
  display: flex !important;
  padding: 4px 4px !important;
  align-items: center;
}
.st-tag span {
  padding-top: 1px;
}
.st-default-line-height {
  line-height: 14px;
}
.st-page-header {
  display: grid;
  align-items: center;
  width: 100%;
  padding-left: 12px;
  background-color: white;
  height: 56px;
  margin-bottom: 20px;
}
.sf-bin-outline:hover {
  fill: #f85555;
}
.sf-spray:hover {
  fill: #0670fa;
}
.st-force-font-color {
  color: #2f3031 !important;
}
.st-page-title {
  display: grid;
  align-items: center;
  background-color: #F0F3F7;
  height: 56px;
  padding: 16px;
}
.st-table-button {
  padding: 0 8px;
  background: #f2f4f6;
  border: 0;
  margin-right: 8px;
}
.st-table-button:hover {
  background: #f2f4f6;
}
.syngenta-ant-input-lg,
.syngenta-ant-input-number-lg {
  font-size: 14px !important;
}
.syngenta-ant-form-item-control {
  line-height: 50px;
}
.syngenta-ant-input-affix-wrapper .syngenta-ant-input-prefix,
.syngenta-ant-input-affix-wrapper .syngenta-ant-input-suffix {
  color: #707374;
  font-size: 18px;
}
.syngenta-ant-popover-inner {
  border-radius: 8px !important;
}
.syngenta-ant-modal-title {
  font-size: 20px;
  font-weight: 400;
}
.syngenta-ant-btn-lg {
  font-size: 14px !important;
}
.syngenta-ant-modal-content {
  border-radius: 8px !important;
}
.syngenta-ant-avatar-string {
  position: initial;
}
.syngenta-ant-select-lg {
  font-size: 14px !important;
}
.syngenta-ant-tree li .syngenta-ant-tree-node-content-wrapper {
  width: calc(100% - 48px) !important;
}
.syngenta-ant-calendar-picker-input {
  max-width: 248px;
  background: white;
  border: 1px solid #f2f4f6;
  margin-left: 14px;
}
.syngenta-ant-select-selection.syngenta-ant-select-selection--single {
  background-color: #f5f8fc;
}
.syngenta-ant-input-group-addon {
  min-width: 70px;
  background-color: var(--addon-select);
}
.syngenta-ant-calendar-picker-input {
  background-color: #f5f8fc !important;
}
.highcharts-root {
  font-family: 'Rubik', sans-serif !important;
  color: #000 !important;
  fill: #000 !important;
}
.st-drawer-no-padding .syngenta-ant-drawer-body {
  padding: 0;
}
.syngenta-ant-form-item-required::before {
  content: '';
}
.syngenta-ant-form-explain {
  float: left;
}
.syngenta-ant-popover-inner-content {
  padding: 0;
}
html {
  overflow: hidden;
}
body {
  color: #707374;
}
.syngenta-ant-popover-placement-bottomRight > .syngenta-ant-popover-content > .syngenta-ant-popover-arrow {
  border-left-color: #F5F8FC;
  border-top-color: #F5F8FC;
}
.syngenta-ant-btn-secondary:hover {
  background: #E8EAED;
  color: #2F3031;
  border-color: #F0F3F7;
}
.btn-orange {
  background: #FFFFFF;
  border: 1px solid #F9C43D;
  color: #F9C43D;
}
.btn-orange:hover {
  background: #F9C43D;
  color: #FFFFFF;
  border: 1px solid #F9C43D;
}
.btn-green,
.btn-green:focus {
  background-color: #73DC78;
  border: 1px solid #73DC78;
  color: #FFFFFF;
}
.btn-green:hover {
  background-color: #5cb060;
  border: 1px solid #5cb060;
  color: #FFFFFF;
}
.btn-dark,
.btn-dark:focus {
  background-color: #2f3031;
  border: 1px solid #2f3031;
  color: #FFFFFF;
}
.btn-dark:hover {
  background-color: #6E7391;
  border: 1px solid #6E7391;
  color: #FFFFFF;
}
.syngenta-ant-btn-primary:hover,
.syngenta-ant-btn-primary:focus {
  color: #fff;
  background-color: #00A0BE;
  border-color: #00A0BE;
}
#mapId {
  height: calc(100vh - 75px);
}
.back-button {
  margin-right: 15px;
  border-radius: 20px;
  color: #00a0be;
  cursor: pointer;
  width: 40px;
  height: 40px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.back-button:hover {
  background-color: #00a0be;
  color: white;
  text-align: center;
}
.map-wheather-div {
  position: fixed;
  top: 80px;
  right: 25px;
  min-width: 271px;
  box-sizing: border-box;
  z-index: 999;
  background: #fff;
  border-radius: 8px !important;
}
@media (min-width: 1024px) and (max-width: 1365px) {
  #scollId .syngenta-ant-list-item {
    align-items: initial;
  }
  .allFields-select {
    width: 120px !important;
  }
  .create-field-options {
    width: 180px !important;
    height: 150px !important;
  }
  .map-wheather-div {
    min-width: 200px !important;
  }
  .platformuser-search-box {
    width: 270px !important;
  }
  .map-timeline-container {
    width: 750px !important;
    left: 55% !important;
  }
  .progress-date-container .progress-date {
    left: 10% !important;
  }
  .map-timeline-container-and-weather-responsive {
    width: 530px !important;
    left: 43% !important;
  }
  .map-timeline-container-and-weather-responsive .progress-date-container .progress-date {
    left: 20% !important;
  }
  .map-timeline-container-and-weather-responsive .main-container .harvest-icon {
    margin-left: 4px !important;
    width: 50px;
    height: 50px;
    margin-top: 8px;
  }
  .map-timeline-container-and-weather-responsive .field-details .syngenta-ant-row .syngenta-ant-col-16 {
    margin-left: 2px;
  }
  .weather-drawer-class .syngenta-ant-drawer-content-wrapper {
    width: 300px !important;
  }
  .weather-drawer-class {
    width: 300px !important;
  }
  .task-edit,
  .task-delete {
    border-right-color: transparent !important;
    border-right-width: 0px !important;
    border-right-style: none !important;
    display: block;
  }
  .task-table {
    height: 330px;
    overflow: auto;
  }
}
.modalWrapClass {
  position: absolute;
  overflow: initial;
  top: 68px;
}
.noBoundaryIconDiv:hover .icon-area-black::before {
  color: #FFFFFF;
}
.create-no-boundary-check-button {
  background-color: #FFFFFF;
}
.create-no-boundary-check-button:hover {
  background-color: #AAB400;
}
.create-no-boundary-check-button:hover .icon-check::before {
  color: #FFFFFF;
}
.print-save-page {
  color: #AAB400;
  font-size: 14px;
}
.create-no-boundary-check-button:hover .icon-check::before,
.create-no-boundary-check-button:hover .icon-print::before,
.create-no-boundary-check-button:hover .print-save-page,
.create-no-boundary-check-button:hover .icon-download::before {
  color: #FFFFFF;
}
.bgColorEdit:hover {
  background-color: #aab400 !important;
}
.print-close:hover .icon-close-glyph-2::before {
  color: #00A0BE;
}
.bgColorEdit:hover {
  background-color: #aab400 !important;
}
.bgColorDelete:hover {
  background-color: #f74141 !important;
}
.error-tooltip .syngenta-ant-tooltip-content .syngenta-ant-tooltip-inner {
  color: black;
  background: white;
}
.error-tooltip .syngenta-ant-tooltip-content .syngenta-ant-tooltip-arrow::before {
  background: white;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #F3F4F6 inset !important;
  background-color: #F3F4F6 !important;
}
input:-internal-autofill-selected,
input:-internal-autofill-selected:hover,
input:-internal-autofill-selected:focus,
input:-internal-autofill-selected:active {
  -webkit-box-shadow: 0 0 0 30px #F3F4F6 inset !important;
  background-color: #F3F4F6 !important;
}
.st-layout {
  height: 100vh;
}
.st-layout .st-sider {
  background-color: #2f3031;
}
.st-layout .st-content {
  background-color: #f5f8fc;
  z-index: 1;
}
.syngenta-ant-input-affix-wrapper {
  color: #14151C;
  background-color: #ffffff;
}
.syngenta-ant-input {
  color: #14151C;
  background-color: #ffffff;
}
.syngenta-ant-input-affix-wrapper:focus,
.syngenta-ant-input-affix-wrapper-focused {
  box-shadow: 0px 0px 0px 3px rgba(0, 146, 228, 0.25);
  border: 1px solid #0092E4;
}

.entitlements-drawer-manage-buttons {
  height: 100%;
  position: relative;
}
.entitlements-drawer-manage-buttons__wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  align-items: center;
}
.entitlements-drawer-manage-buttons__wrapper__right {
  display: flex;
  align-items: center;
}
.entitlements-drawer-manage-buttons__wrapper__right .entitlement-drawer-manage-cancel-button {
  color: black;
  margin-right: 8px;
  background-color: transparent;
  border: none;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.entitlements-drawer-manage-buttons__wrapper__right .entitlement-drawer-manage-cancel-button:hover {
  background-color: #F3F4F6;
  color: black;
}
.entitlements-drawer-manage-buttons__wrapper .entitlement-drawer-manage-delete-button {
  color: #F74141;
  transition: all 0.35s ease;
}
.entitlements-drawer-manage-buttons__wrapper .entitlement-drawer-manage-delete-button:hover {
  cursor: pointer;
  text-decoration: underline;
}

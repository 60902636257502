/* New License Plan - CSS */
.newLicensePlanContent {
  background: #ffffff;
  margin-bottom: 12px;
  padding: 20px 20px 20px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 20px 0 rgba(112, 115, 116, 0.16);
  height: inherit;
  overflow: auto;
}
.newLicensePlanContentData {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.newLicensePlanHeading {
  margin-bottom: 10px;
}
.newLicensePlanTop {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.newLicensePlanTopColumn1 {
  width: fit-content;
  min-width: 30%;
  margin-right: 30px;
}
.newLicensePlanTopColumn2 {
  width: fit-content;
  min-width: 40%;
  margin-right: 30px;
}
.newLicensePlanTopColumn3 {
  width: fit-content;
  min-width: 20%;
}
.newLicensePlanInputNumber {
  width: 320px;
  color: #2F3031;
  background-color: white;
  border: 1px solid #C2C7D0;
}
.newLicensePlanHeadings {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  vertical-align: middle;
  margin-bottom: 20px;
  margin-top: 10px;
  width: 100%;
}
.newLicensePlanHeadingsColumn1 {
  min-width: 700px;
  width: 65%;
  margin-top: 10px;
}
.newLicensePlanHeadingsColumn2 {
  float: right;
  margin-left: auto;
}
.newLicensePlanHeadingsButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: auto;
  padding-left: 10px;
  margin-top: 10px;
}
.newLicensePlanHeadingsButtons Button {
  margin-left: 10px;
  height: 40px;
  width: 62px;
}
.entiltlementsHeadings {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.entiltlementsHeadings Button {
  margin-left: 10px;
  height: 40px;
  width: 62px;
  margin-left: 398px;
}
.quotasHeadings {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.quotaHeadingsText {
  display: flex;
  flex-direction: column;
  width: 740px;
  min-width: 465px;
}
.newLicensePlanBody {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.newLicensePlanBodyColumn1 {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-right: 15px;
  min-width: 545px;
}
.newLicensePlanBodyColumn2 {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 890px;
  min-width: 400px;
}
.deleteLink a {
  color: #eb4034;
}
.spacedIcons {
  margin-left: 2px;
}
.newLicensePlanTopColumn1 .syngenta-ant-select-multiple .syngenta-ant-select-selector {
  background-color: white;
  border: 1px solid #C2C7D0;
  max-height: 70px;
  overflow-y: auto;
}
.newLicensePlanTopColumn2 .syngenta-ant-select-multiple .syngenta-ant-select-selector {
  background-color: white;
  border: 1px solid #C2C7D0;
}
.newLicensePlanDefaultDuration {
  margin-right: 20px;
}
.cw-plan-name {
  background-color: #ffffff;
  color: #2F3031;
  border-color: #C2C7D0;
}
.cw-plan-name:hover {
  border-color: #2F3031 !important;
}

/* SDKs Overview - CSS */
.sdkOverviewContent {
  background: #ffffff;
  margin: 22px 16px 22px 22px;
  padding: 20px 30px 20px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 20px 0 rgba(112, 115, 116, 0.16);
  height: inherit;
  overflow: auto;
}
.clrFloat {
  clear: both;
}
.sdkOverviewContentData {
  width: 100%;
}
.overviewThumbnail {
  width: 310px;
  height: 190px;
  background: #14803C;
  box-shadow: 0px 6px 16px rgba(47, 48, 49, 0.25);
  border-radius: 16px;
  margin-top: 30px;
  margin-right: 40px;
  float: left;
}
.overviewInnerThumbnail {
  width: 100%;
  height: 145px;
  background: #FFFFFF;
  box-shadow: 0px 6px 16px rgba(47, 48, 49, 0.25);
  border-radius: 16px;
  padding: 15px;
}
.InnerLeftBlock {
  float: left;
  width: 40%;
}
.innerLeafBlock {
  width: 85px;
  height: 115px;
  background: #2F3031;
  border-radius: 16px;
  text-align: center;
}
.innerLeafImage {
  padding-top: 30px;
}
.innerRightBlock {
  float: left;
  width: 60%;
}
.innerThumbnailHeading {
  font-weight: 500;
  font-size: 16px;
  color: #2F3031;
  padding: 7px 0px 7px 0px;
}
.coreSdkIcon {
  color: #707374;
  float: right;
  font-size: 12px;
  padding-top: 6px;
  cursor: pointer;
}
.technologiesBlock {
  padding-left: 5px;
  padding-bottom: 5px;
  font-size: 13px;
  color: #707374;
}
.greenCircle {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #8CC3A3;
  float: left;
  margin-top: 7px;
  margin-right: 7px;
}
.technologiesIconBlock {
  margin-left: 10px;
}
.technologiesIcon {
  float: left;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  background: #515253;
  border-radius: 50%;
  text-align: center;
}
.technologiesIcon img {
  margin-top: -4px;
}
.outerThumbData {
  width: 100%;
  padding: 12px 20px;
  color: #ffffff;
  font-size: 13px;
}
.installtionGuide {
  float: left;
  cursor: pointer;
}
.installtionGuide span {
  padding-left: 7px;
}
.contribute {
  float: right;
  cursor: pointer;
}
.contribute span {
  padding-left: 7px;
}
.syngenta-ant-tree {
  padding-top: 15px;
}
.syngenta-ant-tree .syngenta-ant-tree-node-content-wrapper.syngenta-ant-tree-node-selected {
  background-color: transparent !important;
}
.syngenta-ant-tree-content-title {
  cursor: pointer;
  caret-color: transparent;
}

.cw-logo-container {
  margin: 0 28px 0px 0;
  float: left;
}
.cw-login-nav-menu {
  position: absolute;
  right: 180px;
  top: 0;
  height: 65px;
}
.cw-page-container {
  display: flex;
  height: 100vh;
}
.site-layout-background {
  background: #fff;
}
.cw-contextbar-app-icon {
  width: 38px;
  height: 38px;
}
.errorModalContent {
  width: 100%;
}
.errorModalHeader {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 2px solid #f74141;
  border-radius: 8px 8px 0 0;
}
.cw-email-notification {
  background-color: #eaf6ff;
  border-radius: 4px;
}

.card-page-header {
  display: flex;
  gap: 2rem;
  justify-items: center;
}
.card-page-header-back-button {
  padding: 8px;
  border: 1px solid #C2C7D0;
  border-radius: 100%;
  font-size: 1.2rem;
}
.card-page-header-title {
  font-size: 1.25rem !important;
  color: #2F3031 !important;
}
.cw-app-delete {
  color: red;
  margin-right: 24px;
}
.card-page-header-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 60%;
}

/* Widgets - CSS */
.widgetContent {
  background: #ffffff;
  margin-bottom: 12px;
  padding: 20px 30px 20px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 20px 0 rgba(112, 115, 116, 0.16);
  height: inherit;
  min-width: 750px;
}
.subHeadingText {
  font-size: 14px;
  color: #2F3031;
  float: left;
  margin-top: 9px;
}
.clrFloat {
  clear: both;
}
.widgetTableBlock {
  width: 100%;
  margin-top: 20px;
}
.widgetActions {
  min-width: 140px;
}
.widgetAppEdit {
  color: #73DC78;
}
.widgetAppDelete {
  color: #8E9293;
}
.dividerLeft {
  border-left: 1px solid #979797;
}
/*  Widget Model CSS  */
.submitManifestModal .syngenta-ant-modal-header {
  padding: 15px 24px;
  color: #2F3031;
  border-bottom: 2px solid #232324;
  border-radius: 8px 8px 0 0;
  background: #F2F4F6;
}
.submitManifestModal .syngenta-ant-modal-title {
  color: #2F3031;
  font-size: 18px;
}
.submitManifestModal .syngenta-ant-modal-footer {
  border-top: none;
}
.submitManifestModalContent {
  width: 100%;
  min-height: 100px;
  display: flex;
  margin-bottom: 10px;
}
.uploadChoiceBlock {
  width: 50%;
  float: left;
  padding: 5px 40px;
  text-align: center;
}
.uploadGrid {
  width: 155px;
  height: 110px;
}
.uploadChoiceBlock .syngenta-ant-upload.syngenta-ant-upload-drag,
.uploadChoiceBlock .syngenta-ant-upload.syngenta-ant-upload-select {
  border: 1px solid #E8EAED !important;
  background: #ffffff;
  border-radius: 4px;
  padding: 5px 0;
}
.uploadChoiceBlock .syngenta-ant-upload.syngenta-ant-upload-drag .syngenta-ant-upload {
  padding: 0px;
}
.uploadTypeIcon {
  font-size: 24px;
  color: #73DC78;
  vertical-align: text-top;
}
.uploadChoiceHeading {
  color: #515253;
  font-weight: 400;
}
.uploadChoiceText {
  font-weight: 300;
  font-size: 12px;
  color: #8E9293;
}
.submitManifestModalFooter {
  margin-top: 20px;
}
.helpText {
  font-size: 12px;
  color: #8E9293;
  display: inline-block;
  margin-top: 15px;
}
.helpText span {
  cursor: pointer;
  color: #73DC78;
  font-weight: 500;
}
.cancelBtnBlock {
  float: right;
}
.defaultBtn {
  color: #2F3031 !important;
  background: #F2F4F6 !important;
  border-radius: 4px;
}
.uploadChoiceBlock .syngenta-ant-upload-text-icon {
  float: left;
}

.cw-app-created-content-container {
  background: #fff;
  margin-bottom: 12px;
  padding: 24px 24px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 20px 0 rgba(112, 115, 116, 0.16);
  height: inherit;
  overflow: auto;
  min-width: 500px;
}
.cw-app-created-icon-placeholder {
  border-radius: 8px;
  background-color: #2f3031;
  margin-bottom: 1em;
  height: 45px;
  width: 45px;
}
.cw-app-created-right-outlined {
  padding-left: 20px;
  margin-top: -10px;
  font-size: 16px;
  color: #73DC78;
}
.cw-app-created-icon-outlined {
  font-size: 30px;
  color: #73DC78;
}
.cw-app-created-client-container {
  display: flex;
  flex-direction: column;
  width: 330;
  margin-right: 40px;
}
.cw-app-created-copy-container {
  background: #F3F4F6;
  border-radius: 4px;
  padding: 15px 30px 15px 15px;
  margin-top: 10px;
  min-width: 335px;
}
.cw-app-card-container {
  width: 245;
  height: 160;
  margin-right: 20px;
  margin-top: 10px;
}
.cw-app-card-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

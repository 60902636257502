.card-page-header {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.card-page-header-back-button {
  padding: 8px;
  border: 1px solid #C2C7D0;
  border-radius: 100%;
  font-size: 1.2rem;
}
.card-page-header-title {
  font-size: 1.25rem !important;
  color: #2F3031 !important;
}

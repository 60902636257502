.cw-credential-actions {
  min-width: 140px;
}
.cw-credential-edit {
  color: #0071CD;
}
.cw-credential-revoke {
  color: #8E9293;
}
.cw-credential-content-container {
  background: #fff;
  margin-bottom: 12px;
  padding: 20px 30px 20px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 20px 0 rgba(112, 115, 116, 0.16);
  height: inherit;
  overflow: auto;
  min-width: 750px;
}
.cw-credential-icon-placeholder {
  border-radius: 8px;
  background-color: #2f3031;
  margin-bottom: 1em;
  max-height: 45px;
  max-width: 45px;
}
.cw-divider-left {
  border-left: 1px solid #979797;
}
.cw-credential-create-client-button {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: 35px;
  margin-right: 20px;
}
.cw-credential-table {
  margin-right: 20px;
}
.cw-credential-column {
  flex: 100%;
}

.cw-app-details-form-vseparator {
  background-color: #E5E7EA;
  flex-direction: column;
  min-width: 1px;
  margin-left: 25px;
}
.cw-content-container {
  background: #fff;
  margin: 24px;
  padding: 20px 30px 20px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 20px 0 rgba(112, 115, 116, 0.16);
}
.cw-app-details-column1 {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  padding-right: 40px;
  min-width: 500px;
  width: 35%;
  border-right: 1px solid #E8EAED;
}
.cw-app-details-column2 {
  display: flex;
  min-width: 450px;
  flex-direction: column;
  width: 35%;
}
.cw-app-details-row {
  display: flex;
  flex-direction: row;
  margin: 30px 0 20px 0;
}
.cw-app-details-input {
  margin-top: 10px;
}
.icon-uploader > .ant-upload-select-picture-card {
  width: 104px;
  height: 104px;
}
.ant-upload-select-picture-card i {
  width: 450px;
}
.ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.pad10 {
  padding-bottom: 10px;
}
.pad40 {
  padding-bottom: 40px;
}
.cw-divider-left {
  border-left: 1px solid #979797;
}

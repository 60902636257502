/* Licensing - CSS */
.licensingContent {
  background: #ffffff;
  margin-bottom: 12px;
  padding: 20px 30px 20px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 20px 0 rgba(112, 115, 116, 0.16);
  height: inherit;
  overflow: auto;
}
.licensingSubHeadingBlock {
  font-size: 14px;
  color: #2F3031;
  margin-top: 9px;
}
.clrFloat {
  clear: both;
}
.licensingTableBlock {
  width: 100%;
  margin-top: 40px;
}
.dividerLeft {
  border-left: 1px solid #979797;
}
.licensingAppEdit {
  color: #0071CD;
}
.licensingAppContracts {
  color: #8E9293;
}
.licensingAppDelete {
  color: #EA4335;
}
.licensingCreatePlanButton {
  display: flex;
  justify-content: flex-end;
  width: 67%;
}
.licensingTable .syngenta-ant-pagination-item {
  display: none;
}
.licensingTable .syngenta-ant-pagination li:not(.syngenta-ant-pagination-disabled) a {
  color: #0071CD;
}
.plansHeaderContainer {
  width: 100%;
  display: inline-block;
}
.plansEmptyStateContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 400px;
}
.plansEmptyStateTitle {
  font-size: 18px;
  font-weight: bold;
  color: #14151C;
}
.plansEmptyStateText {
  font-size: 14px;
  color: #14151C;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

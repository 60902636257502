.cw-api-created-content-container {
  background: #fff;
  margin-bottom: 12px;
  padding: 24px 24px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 20px 0 rgba(112, 115, 116, 0.16);
  height: inherit;
  overflow: auto;
  min-width: 500px;
}
.cw-app-created-client-container {
  display: flex;
  flex-direction: column;
  width: 330;
  margin-right: 40px;
  margin-top: 10px;
}
.cw-api-created-copy-container {
  background: #F3F4F6;
  border-radius: 4px;
  padding: 15px 30px 15px 15px;
  margin-top: 10px;
  min-width: 340px;
  cursor: pointer;
}

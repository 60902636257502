.modal-placeholder {
  text-align: center;
  padding-top: 20px;
}
.modal-error {
  color: red;
  font-size: 40px;
}
.modal-success {
  color: green;
  font-size: 40px;
}

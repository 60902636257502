.entitlements-drawer-buttons {
  position: relative;
  margin-top: 55px;
}
.entitlements-drawer-buttons__wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
}
.entitlements-drawer-buttons__wrapper__right {
  display: flex;
  align-items: center;
}
.entitlements-drawer-buttons__wrapper__right .entitlement-drawer-cancel-button {
  color: black;
  margin-right: 23px;
  background-color: transparent;
  border: none;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.entitlements-drawer-buttons__wrapper__right .entitlement-drawer-cancel-button:hover {
  background-color: #F3F4F6;
  color: black;
}
.entitlements-drawer-buttons .entitlement-drawer-create-button {
  background: #FFFFFF;
  border: 1px solid #198746;
  box-sizing: border-box;
  border-radius: 4px;
  color: #198746;
}
.entitlements-drawer {
  height: 100vh;
}
.entitlements-drawer__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.entitlements-drawer .entitlements-drawer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.entitlements-drawer .entitlements-drawer-header__input {
  margin-bottom: 20px;
  margin-top: 20px;
}
.entitlements-drawer .entitlements-drawer-header__switch {
  display: flex;
}

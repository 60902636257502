.custom-select-popover {
  padding: 0 !important;
}
.custom-select-popover [class*=-popover-inner] {
  border-radius: 4px !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15) !important;
}
.custom-select-popover [class*=-checkbox-checked] [class*=-checkbox-inner],
.custom-select-popover [class*=-checkbox-indeterminate] [class*=-checkbox-inner]::after {
  background-color: #0071CD !important;
  border-color: #0071CD !important;
}
.custom-select-popover [class*=-checkbox-checked]::after {
  border: none;
}
.custom-select-popover [class*=-checkbox-wrapper]:hover [class*=-checkbox-inner],
.custom-select-popover [class*=-checkbox-input]:focus + [class*=-checkbox-inner] {
  border-color: #0071CD !important;
}
.custom-select-popover .hide-checkbox > [class$=-checkbox],
.custom-select-popover .hide-checkbox > [class$=-checkbox-checked] {
  display: none;
}
.custom-select-popover .hide-checkbox span {
  padding: 0;
}
.custom-select-popover .hide-checkbox[class*=-checkbox-wrapper-checked] span {
  font-weight: 600;
}
.custom-select-popover .virtualized-list [class*=-checkbox-wrapper] {
  margin: 0;
  padding: 5px 16px;
  width: 100%;
  transition: background-color 300ms ease-in-out;
}
.custom-select-popover .virtualized-list [class*=-checkbox-wrapper] > span + span {
  width: 100%;
}
.custom-select-popover .virtualized-list [class*=-checkbox-wrapper] p.label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  margin: 0;
}
.custom-select-popover .virtualized-list [class*=-checkbox-wrapper]:hover {
  background-color: #EAF6FF;
  z-index: 100;
  position: relative;
}
.custom-select-popover .virtualized-list [class*=-checkbox-wrapper]:hover p {
  white-space: normal;
}
.custom-select-popover .outlineButton {
  background: #F3F4F6;
  border-color: #F3F4F6;
}
.custom-select-popover .outlineButton:hover {
  background: #e4e4e4;
  border-color: #e4e4e4;
}

.cw-technology-card {
  border-radius: 8px;
  max-width: 200px;
  padding: 16px 32px 16px 32px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 2em;
  margin-left: 8px;
  margin-right: 8px;
  text-align: center;
}
.cw-technology-card:hover {
  background-color: #E8EAED;
}
.cw-technology-card-selected {
  background-color: #F0F3F7;
}
.cw-technology-card-selected:hover {
  background-color: #F0F3F7;
}
.cw-technology-image-placeholder {
  border-radius: 8px;
  background-color: #2f3031;
  margin-bottom: 1em;
  max-height: 75px;
  max-width: 75px;
}

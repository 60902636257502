.cw-app-actions {
  min-width: 140px;
}
.cw-app-edit {
  color: #0071CD;
}
.cw-app-icon-placeholder {
  border-radius: 8px;
  background-color: #2f3031;
  margin-bottom: 1em;
  max-height: 45px;
  max-width: 45px;
  cursor: pointer;
}
.cw-divider-left {
  border-left: 1px solid #979797;
}
.cw-app-icon-image {
  width: 45px;
  height: 45px;
}
.cw-app-icon-image-placeholder {
  background-color: black;
  border-radius: 8px;
  width: 45px;
  height: 45px;
}
